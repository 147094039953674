import React, { useState } from 'react';
import {
  getCodeUsedFor,
  getSubset,
  getCustom2dMappingByColumnName,
  getUniqueAlertIds,
  getEnrichment
} from '../helpers';
import Preview from './preview';
import yaml from 'js-yaml';
import styled from 'styled-components';
import {
  Checkbox,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel as MuiFormControlLabel,
  MenuItem,
  Button,
  Grid,
  Box,
  Card,
  CardContent as MuiCardContent
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import I18n from 'i18n';
const { lookup } = new I18n();

const FlexEndRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FilterInfo = styled(Alert)(
  p => `
    ul {
      padding-left: ${p.theme.space.md};
      margin: 0;
    }
  `
);

const CardContent = styled(MuiCardContent)(
  p => `
    padding: ${p.theme.space.sm} ${p.theme.space.md};
  `
);

const FormControlLabel = styled(MuiFormControlLabel)`
  margin: 0;
  width: 50%;
`;

const Filters = ({ doc, sheet, rows }) => {
  const uniqueAlertIds = getUniqueAlertIds(rows);

  const [state, setState] = useState({
    subset: {
      checked: true,
      label: 'Subset',
      format: 'yaml',
      json: '',
      yaml: '',
      func: getSubset,
      type: 'label'
    },
    alertfilter: {
      checked: true,
      label: 'Alert filter',
      format: 'yaml',
      json: '',
      yaml: '',
      func: getCodeUsedFor,
      type: 'label'
    },
    allowlist: {
      checked: true,
      label: 'Allowlist',
      format: 'yaml',
      json: '',
      yaml: '',
      func: getCodeUsedFor,
      type: 'label'
    },
    denylist: {
      checked: true,
      label: 'Denylist',
      format: 'yaml',
      json: '',
      yaml: '',
      func: getCodeUsedFor,
      type: 'label'
    },
    regimen: {
      sheetColIndex: '',
      checked: false,
      label: 'Regimen Map',
      format: 'yaml',
      json: '',
      yaml: '',
      func: getCustom2dMappingByColumnName,
      type: 'dropdown',
      is2d: true
    },
    subtype: {
      sheetColIndex: '',
      checked: false,
      label: 'Subtype Map',
      format: 'yaml',
      json: '',
      yaml: '',
      func: getCustom2dMappingByColumnName,
      type: 'dropdown',
      is2d: true
    },
    product: {
      sheetColIndex: '',
      checked: false,
      label: 'Product Map',
      format: 'yaml',
      json: '',
      yaml: '',
      func: getCustom2dMappingByColumnName,
      type: 'dropdown',
      is2d: true
    },
    enrichment: {
      sheetColIndex: '',
      checked: false,
      label: 'Enrichment',
      format: 'yaml',
      json: '',
      yaml: '',
      func: getEnrichment,
      type: 'dropdown'
    }
  });

  const [alertIdIndex, setAlertIdIndex] = useState(0);
  const [codeUserForIndex, setCodeUsedForIndex] = useState(4);
  const [loading, setLoading] = useState(false);
  const [yamlGenerated, setYamlGenerated] = useState(false);

  const handleDropdownChange = (event, key) => {
    setState(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        sheetColIndex: event.target.value
      }
    }));
    setYamlGenerated(false);
  };

  const handleCheckboxChange = event => {
    event.persist();
    setState(prevState => ({
      ...prevState,
      [event.target.name]: {
        ...prevState[event.target.name],
        checked: event.target.checked
      }
    }));
    setYamlGenerated(false);
  };

  const handleAlertIdChange = event => {
    setAlertIdIndex(event.target.value);
    setYamlGenerated(false);
  };

  async function generateYAML() {
    try {
      setLoading(true);
      setYamlGenerated(false);
      const newState = { ...state };

      Object.keys(state).forEach(item => {
        const field = state[item];
        if (field.checked) {
          const jsonObj = field.func({
            rows,
            codeColumn: sheet.headerValues[0], // name of Code column
            alertId: uniqueAlertIds[alertIdIndex],
            selectedColumn: sheet.headerValues[field.sheetColIndex],
            codeUsedForColumn: sheet.headerValues[codeUserForIndex],
            codeUsedForName: field.label
          });
          newState[item].yaml = yaml.dump(jsonObj, {
            quotingType: '"',
            forceQuotes: true
          });
          newState[item].json = jsonObj;
        }
      });

      setState(newState);
      setYamlGenerated(true);
    } catch (err) {
      setState(() => {
        throw new Error(err);
      });
    } finally {
      setLoading(false);
    }
  }

  const isFilterValid = () => {
    // check if at least 1 field selected
    let isValid = Object.keys(state).some(item => state[item].checked);
    if (isValid) {
      // validate if all checked dropdowns have sheetColIndex
      isValid = !Object.keys(state)
        .filter(
          field => state[field].checked && state[field].type === 'dropdown'
        )
        .some(field => !Number.isInteger(state[field].sheetColIndex));
    }
    return isValid;
  };

  return (
    <>
      <Alert severity="success">
        <strong>{`${doc.title} - ${sheet.title}`}</strong>
      </Alert>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              {lookup('yaml_verify_label')}
              <Box pt={1}>
                <FormControl fullWidth>
                  <InputLabel id={`alert-id-label`} shrink={true}>
                    {lookup('yaml_alert_id_label')}
                  </InputLabel>
                  <Select
                    labelId={`alert-id-label`}
                    id={`alert-id-column`}
                    value={alertIdIndex}
                    onChange={handleAlertIdChange}
                  >
                    {uniqueAlertIds.map((key, index) => {
                      return (
                        <MenuItem key={`alert-id-${key}`} value={index}>
                          {key}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box pt={1}>
                <FormControl fullWidth>
                  <InputLabel id={`code-used-for-label`} shrink={true}>
                    {lookup('yaml_code_used_for_label')}
                  </InputLabel>
                  <Select
                    labelId={`code-used-for-label`}
                    id={`code-used-for-column`}
                    value={codeUserForIndex}
                    onChange={e => setCodeUsedForIndex(e.target.value)}
                  >
                    {sheet.headerValues.map((key, index) => {
                      return (
                        <MenuItem key={key} value={index}>
                          {key}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              {Object.keys(state).map((field, id) => {
                const row = state[field];
                if (row.type === 'dropdown') {
                  return (
                    <Box key={field} pt={1}>
                      <FlexEndRow>
                        <Checkbox
                          checked={row.checked}
                          color="primary"
                          onChange={handleCheckboxChange}
                          name={field}
                        />
                        <FormControl fullWidth size="small">
                          <InputLabel id={`${field}-label`} shrink={true}>
                            {row.label}
                          </InputLabel>
                          <Select
                            labelId={`${field}-label`}
                            id={`${field}-column`}
                            value={row.sheetColIndex}
                            onChange={e => handleDropdownChange(e, field)}
                          >
                            {sheet.headerValues.map((key, index) => {
                              return (
                                <MenuItem key={key} value={index}>
                                  {key}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </FlexEndRow>
                    </Box>
                  );
                } else if (row.type === 'label') {
                  return (
                    <FormControlLabel
                      key={field}
                      control={
                        <Checkbox
                          checked={row.checked}
                          color="primary"
                          onChange={handleCheckboxChange}
                          name={field}
                        />
                      }
                      label={row.label}
                    />
                  );
                }
                return null;
              })}
              <Box pt={1}>
                <FilterInfo severity="info">
                  {lookup('yaml_filter_info')}
                </FilterInfo>
              </Box>
              <Box pt={1}>
                <FlexEndRow>
                  <Button
                    variant="contained"
                    disabled={loading || !isFilterValid()}
                    onClick={generateYAML}
                    color="primary"
                  >
                    {lookup('yaml_button_generate')}
                  </Button>
                </FlexEndRow>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={9}>
          {yamlGenerated && (
            <Card>
              <CardContent>
                <Preview fields={state} title={`${doc.title}_${sheet.title}`} />
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Filters;
