import React, { useEffect } from 'react';
import {
  Typography,
  Paper,
  Box,
  InputAdornment as MuiInputAdornment
} from '@material-ui/core';
import {
  InputField,
  SelectField,
  AlertOutputField,
  NoiseReductionField
} from '../../FormFields';
import { SAAS_FORM } from 'constants/AppConstants';
import { criteriaBoxOptions, CriteriaBox } from './Shared';
import styled from 'styled-components';
import { trackView } from 'utils/analytics';
import I18n from 'i18n';
const { lookup } = new I18n();
const { ALERT_OUTPUT_FORM } = SAAS_FORM;

const InputAdornment = styled(MuiInputAdornment)`
  text-transform: none;
`;

const DELIVERY_DAYS = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Friday', label: 'Friday' }
];

const AlertOutputForm = props => {
  const {
    formField: {
      alert_category_name,
      alert_file_name_template,
      delivery_day,
      noise_config,
      noise_reduction,
      dedupe_config,
      output_columns,
      client_acronym,
      project_directory,
      expected_compound_columns_to_be_unique,
      enrichment,
      dts_flag
    },
    formikProps: { setFieldValue },
    defaultColumns,
    defaultNoiseReduction
  } = props;

  useEffect(() => {
    trackView('saas_output');
  }, []);

  return (
    <>
      <Typography variant="h4" paragraph>
        {lookup('output_form_title')}
      </Typography>
      <Paper square>
        <Box p={2}>
          <CriteriaBox
            title={alert_file_name_template.label}
            {...criteriaBoxOptions}
            {...alert_file_name_template.tooltip}
          >
            <InputField
              name={alert_file_name_template.name}
              endAdornment={
                <InputAdornment position="end">
                  {lookup('output_file_extension')}
                </InputAdornment>
              }
            />
          </CriteriaBox>
          <CriteriaBox title={delivery_day.label} {...criteriaBoxOptions}>
            <SelectField
              name={delivery_day.name}
              placeholder={delivery_day.placeholder}
              data={DELIVERY_DAYS}
              variant="outlined"
              displayEmpty
            />
          </CriteriaBox>
          <CriteriaBox title={noise_config.label} {...criteriaBoxOptions}>
            <NoiseReductionField
              formFields={{
                alert_category_name,
                noise_config,
                noise_reduction,
                dedupe_config,
                client_acronym,
                project_directory,
                expected_compound_columns_to_be_unique
              }}
              setFieldValue={setFieldValue}
              defaultNoiseReduction={defaultNoiseReduction}
            />
          </CriteriaBox>
        </Box>
      </Paper>
      <AlertOutputField
        setFieldValue={setFieldValue}
        defaultColumns={defaultColumns}
        outputColumns={output_columns}
        enrichment={enrichment}
        dtsFlag={dts_flag}
      />
    </>
  );
};

AlertOutputForm.label = ALERT_OUTPUT_FORM.label;
AlertOutputForm.path = ALERT_OUTPUT_FORM.path;

export { AlertOutputForm };
