const ENV = {
  localhost: 'http://localhost:8000',
  dev: 'https://pulse-api-dev.komodohealth.net',
  qa: 'https://pulse-api-qa.komodohealth.net',
  staging: 'https://pulse-api-staging.komodohealth.net',
  flores: 'https://pulse-api.komodohealth.net'
};

const ENDPOINT = () => {
  if (process.env.REACT_APP_ENV) {
    return `${ENV[process.env.REACT_APP_ENV]}/api/v1/`;
  }
  return `${ENV[getEnv()]}/api/v1/`;
};

export const getEnv = () => {
  const hostname = window.location.hostname;
  return (
    hostname.split('.')[0].split('-')[1] || hostname.split('.')[0].split('-')[0]
  );
};

export default ENDPOINT();
