import React, { useContext, useState, useEffect, useMemo } from 'react';
import AppContext from 'AppContext';
import { ProjectDataRuleService, DataRuleService } from 'services';
import { Container, TextareaAutosize } from '@material-ui/core';
import Link from 'components/common/link';
import Table from 'components/common/table';
import Breadcrumbs from 'components/common/breadcrumbs';
import { required, requiredLookup, validateYAMLFormat } from 'utils/validation';
import AppRoutes from 'constants/AppRoutes';
import formatDate from 'utils/date';
import Alert from 'components/common/alert';
import { trackView, trackEvent } from 'utils/analytics';
import I18n from 'i18n';
import styled from 'styled-components';
const { lookup } = new I18n();

const YAMLTextAreaAutoresize = styled(TextareaAutosize)`
  font-family: inherit;
  min-width: 200px;
`;

const YAMLTextArea = props => {
  const { value, onChange, isReadOnly, onKeyDown } = props;
  let displayValue = value;

  if (!value) {
    displayValue = isReadOnly ? lookup('empty_yaml') : '';
  }

  return (
    <YAMLTextAreaAutoresize
      rowsMin={2}
      disabled={isReadOnly}
      value={displayValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};

const handleYAMLTextAreaKeyDown = e => {
  if (e.key === 'Enter') {
    e.stopPropagation();
  } else if (e.key === 'Tab') {
    e.preventDefault();
  }
};

const ProjectDataRule = props => {
  const { token, displayErrorMessage } = useContext(AppContext);
  const { projectId, companyId, projectName, companyName } = props.match.params;
  const projectDataRuleService = useMemo(
    () => new ProjectDataRuleService(token),
    [token]
  );
  const dataRuleService = useMemo(() => new DataRuleService(token), [token]);

  const [state, setState] = useState({
    dataRuleMap: {},
    projectDataRules: [],
    loading: true
  });

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        field: 'id',
        type: 'numeric',
        editable: 'never',
        render: ({ id }) => (
          <Link
            component="button"
            color="inherit"
            variant="button"
            underline="always"
            onClick={() => {
              props.history.push(
                `${AppRoutes.DELIVERY_SETTING}/${companyId}/${companyName}/${projectId}/${projectName}/${id}`
              );
            }}
          >
            {id}
          </Link>
        )
      },
      {
        title: 'Data Rule',
        field: 'data_rule_id',
        lookup: state.dataRuleMap,
        validate: ({ data_rule_id }) => requiredLookup(data_rule_id)
      },
      {
        title: 'Alert ID',
        field: 'alert_id',
        type: 'numeric'
      },
      {
        title: 'Parent Alert ID',
        field: 'parent_alert_id',
        type: 'numeric'
      },
      {
        title: 'Alert Name',
        field: 'alert_name',
        validate: ({ alert_name }) => required(alert_name)
      },
      {
        title: 'Description',
        field: 'description',
        validate: ({ description }) => required(description)
      },
      {
        title: 'YAML',
        field: 'extra_config_blob',
        render: rowData => (
          <YAMLTextArea value={rowData.extra_config_blob} isReadOnly={true} />
        ),
        editComponent: props => (
          <YAMLTextArea
            value={props.rowData.extra_config_blob}
            isReadOnly={false}
            onChange={e => props.onChange(e.target.value)}
            onKeyDown={handleYAMLTextAreaKeyDown}
          />
        ),
        validate: ({ extra_config_blob }) =>
          validateYAMLFormat(extra_config_blob)
      },
      {
        title: 'S3 Bucket Name',
        field: 's3_bucket_name',
        validate: ({ s3_bucket_name }) => required(s3_bucket_name)
      },
      {
        title: 'S3 Key Prefix',
        field: 's3_key_prefix',
        validate: ({ s3_key_prefix }) => required(s3_key_prefix)
      },
      {
        title: 'Inactive',
        field: 'is_deleted',
        type: 'boolean'
      },
      {
        title: 'Combined',
        field: 'is_combined',
        type: 'boolean'
      },
      {
        title: 'Created At',
        editable: 'never',
        field: 'created_at',
        render: ({ created_at }) => formatDate(created_at)
      },
      {
        title: 'Updated At',
        field: 'updated_at',
        editable: 'never',
        render: ({ updated_at }) => formatDate(updated_at)
      }
    ],
    [
      state.dataRuleMap,
      companyId,
      projectId,
      companyName,
      projectName,
      props.history
    ]
  );

  const [alertMessage, setAlertMessage] = useState({
    message: '',
    open: false
  });

  const onAlertClose = () => {
    setAlertMessage({
      ...alertMessage,
      open: false
    });
  };

  /**
   * Update state on successful create/edit
   * @param {Array} projectDataRules updated projectDataRules
   * @param {string} message message to display on successful create/edit
   * @param {function} callback callback function
   */
  const onSave = (projectDataRules, message, callback) => {
    setState({
      ...state,
      projectDataRules
    });
    setAlertMessage({
      message,
      open: true
    });
    trackEvent('project_data_rule_saved', message);
    callback();
  };

  const init = () => {
    trackView('project_data_rule');
    Promise.all([
      dataRuleService.getDataRuleMap(),
      projectDataRuleService.get(projectId)
    ])
      .then(res => {
        setState({
          dataRuleMap: res[0],
          projectDataRules: res[1],
          loading: false
        });
      })
      .catch(err => {
        setState(() => {
          throw new Error(err);
        });
      });
  };

  useEffect(init, [projectId, dataRuleService, projectDataRuleService]);

  return (
    <Container>
      <Alert onClose={onAlertClose} {...alertMessage} />
      <Table
        title={
          <Breadcrumbs
            data={[
              {
                name: lookup('breadcrumb_company'),
                href: AppRoutes.COMPANY
              },
              {
                name: `${lookup('breadcrumb_project')} (${companyName})`,
                href: `${AppRoutes.PROJECT}/${companyId}/${companyName}`
              },
              {
                name: `${lookup('breadcrumb_pdr')} (${projectName})`
              }
            ]}
          />
        }
        columns={columns}
        data={state.projectDataRules}
        isLoading={state.loading}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              const data = {
                ...newData,
                project_id: projectId
              };
              projectDataRuleService.create(data).then(
                newProjectDataRule => {
                  const projectDataRules = [
                    newProjectDataRule,
                    ...state.projectDataRules
                  ];
                  onSave(
                    projectDataRules,
                    `${newProjectDataRule.alert_name} ${lookup(
                      'table_create_message'
                    )}`,
                    resolve
                  );
                },
                err => {
                  displayErrorMessage(err, setAlertMessage, setState);
                  reject();
                }
              );
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const projectDataRules = [...state.projectDataRules];
              projectDataRuleService.update(newData).then(
                newProjectDataRule => {
                  projectDataRules[oldData.tableData.id] = newProjectDataRule;
                  onSave(
                    projectDataRules,
                    `${newProjectDataRule.alert_name} ${lookup(
                      'table_update_message'
                    )}`,
                    resolve
                  );
                },
                err => {
                  displayErrorMessage(err, setAlertMessage, setState);
                  reject();
                }
              );
            })
        }}
      />
    </Container>
  );
};

export default ProjectDataRule;
