import I18n from 'i18n';
const { lookup } = new I18n();

const alertFormModel = {
  formId: 'alertForm',
  formField: {
    alert_class_name: {
      name: 'ALERT'
    },
    client: {
      name: 'company_id',
      label: 'Customer Name *',
      requiredErrorMsg: 'Client is required',
      placeholder: 'Select Customer'
    },
    client_name: {
      name: 'company_name'
    },
    client_acronym: {
      name: 'client_id'
    },
    project: {
      name: '_ui_project_id',
      label: 'Project *',
      requiredErrorMsg: 'Project is required',
      placeholder: 'Select Project'
    },
    dts_flag: {
      name: '_ui_dts'
    },
    project_ta_acronym: {
      name: 'ta_id'
    },
    project_directory: {
      name: 'project_id'
    },
    cohort_name: {
      name: 'cohort_name',
      label: 'Cohort Name',
      requiredErrorMsg: 'Cohort Name is required',
      maxLength: 100,
      placeholder: 'Enter a Cohort Name'
    },
    dx_codes_allow_list: {
      name: 'dx_codes_allow_list',
      label: 'DX CODES ALLOW LIST (OR)',
      requiredErrorMsg: 'Please enter DX codes',
      placeholder: lookup('dx_codes_placeholder'),
      codeType: 'ICD'
    },
    px_codes_allow_list: {
      name: 'px_codes_allow_list',
      label: 'PX CODES ALLOW LIST (OR)',
      requiredErrorMsg: 'Please enter PX codes',
      placeholder: lookup('px_codes_placeholder'),
      codeType: 'Procedure'
    },
    rx_codes_allow_list: {
      name: 'rx_codes_allow_list',
      label: 'RX CODES ALLOW LIST (OR)',
      requiredErrorMsg: 'Please enter RX codes',
      placeholder: lookup('rx_codes_placeholder'),
      codeType: 'NDC'
    },
    dx_codes_deny_list: {
      name: 'dx_codes_deny_list',
      label: 'DX CODES DENY LIST (OR)',
      requiredErrorMsg: 'Please enter DX codes',
      placeholder: lookup('dx_codes_placeholder'),
      codeType: 'ICD'
    },
    px_codes_deny_list: {
      name: 'px_codes_deny_list',
      label: 'PX CODES DENY LIST (OR)',
      requiredErrorMsg: 'Please enter PX codes',
      placeholder: lookup('px_codes_placeholder'),
      codeType: 'Procedure'
    },
    rx_codes_deny_list: {
      name: 'rx_codes_deny_list',
      label: 'RX CODES DENY LIST (OR)',
      requiredErrorMsg: 'Please enter RX codes',
      placeholder: lookup('rx_codes_placeholder'),
      codeType: 'NDC'
    },
    patient_age: {
      name: '_ui_patient_age',
      label: lookup('cohort_patient_age_label')
    },
    hcp_npi_allow_list: {
      name: '_ui_hcp_npi_allow_list',
      placeholder: lookup('csv_upload_placeholder'),
      label: lookup('cohort_npi_allow_label'),
      path: 'npis_to_include_path',
      flag: 'allowlist_npis_flag',
      uploadType: 'npi_include',
      uploadObject: '_ui_uploads',
      tooltip: {
        TooltipContent: lookup('tooltip_npi_include_exclude')
      }
    },
    hcp_npi_deny_list: {
      name: '_ui_hcp_npi_deny_list',
      placeholder: lookup('csv_upload_placeholder'),
      label: lookup('cohort_npi_deny_label'),
      path: 'npis_to_exclude_path',
      flag: 'denylist_npis_flag',
      uploadType: 'npi_exclude',
      uploadObject: '_ui_uploads',
      tooltip: {
        TooltipContent: lookup('tooltip_npi_include_exclude')
      }
    },
    include_any_hcp_specialties: {
      name: '_ui_include_any_hcp_specialties',
      placeholder: lookup('csv_upload_placeholder'),
      label: lookup('cohort_specialties_allow_label'),
      path: 'specialties_to_include_path',
      flag: 'allowlist_specialties_flag',
      uploadType: 'specialty_include',
      uploadObject: '_ui_uploads',
      tooltip: {
        TooltipContent: lookup('tooltip_specialties_include_exclude')
      }
    },
    exclude_any_hcp_specialties: {
      name: '_ui_exclude_any_hcp_specialties',
      placeholder: lookup('csv_upload_placeholder'),
      label: lookup('cohort_specialties_deny_label'),
      path: 'specialties_to_exclude_path',
      flag: 'denylist_specialties_flag',
      uploadType: 'specialty_exclude',
      uploadObject: '_ui_uploads',
      tooltip: {
        TooltipContent: lookup('tooltip_specialties_include_exclude')
      }
    },
    dts_clinical_trial_site_list: {
      name: '_ui_dts_clinical_trial_site_list',
      requiredErrorMsg: 'Please upload a file.',
      placeholder: lookup('csv_upload_placeholder'),
      path: 'distance_to_site.s3_file_uri',
      flag: 'distance_to_site.is_enabled',
      unit: 'distance_to_site.units_of_measure',
      unitPlaceholder: 'Choose Unit',
      uploadType: 'distance_to_site',
      uploadObject: '_ui_uploads',
      tooltip: lookup('enrichment_form_dts_tooltip')
    },
    specialties_to_include_path: {
      name: 'specialties_to_include_path'
    },
    specialties_to_exclude_path: {
      name: 'specialties_to_exclude_path'
    },
    npis_to_include_path: {
      name: 'npis_to_include_path'
    },
    npis_to_exclude_path: {
      name: 'npis_to_exclude_path'
    },
    distance_to_site: {
      name: 'distance_to_site'
    },
    allowlist_specialties_flag: {
      name: 'allowlist_specialties_flag'
    },
    denylist_specialties_flag: {
      name: 'denylist_specialties_flag'
    },
    allowlist_npis_flag: {
      name: 'allowlist_npis_flag'
    },
    denylist_npis_flag: {
      name: 'denylist_npis_flag'
    },
    uploads: {
      name: '_ui_uploads'
    },
    lookback_period: {
      name: 'lookback_window_in_months',
      label: lookup('cohort_lookback_label'),
      requiredErrorMsg: 'Please enter Lookback window',
      placeholder: '36',
      tooltip: {
        TooltipContent: lookup('tooltip_cohort_lookback_window')
      }
    },
    alert_name: {
      name: 'alert_name',
      label: lookup('trigger_alert_name_label'),
      requiredErrorMsg: 'Please enter Alert Name',
      maxLength: 100,
      placeholder: 'Enter a name for your alert'
    },
    alert_description: {
      name: 'alert_description',
      label: lookup('trigger_alert_description_label'),
      requiredErrorMsg: 'Please enter Alert Description',
      maxLength: 1000,
      placeholder: 'Enter a description for your alert'
    },
    alert_category: {
      name: 'data_rule_id',
      label: lookup('trigger_alert_category_label'),
      requiredErrorMsg: 'Please select an Alert Category',
      placeholder: 'Select an Alert Category',
      tooltip: {
        TooltipContent: lookup('tooltip_alert_category')
      }
    },
    alert_category_name: {
      name: 'data_rule_name'
    },
    medical_event_name: {
      name: 'medical_event_name',
      label: 'Medical Event',
      requiredErrorMsg: 'Medical Event Name is required',
      maxLength: 100,
      placeholder: 'Enter a Medical Event Name'
    },
    code_logic: {
      name: 'filter_dx_rx_px',
      label: lookup('trigger_code_logic_label'),
      requiredErrorMsg: 'Code Logic is required',
      placeholder: 'Choose Code Logic',
      tooltip: {
        TooltipContent: lookup('tooltip_code_logic')
      }
    },
    medical_event_dx_codes: {
      name: 'medical_event_dx_codes',
      label: lookup('trigger_dx_label'),
      requiredErrorMsg: 'Please enter DX codes',
      placeholder: lookup('dx_codes_placeholder'),
      codeType: 'ICD'
    },
    medical_event_rx_codes: {
      name: 'medical_event_rx_codes',
      label: lookup('trigger_rx_label'),
      requiredErrorMsg: 'Please enter RX codes',
      placeholder: lookup('rx_codes_placeholder'),
      codeType: 'NDC'
    },
    medical_event_px_codes: {
      name: 'medical_event_px_codes',
      label: lookup('trigger_px_label'),
      requiredErrorMsg: 'Please enter PX codes',
      placeholder: lookup('px_codes_placeholder'),
      codeType: 'Procedure'
    },
    era: {
      name: 'era_threshold_weeks',
      label: lookup('trigger_era_label'),
      requiredErrorMsg: 'Please enter Event Gap Threshold',
      placeholder: 'Enter Event Gap Threshold',
      tooltip: {
        TooltipContent: lookup('tooltip_event_gap_threshold')
      }
    },
    qualifying_window: {
      name: 'qualifying_window_in_weeks',
      label: lookup('trigger_qualifying_window'),
      requiredErrorMsg: 'Please enter Qualifying Window',
      placeholder: 'Enter Qualifying Window',
      tooltip: {
        TooltipContent: lookup('tooltip_qualifying_window')
      }
    },
    alert_file_name_template: {
      name: 'output_filename_template',
      label: lookup('output_file_template_label'),
      requiredErrorMsg: 'Please enter Alert File Name',
      maxLength: 255,
      customValidationRegex: /^[a-zA-Z0-9._-]+$/,
      customValidationMessage:
        'Invalid Alert File Name\nOnly alphanumeric characters, dashes, underscores, and periods are allowed\n“_{date}.csv” will be appended automatically',
      tooltip: {
        TooltipContent: lookup('tooltip_alert_file_name')
      }
    },
    delivery_day: {
      name: 'delivery_day',
      label: 'Schedule Alert to Deliver on',
      placeholder: 'Select a day of the week',
      requiredErrorMsg: 'Delivery Day is required'
    },
    noise_config: {
      name: '_ui_noise_reduction',
      label: 'Apply noise reduction filter',
      placeholder: 'Select noise preference'
    },
    noise_reduction: {
      name: 'noise_reduction'
    },
    dedupe_config: {
      name: 'dedupe_config'
    },
    expected_compound_columns_to_be_unique: {
      name: 'expected_compound_columns_to_be_unique'
    },
    enrichment: {
      // TODO: localize after final copy
      name: 'claims_enrichment',
      fields: {
        event_name: {
          placeholder: 'Enter a Medical Event Name',
          label: 'Medical Event',
          maxLength: 100
        },
        dx_codes: {
          placeholder: lookup('dx_codes_placeholder'),
          label: 'DX CODES',
          codeType: 'ICD'
        },
        px_codes: {
          placeholder: lookup('px_codes_placeholder'),
          label: 'PX CODES',
          codeType: 'Procedure'
        },
        rx_codes: {
          placeholder: lookup('rx_codes_placeholder'),
          label: 'RX CODES',
          codeType: 'NDC'
        },
        window_in_days: {
          placeholder: 'Lookback window',
          label: 'Lookback window',
          unitLabel: lookup('enrichment_form_window_info'),
          tooltip: {
            TooltipContent: lookup('tooltip_enrichment_lookback_window')
          }
        }
      },
      errorMsg: {
        event_name_required: 'Medical Event Name is required',
        event_name_unique: 'Medical Event Name must be unique',
        event_name_regex: 'Special characters and spaces are not allowed',
        codes_required: 'Please enter codes',
        enrichment_columns_required: 'Please select at least one column',
        window_in_days_required: 'Please enter Lookback window'
      }
    },
    output_columns: {
      name: 'output_columns_map'
    }
  }
};

export { alertFormModel };
