import MuiLink from '@material-ui/core/Link';
import styled from 'styled-components';

const Link = styled(MuiLink)(
  ({ theme }) => `
	transition: 0.2s;
	&:hover {
	  color: ${theme.colors.brand};
	}
`
);

export default Link;
