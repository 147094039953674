import ENDPOINT from 'utils/api';
import { handleErrors } from 'utils/error';

class FormDefaultsService {
  /**
   * 'Constructor' for FormDefaultsService
   * @param {string} token auth0 token
   */
  constructor(token) {
    this.path = `${ENDPOINT}saas_default_values`;
    this.requestHeader = {
      headers: {
        'Access-Token': token,
        'Content-Type': 'application/json'
      }
    };
  }

  /**
   * Get default SAAS Form values
   * @async
   * @return {Promise} default output_columns_map, default_claims_enrichment and default_noise_reduction_mapping.
   */
  async get() {
    try {
      const res = await fetch(this.path, this.requestHeader);
      if (!res.ok) {
        throw res;
      }
      let {
        output_columns_map,
        default_claims_enrichment,
        default_noise_reduction_mapping
      } = await res.json();
      Object.keys(output_columns_map).forEach(key => {
        output_columns_map[key].forEach(item => {
          item.target_column_name = item.default_target_column_name;
        });
      });
      return {
        output_columns_map,
        default_claims_enrichment,
        default_noise_reduction_mapping
      };
    } catch (e) {
      throw await handleErrors(e);
    }
  }
}

export { FormDefaultsService };
