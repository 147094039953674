import APP_ROUTES from './AppRoutes';

export const ROLES = {
  ADMIN: 'admin',
  CX: 'cx'
};

export const HEADER_INDEX = {
  MONITORING: 0,
  CONFIGURATION: 1,
  YAML: 2,
  ALERT_LIST: 3
};

export const HEADER_CONFIG = {
  [ROLES.ADMIN]: [
    {
      index: HEADER_INDEX.MONITORING,
      route: APP_ROUTES.MONITORING,
      label: 'header_monitoring'
    },
    {
      index: HEADER_INDEX.CONFIGURATION,
      route: APP_ROUTES.COMPANY,
      label: 'header_configuration'
    },
    {
      index: HEADER_INDEX.YAML,
      route: APP_ROUTES.YAML,
      label: 'header_yaml'
    },
    {
      index: HEADER_INDEX.ALERT_LIST,
      route: APP_ROUTES.ALERT_LIST,
      label: 'header_alert_list'
    }
  ],
  [ROLES.CX]: [
    {
      index: HEADER_INDEX.MONITORING,
      route: APP_ROUTES.MONITORING,
      label: 'header_monitoring'
    }
  ]
};

export const MONITORING_STATUS = {
  delivered: {
    severity: 'success',
    label: 'Delivered'
  },
  scheduled: {
    severity: 'info',
    label: 'Scheduled'
  },
  failed: {
    severity: 'error',
    label: 'Failed'
  },
  pending: {
    severity: 'info',
    label: 'Pending'
  },
  canceled: {
    severity: 'warning',
    label: 'Canceled'
  },
  'no-data': {
    severity: 'info',
    label: 'No Data',
    class: 'no-data'
  }
};

export const DATE_FORMAT = {
  timestamp: 'ddd MM-DD-YYYY h:mm A'
};

export const SETTING_ACTIVE_FILTER_MAPPING = {
  active: {
    value: 'checked',
    label: 'Active Alerts'
  },
  inactive: {
    value: 'unchecked',
    label: 'Inactive Alerts'
  },
  show_all: {
    value: undefined,
    label: 'Show All'
  }
};

export const BRD_TEMPLATE = {
  CODE_TYPE: 'Code Type',
  ALERT_ID: 'Alert #',
  FLAG: 'Flag'
};

export const SAAS_FORM = {
  CUSTOMER_FORM: {
    label: 'SELECT CUSTOMER',
    path: 'customer'
  },
  COHORT_FORM: {
    label: 'CREATE COHORT',
    path: 'cohort'
  },
  ALERT_TRIGGER_FORM: {
    label: 'DEFINE ALERT TRIGGER',
    path: 'alerttrigger'
  },
  REVIEW_FORM: {
    label: 'REVIEW & FINISH',
    path: 'review'
  },
  ALERT_OUTPUT_FORM: {
    label: 'DEFINE OUTPUT',
    path: 'alertoutput'
  },
  ENRICHMENT_FORM: {
    label: 'DEFINE ENRICHMENT',
    path: 'enrichment'
  }
};

export const ALERT_CATEGORIES = {
  START_THERAPY: 'Start Therapy',
  NEWLY_DIAGNOSED: 'Newly Diagnosed',
  NEWLY_TESTED: 'Newly Tested',
  CONTINUOUS_THERAPY: 'Continuous Therapy'
};

export const ALERT_CATEGORIES_ARRAY = [
  ALERT_CATEGORIES.START_THERAPY,
  ALERT_CATEGORIES.NEWLY_DIAGNOSED,
  ALERT_CATEGORIES.NEWLY_TESTED,
  ALERT_CATEGORIES.CONTINUOUS_THERAPY
];

export const CODE_TYPES = {
  ICD: 'icd',
  PROCEDURE: 'procedure',
  NDC: 'ndc'
};

export const DTS_UNITS = {
  miles: {
    value: 'miles',
    label: 'Miles (mi)'
  },
  kilometers: {
    value: 'kilometers',
    label: 'Kilometers (km)'
  }
};

export const NOISE_REDUCTION_OPTIONS_WITH_DAYS = [
  'noise_reduction_on_patient_hcp_timeframe',
  'noise_reduction_on_patient_timeframe'
];

export const CONTINUOUS_THERAPY_NOISE_REDUCTION_OPTIONS = [
  'no_noise_reduction',
  'noise_reduction_on_patient_hcp_timeframe'
];

export const MAX_LENGTH_MESSAGE = (key, maxLength) => {
  return `${key} is limited to ${maxLength} characters`;
};

export const RESERVED_COLUMN_NAMES = ['alert_uuid', 'project_id'];
