import React, { useContext, useState, useMemo, useEffect } from 'react';
import { Container, Box } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { Button } from '@komodohealth/fs-harmony.ui.button';
import Table from 'components/common/table';
import Alert from 'components/common/alert';
import AddIcon from '@material-ui/icons/Add';
import Link from 'components/common/link';
import Breadcrumbs from 'components/common/breadcrumbs';
import AppRoutes from 'constants/AppRoutes';
import AppContext from 'AppContext';
import { FormService } from 'services';
import formatDate from 'utils/date';
import { trackView } from 'utils/analytics';
import I18n from 'i18n';
const { lookup } = new I18n();

const AlertList = withTheme(props => {
  const { history, theme } = props;
  const { token } = useContext(AppContext);
  const formService = useMemo(() => new FormService(token), [token]);

  const [state, setState] = useState({
    forms: [],
    loading: true
  });

  const columns = [
    {
      title: 'ID',
      field: 'id',
      render: ({ id }) => (
        <Link
          component="button"
          color="inherit"
          variant="button"
          underline="always"
          onClick={() => {
            history.push(AppRoutes.ALERT_FORM_CUSTOMER(id));
          }}
        >
          {id}
        </Link>
      )
    },
    {
      title: 'Alert Name',
      field: 'json_blob.alert_name'
    },
    {
      title: 'Company ID',
      field: 'company_id'
    },
    {
      title: 'Last Updated By',
      field: 'user.email'
    },
    {
      title: 'Created At',
      field: 'created_at',
      render: ({ created_at }) => formatDate(created_at)
    },
    {
      title: 'Updated At',
      field: 'updated_at',
      defaultSort: 'desc',
      render: ({ updated_at }) => formatDate(updated_at)
    }
  ];

  const init = () => {
    trackView('saas_list');
    formService.get().then(
      forms => {
        setState({
          forms,
          loading: false
        });
      },
      err => {
        setState(() => {
          throw new Error(err);
        });
      }
    );
  };

  useEffect(init, [formService]);

  return (
    <Container>
      <Alert />
      <Table
        title={
          <Breadcrumbs
            data={[
              {
                name: lookup('breadcrumb_saas_list')
              }
            ]}
          />
        }
        options={{
          maxBodyHeight: 550,
          pageSize: 10,
          headerStyle: theme.typography.h6
        }}
        components={{
          Actions: props => {
            return (
              <Box ml={2}>
                <Button
                  onClick={() =>
                    history.push(AppRoutes.ALERT_FORM_CUSTOMER('create'))
                  }
                  customStartIcon={<AddIcon />}
                >
                  {lookup('saas_list_new_button')}
                </Button>
              </Box>
            );
          }
        }}
        columns={columns}
        data={state.forms}
        isLoading={state.loading}
      />
    </Container>
  );
});

export { AlertList };
