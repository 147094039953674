import yaml from 'js-yaml';

/**
 * required validator for material-table
 * @param {val} val string value
 * @returns {(boolean|Object)}
 */
export const required = val => {
  if (val?.trim().length > 0) {
    return true;
  }
  return { isValid: false };
};

/**
 * Lookup required validator for material-table
 * @param {val} val lookup value
 * @returns {(boolean|Object)}
 */
export const requiredLookup = val => {
  if (val) {
    return true;
  }
  return { isValid: false };
};

/**
 * YAML validation for input blob
 * @param {inputString} inputString to be validated
 * @returns {(boolean|Object)}
 */
export const validateYAMLFormat = inputString => {
  try {
    JSON.stringify(yaml.load(inputString));
    return true;
  } catch (e) {
    return { isValid: false };
  }
};
/**
 * Cron expression validator for material-table
 * @param {cronExpression} cronExpression to be validated
 * @returns {(boolean|Object)}
 */
export const validCronFormat = cronExpression => {
  let regex = new RegExp(
    /^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/
  );
  let result = regex.test(cronExpression);
  if (result) {
    return true;
  }

  return { isValid: false };
};
