import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Grid,
  Accordion as MuiAccordion,
  Checkbox,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  IconButton,
  FormControl as MuiFormControl,
  FormLabel as MuiFormLabel,
  FormControlLabel as MuiFormControlLabel,
  Paper
} from '@material-ui/core';
import {
  InputField,
  CodeSelectField,
  TextInputTitleField,
  SelectField,
  UploadField
} from '../../FormFields';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@komodohealth/fs-harmony.ui.tooltip';
import { criteriaBoxOptions, CriteriaBoxEnrichment } from './Shared';
import { FieldArray, useField } from 'formik';
import { SurfaceButton } from '@komodohealth/fs-harmony.ui.button';
import { ExpandButton } from '@komodohealth/fs-harmony.ui.button';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import HelperText from 'components/common/helperText';
import { at } from 'lodash';
import styled, { css } from 'styled-components';
import { SAAS_FORM, DTS_UNITS } from 'constants/AppConstants';
import { trackView, trackEvent } from 'utils/analytics';
import I18n from 'i18n';
const { lookup } = new I18n();
const { ENRICHMENT_FORM } = SAAS_FORM;

const tooltipStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer'
  },
  customWidth: {
    maxWidth: 500
  }
}));

const EMPTY_ENRICHMENT_SCHEMA = {
  event_name: '',
  codes: { dx_codes: [], px_codes: [], rx_codes: [] },
  enrichment_columns: [],
  window_in_days: ''
};

const FormTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const EnrichmentColumnsGrid = styled(Grid)(
  p => `
    padding-left: ${p.theme.space.lg};
  `
);

const FormControl = styled(MuiFormControl)`
  word-break: break-all;
`;

const FormLabel = styled(MuiFormLabel)(
  p => css`
    ${p.theme.typography.h5};
  `
);

const Accordion = styled(MuiAccordion)(
  p => `
    margin-bottom: ${p.theme.space.md};
    margin-top: 0;
    &.Mui-expanded{
      margin-top: 0;
    }
  `
);

const CodesHelperText = styled(HelperText)(
  p => `
    padding-bottom: ${p.theme.space.md};
  `
);

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AccordionSummary = styled(MuiAccordionSummary)(
  p => css`
    .MuiAccordionSummary-content {
      overflow: hidden;
      align-items: center;
      .panel-content {
        overflow: hidden;
        width: 100%;
      }
      margin: ${p.theme.space.md} 0;
    }
    &.Mui-focused {
      background-color: white;
    }
  `
);

const FormControlLabel = styled(MuiFormControlLabel)(
  p => css`
    .MuiFormControlLabel-label {
      ${p.theme.typography.h6};
    }
  `
);

/**
 * Update Output Columns Map when user makes changes to enrichment
 * @param {string} outputColumnsName yaml name of outputColumns
 * @param {Array} columnsToDelete array of columns to delete
 * @param {function} setFieldValue formik setFieldValue
 * @param {Object} currentOutputColumnsValue formik current output columns value
 */
const updateOutputColumnsMap = (
  outputColumnsName,
  columnsToDelete,
  setFieldValue,
  currentOutputColumnsValue
) => {
  setFieldValue(
    outputColumnsName,
    currentOutputColumnsValue.filter(
      item => !columnsToDelete.includes(item.source_column_name)
    )
  );
};

const EnrichmentColumns = props => {
  const {
    defaultEnrichment,
    enrichment,
    index,
    setFieldValue,
    outputColumns,
    values,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(rest);

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <HelperText error={true}>{error}</HelperText>;
    }
  };

  const handleColumnChange = (e, eventName, defaultColumn, arrayHelpers) => {
    if (e.target.checked) {
      arrayHelpers.push({
        ...defaultColumn,
        fieldname: `${eventName}${defaultColumn.fieldname}`
      });
      trackEvent('aggregation_selected', defaultColumn.fieldname);
    } else {
      const columnId = values[enrichment.name][
        index
      ].enrichment_columns.findIndex(
        column => column.aggregation === defaultColumn.aggregation
      );
      const columnToDelete =
        values[enrichment.name][index].enrichment_columns[columnId].fieldname;
      updateOutputColumnsMap(
        outputColumns.name,
        [columnToDelete],
        setFieldValue,
        values[outputColumns.name]
      );
      arrayHelpers.remove(columnId);
      helpers.setTouched(true);
    }
  };

  return (
    <>
      <FieldArray
        name={field.name}
        render={arrayHelpers => (
          <>
            {defaultEnrichment.map((item, i) => {
              const eventName = `${values[enrichment.name][index].event_name}`;
              return (
                <FormControlLabel
                  key={`${item.aggregation}-${i}`}
                  control={
                    <Checkbox
                      name={`${enrichment.name}[${index}].enrichment_columns`}
                      value={item}
                      checked={Boolean(
                        values[enrichment.name][index].enrichment_columns.find(
                          column => column.aggregation === item.aggregation
                        )
                      )}
                      onChange={e =>
                        handleColumnChange(e, eventName, item, arrayHelpers)
                      }
                    />
                  }
                  label={item.fieldname.replace('_', '').replace(/_/g, ' ')}
                />
              );
            })}
          </>
        )}
      />
      {renderHelperText()}
    </>
  );
};

const EnrichmentConfiguration = props => {
  const { enrichment, index, setFieldValue, ...rest } = props;
  const {
    fields: { dx_codes, px_codes, rx_codes, window_in_days }
  } = enrichment;
  const [field, meta] = useField(rest);

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <CodesHelperText error={true}>{error}</CodesHelperText>;
    }
  };

  return (
    <>
      <CriteriaBoxEnrichment title={dx_codes.label} {...criteriaBoxOptions}>
        <CodeSelectField
          name={`${field.name}.dx_codes`}
          codeType={dx_codes.codeType}
          placeholder={dx_codes.placeholder}
          setFieldValue={setFieldValue}
        />
      </CriteriaBoxEnrichment>
      <CriteriaBoxEnrichment title={px_codes.label} {...criteriaBoxOptions}>
        <CodeSelectField
          name={`${field.name}.px_codes`}
          codeType={px_codes.codeType}
          placeholder={px_codes.placeholder}
          setFieldValue={setFieldValue}
        />
      </CriteriaBoxEnrichment>
      <CriteriaBoxEnrichment title={rx_codes.label} {...criteriaBoxOptions}>
        <CodeSelectField
          name={`${field.name}.rx_codes`}
          codeType={rx_codes.codeType}
          placeholder={rx_codes.placeholder}
          setFieldValue={setFieldValue}
        />
      </CriteriaBoxEnrichment>
      {renderHelperText()}
      <CriteriaBoxEnrichment
        title={window_in_days.label}
        {...criteriaBoxOptions}
        {...window_in_days.tooltip}
      >
        <InputField
          name={`${enrichment.name}[${index}].window_in_days`}
          type="number"
          placeholder={window_in_days.placeholder}
          inputProps={{
            inputProps: {
              min: 1,
              step: 1
            }
          }}
          fullWidth={false}
        />
        <Box pl={1}>{window_in_days.unitLabel}</Box>
      </CriteriaBoxEnrichment>
    </>
  );
};

const EnrichmentPanel = props => {
  const {
    title,
    enrichment,
    outputColumns,
    values,
    setFieldValue,
    arrayHelpers,
    index,
    children
  } = props;
  const [expanded, setExpanded] = useState(true);
  return (
    <Accordion expanded={expanded} square>
      <AccordionSummary>
        <div className="panel-content">{title}</div>
        <ExpandButton
          open={expanded}
          color="primary"
          onClick={() => setExpanded(!expanded)}
        />
        <IconButton
          onClick={() => {
            const columnsToDelete = values[enrichment.name][
              index
            ].enrichment_columns.map(item => item.fieldname);
            updateOutputColumnsMap(
              outputColumns.name,
              columnsToDelete,
              setFieldValue,
              values[outputColumns.name]
            );
            arrayHelpers.remove(index);
          }}
        >
          <CancelOutlinedIcon color="action" />
        </IconButton>
      </AccordionSummary>
      {children}
    </Accordion>
  );
};

const DTSPanel = props => {
  const {
    title,
    outputColumns,
    values,
    setFieldValue,
    dtsFlag,
    uploadField,
    currentUploads,
    dtsSourceColumns,
    children
  } = props;
  const [expanded, setExpanded] = useState(true);
  return (
    <Accordion expanded={expanded} square>
      <AccordionSummary>
        <div className="panel-content">{title}</div>
        <ExpandButton
          open={expanded}
          color="primary"
          onClick={() => setExpanded(!expanded)}
        />
        <IconButton
          onClick={() => {
            // start deleting all dts references

            // disable dts
            setFieldValue(dtsFlag, '');

            // remove/reset all file upload references
            setFieldValue(uploadField.uploadType, '');
            const {
              [uploadField.uploadType]: _,
              ...newUploads
            } = currentUploads;
            setFieldValue(uploadField.uploadObject, newUploads);
            setFieldValue(uploadField.name, '');

            // delete dts columns from output columns map
            updateOutputColumnsMap(
              outputColumns.name,
              dtsSourceColumns,
              setFieldValue,
              values[outputColumns.name]
            );
          }}
        >
          <CancelOutlinedIcon color="action" />
        </IconButton>
      </AccordionSummary>
      {children}
    </Accordion>
  );
};

const EnrichmentButtons = ({
  values,
  dtsFlag,
  dtsField,
  enrichment,
  setFieldValue
}) => {
  return (
    <AddButtonContainer>
      <Box mr={2}>
        <SurfaceButton
          customStartIcon={<AddCircleOutlineSharpIcon />}
          disabled={Boolean(values[dtsFlag.name])}
          onClick={async () => {
            // enable dts and set unit to miles
            Promise.all([
              setFieldValue(dtsFlag.name, true),
              setFieldValue(dtsField.unit, DTS_UNITS.miles.value)
            ]).then(() => {
              // scroll Top
              window.scrollTo({ top: 0, behavior: 'smooth' });
            });
            trackEvent('new_dts');
          }}
        >
          {lookup('enrichment_form_add_new_dts_label')}
        </SurfaceButton>
      </Box>
      <SurfaceButton
        customStartIcon={<AddCircleOutlineSharpIcon />}
        onClick={async () => {
          // add new enrichment
          setFieldValue(enrichment.name, [
            ...values[enrichment.name],
            EMPTY_ENRICHMENT_SCHEMA
          ]).then(() => {
            // scroll Bottom
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: 'smooth'
            });
          });
          trackEvent('new_enrichment');
        }}
      >
        {lookup('enrichment_form_add_new_label')}
      </SurfaceButton>
    </AddButtonContainer>
  );
};

const EnrichmentForm = props => {
  const {
    formField: {
      enrichment,
      output_columns,
      dts_clinical_trial_site_list,
      dts_flag
    },
    formikProps: { setFieldValue, setSubmitting, values },
    defaultEnrichment,
    defaultDTSColumns
  } = props;

  const tooltipClasses = tooltipStyles();
  const dtsSourceColumns = defaultDTSColumns.map(
    column => column.source_column_name
  );

  const updateEventName = (
    eventName,
    enrichmentColumns,
    newValue,
    columnsToDelete,
    outputColumns,
    values
  ) => {
    updateOutputColumnsMap(
      outputColumns.name,
      columnsToDelete,
      setFieldValue,
      values[outputColumns.name]
    );
    setFieldValue(eventName, newValue);
    //reset selected enrichment columns
    setFieldValue(enrichmentColumns, []);
  };

  useEffect(() => {
    trackView('saas_enrichment');
  }, []);

  return (
    <>
      <Box pb={2}>
        <FormTitleContainer>
          <Typography variant="h4">
            {lookup('enrichment_form_title')}
          </Typography>
          <EnrichmentButtons
            values={values}
            dtsFlag={dts_flag}
            dtsField={dts_clinical_trial_site_list}
            enrichment={enrichment}
            setFieldValue={setFieldValue}
          />
        </FormTitleContainer>
      </Box>
      {/* Render if enrichment and dts don't exist */}
      {values[enrichment.name].length < 1 && !values[dts_flag.name] && (
        <>
          <Grid container>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Paper square>
                <Box p={2}>
                  <Typography variant="h5" paragraph>
                    {lookup('enrichment_form_add_new_title')}
                  </Typography>
                  <Typography variant="body2" paragraph>
                    {lookup('enrichment_form_add_new_subtitle')}
                  </Typography>
                  <SurfaceButton
                    customStartIcon={<AddCircleOutlineSharpIcon />}
                    onClick={() => {
                      setFieldValue(enrichment.name, [EMPTY_ENRICHMENT_SCHEMA]);
                      trackEvent('new_enrichment');
                    }}
                  >
                    {lookup('enrichment_form_add_new_label')}
                  </SurfaceButton>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={2}>
            <Grid container>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Paper square>
                  <Box p={2}>
                    <Typography variant="h5" paragraph>
                      {lookup('enrichment_form_add_new_dts_title')}
                    </Typography>
                    <Typography variant="body2" paragraph>
                      {lookup('enrichment_form_add_new_dts_subtitle')}
                    </Typography>
                    <SurfaceButton
                      customStartIcon={<AddCircleOutlineSharpIcon />}
                      onClick={() => {
                        setFieldValue(dts_flag.name, true);
                        setFieldValue(
                          dts_clinical_trial_site_list.unit,
                          DTS_UNITS.miles.value
                        );
                        trackEvent('new_dts');
                      }}
                    >
                      {lookup('enrichment_form_add_new_dts_label')}
                    </SurfaceButton>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {values[dts_flag.name] && (
        <DTSPanel
          outputColumns={output_columns}
          values={values}
          setFieldValue={setFieldValue}
          dtsFlag={dts_flag.name}
          uploadField={dts_clinical_trial_site_list}
          currentUploads={values._ui_uploads}
          dtsSourceColumns={dtsSourceColumns}
          title={
            <Typography variant="h5">
              {lookup('enrichment_form_dts_title')}
            </Typography>
          }
        >
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body2" paragraph>
                  {lookup('enrichment_form_dts_subtitle')}
                </Typography>
                <Box pt={2}>
                  <Typography variant="body2" component="div" paragraph>
                    <TooltipContainer>
                      <Box pr={1}>{lookup('enrichment_form_upload_label')}</Box>
                      <Tooltip
                        className={tooltipClasses.root}
                        classes={{ tooltip: tooltipClasses.customWidth }}
                        TitleContent={dts_clinical_trial_site_list.tooltip}
                      />
                    </TooltipContainer>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <UploadField
                  {...dts_clinical_trial_site_list}
                  currentUploads={values._ui_uploads}
                  setFieldValue={setFieldValue}
                  setSubmitting={setSubmitting}
                />
                <Box pt={2}>
                  <Typography variant="body2" paragraph>
                    {lookup('enrichment_form_dts_unit_label')}
                  </Typography>
                  <SelectField
                    name={dts_clinical_trial_site_list.unit}
                    placeholder={dts_clinical_trial_site_list.unitPlaceholder}
                    data={[DTS_UNITS.miles, DTS_UNITS.kilometers]}
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </DTSPanel>
      )}
      <FieldArray
        name={enrichment.name}
        render={arrayHelpers => (
          <>
            {values[enrichment.name].map((claims_enrichment, index) => {
              const enrichmentName = claims_enrichment.event_name;
              return (
                <EnrichmentPanel
                  key={`${enrichmentName}-${index}`}
                  index={index}
                  enrichment={enrichment}
                  outputColumns={output_columns}
                  values={values}
                  setFieldValue={setFieldValue}
                  arrayHelpers={arrayHelpers}
                  title={
                    <TextInputTitleField
                      name={`${enrichment.name}[${index}].event_name`}
                      placeholder={enrichment.fields.event_name.placeholder}
                      customOnChange={newVal => {
                        const columnsToDelete = values[enrichment.name][
                          index
                        ].enrichment_columns.map(item => item.fieldname);
                        updateEventName(
                          `${enrichment.name}[${index}].event_name`,
                          `${enrichment.name}[${index}].enrichment_columns`,
                          newVal,
                          columnsToDelete,
                          output_columns,
                          values
                        );
                      }}
                    />
                  }
                >
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12} sm={9} md={9} lg={9}>
                        <EnrichmentConfiguration
                          name={`${enrichment.name}[${index}].codes`}
                          index={index}
                          enrichment={enrichment}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <EnrichmentColumnsGrid item xs={12} sm={3} md={3} lg={3}>
                        <FormControl component="fieldset">
                          <FormLabel focused={false}>
                            {lookup('enrichment_column_title')}
                          </FormLabel>
                          <EnrichmentColumns
                            name={`${enrichment.name}[${index}].enrichment_columns`}
                            defaultEnrichment={defaultEnrichment}
                            enrichment={enrichment}
                            outputColumns={output_columns}
                            setFieldValue={setFieldValue}
                            values={values}
                            index={index}
                          />
                        </FormControl>
                      </EnrichmentColumnsGrid>
                    </Grid>
                  </AccordionDetails>
                </EnrichmentPanel>
              );
            })}
          </>
        )}
      />
      {/* Display bottom buttons if there are more than 1 card */}
      {(values[enrichment.name].length > 1 ||
        (values[dts_flag.name] && values[enrichment.name].length > 0)) && (
        <EnrichmentButtons
          values={values}
          dtsFlag={dts_flag}
          dtsField={dts_clinical_trial_site_list}
          enrichment={enrichment}
          setFieldValue={setFieldValue}
        />
      )}
    </>
  );
};

EnrichmentForm.label = ENRICHMENT_FORM.label;
EnrichmentForm.path = ENRICHMENT_FORM.path;

export { EnrichmentForm };
