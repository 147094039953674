import * as Yup from 'yup';
import { MAX_LENGTH_MESSAGE, ALERT_CATEGORIES } from 'constants/AppConstants';
import { alertFormModel } from '.';
const {
  formField: {
    client,
    project,
    cohort_name,
    dx_codes_allow_list,
    rx_codes_allow_list,
    px_codes_allow_list,
    dx_codes_deny_list,
    rx_codes_deny_list,
    px_codes_deny_list,
    patient_age,
    hcp_npi_allow_list,
    hcp_npi_deny_list,
    include_any_hcp_specialties,
    exclude_any_hcp_specialties,
    dts_clinical_trial_site_list,
    dts_flag,
    distance_to_site,
    specialties_to_include_path,
    specialties_to_exclude_path,
    npis_to_include_path,
    npis_to_exclude_path,
    allowlist_specialties_flag,
    denylist_specialties_flag,
    allowlist_npis_flag,
    denylist_npis_flag,
    uploads,
    lookback_period,
    alert_name,
    alert_description,
    alert_category,
    alert_category_name,
    medical_event_name,
    code_logic,
    medical_event_dx_codes,
    medical_event_rx_codes,
    medical_event_px_codes,
    era,
    qualifying_window,
    alert_file_name_template,
    delivery_day,
    noise_config,
    noise_reduction,
    dedupe_config,
    enrichment,
    output_columns,
    expected_compound_columns_to_be_unique
  }
} = alertFormModel;

// solution from yup's community to check uniqueness
// https://github.com/jquense/yup/issues/345#issuecomment-454465375
Yup.addMethod(Yup.object, 'uniqueProperty', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }
    if (
      this.parent
        .filter(v => v !== value)
        .some(
          v =>
            v[propertyName]?.toLowerCase() ===
            value[propertyName]?.toLowerCase()
        )
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`
      });
    }
    return true;
  });
});

const validationSchema = [
  Yup.object().shape({
    [client.name]: Yup.number().required(`${client.requiredErrorMsg}`),
    [project.name]: Yup.number().required(`${project.requiredErrorMsg}`)
  }),
  Yup.object().shape({
    [cohort_name.name]: Yup.string()
      .trim()
      .required(`${cohort_name.requiredErrorMsg}`)
      .max(
        cohort_name.maxLength,
        MAX_LENGTH_MESSAGE(cohort_name.label, cohort_name.maxLength)
      ),
    [dx_codes_allow_list.name]: Yup.array(),
    [rx_codes_allow_list.name]: Yup.array(),
    [px_codes_allow_list.name]: Yup.array(),
    [dx_codes_deny_list.name]: Yup.array(),
    [rx_codes_deny_list.name]: Yup.array(),
    [px_codes_deny_list.name]: Yup.array(),
    [patient_age.name]: Yup.array(),
    [hcp_npi_allow_list.name]: Yup.object({
      id: Yup.number(),
      name: Yup.string()
    }),
    [hcp_npi_deny_list.name]: Yup.object({
      id: Yup.number(),
      name: Yup.string()
    }),
    [include_any_hcp_specialties.name]: Yup.object({
      id: Yup.number(),
      name: Yup.string()
    }),
    [exclude_any_hcp_specialties.name]: Yup.object({
      id: Yup.number(),
      name: Yup.string()
    }),

    [specialties_to_include_path.name]: Yup.string(),
    [specialties_to_exclude_path.name]: Yup.string(),
    [npis_to_include_path.name]: Yup.string(),
    [npis_to_exclude_path.name]: Yup.string(),
    [allowlist_specialties_flag.name]: Yup.boolean(),
    [denylist_specialties_flag.name]: Yup.boolean(),
    [allowlist_npis_flag.name]: Yup.boolean(),
    [denylist_npis_flag.name]: Yup.boolean(),
    [uploads.name]: Yup.object(),
    [lookback_period.name]: Yup.number().when(
      [
        dx_codes_allow_list.name,
        rx_codes_allow_list.name,
        px_codes_allow_list.name,
        dx_codes_deny_list.name,
        rx_codes_deny_list.name,
        px_codes_deny_list.name
      ],
      (a, b, c, d, e, f) => {
        if (
          a.length > 0 ||
          b.length > 0 ||
          c.length > 0 ||
          d.length > 0 ||
          e.length > 0 ||
          f.length > 0
        ) {
          return Yup.number().required(`${lookback_period.requiredErrorMsg}`);
        }
      }
    )
  }),
  Yup.object().shape({
    [alert_name.name]: Yup.string()
      .trim()
      .required(`${alert_name.requiredErrorMsg}`)
      .max(
        alert_name.maxLength,
        MAX_LENGTH_MESSAGE(alert_name.label, alert_name.maxLength)
      ),
    [alert_description.name]: Yup.string()
      .trim()
      .required(`${alert_description.requiredErrorMsg}`)
      .max(
        alert_description.maxLength,
        MAX_LENGTH_MESSAGE(alert_description.label, alert_description.maxLength)
      ),
    [alert_category.name]: Yup.number().required(
      `${alert_category.requiredErrorMsg}`
    ),
    [medical_event_name.name]: Yup.string()
      .trim()
      .required(`${medical_event_name.requiredErrorMsg}`)
      .max(
        medical_event_name.maxLength,
        MAX_LENGTH_MESSAGE(
          medical_event_name.label,
          medical_event_name.maxLength
        )
      ),
    [code_logic.name]: Yup.string().required(`${code_logic.requiredErrorMsg}`),
    [medical_event_dx_codes.name]: Yup.array().when(
      [code_logic.name],
      codeLogic => {
        if (codeLogic && codeLogic.includes('dx'))
          return Yup.array().min(
            1,
            `${medical_event_dx_codes.requiredErrorMsg}`
          );
      }
    ),
    [medical_event_rx_codes.name]: Yup.array().when(
      [code_logic.name],
      codeLogic => {
        if (codeLogic && codeLogic.includes('rx'))
          return Yup.array().min(
            1,
            `${medical_event_rx_codes.requiredErrorMsg}`
          );
      }
    ),
    [medical_event_px_codes.name]: Yup.array().when(
      [code_logic.name],
      codeLogic => {
        if (codeLogic && codeLogic.includes('px'))
          return Yup.array().min(
            1,
            `${medical_event_px_codes.requiredErrorMsg}`
          );
      }
    ),
    [era.name]: Yup.number().when([alert_category_name.name], alertCategory => {
      if (
        alertCategory &&
        alertCategory !== ALERT_CATEGORIES.CONTINUOUS_THERAPY
      )
        return Yup.number().required(`${era.requiredErrorMsg}`);
    }),
    [qualifying_window.name]: Yup.number().required(
      `${qualifying_window.requiredErrorMsg}`
    )
  }),
  Yup.object().shape({
    [dts_clinical_trial_site_list.name]: Yup.object().when(
      [dts_flag.name],
      dtsEnabled => {
        if (dtsEnabled) {
          return Yup.object({
            id: Yup.number(),
            name: Yup.string()
          }).test(
            'required',
            dts_clinical_trial_site_list.requiredErrorMsg,
            value => !!(value.id && value.name)
          );
        }
      }
    ),
    [distance_to_site.name]: Yup.object({
      s3_file_uri: Yup.string(),
      is_enabled: Yup.boolean(),
      units_of_measure: Yup.string()
    }),
    [enrichment.name]: Yup.array().of(
      Yup.object()
        .shape({
          event_name: Yup.string()
            .required(enrichment.errorMsg.event_name_required)
            .matches(/^[a-zA-Z0-9_]*$/, enrichment.errorMsg.event_name_regex)
            .max(
              enrichment.fields.event_name.maxLength,
              MAX_LENGTH_MESSAGE(
                enrichment.fields.event_name.label,
                enrichment.fields.event_name.maxLength
              )
            ),
          codes: Yup.object()
            .shape({
              dx_codes: Yup.array(),
              rx_codes: Yup.array(),
              px_codes: Yup.array()
            })
            .test(
              'at-least-one-property',
              enrichment.errorMsg.codes_required,
              value =>
                !!(
                  value.dx_codes.length > 0 ||
                  value.rx_codes.length > 0 ||
                  value.px_codes.length > 0
                )
            ),
          enrichment_columns: Yup.array().min(
            1,
            enrichment.errorMsg.enrichment_columns_required
          ),
          window_in_days: Yup.number().required(
            enrichment.errorMsg.window_in_days_required
          )
        })
        .uniqueProperty('event_name', enrichment.errorMsg.event_name_unique)
    )
  }),
  Yup.object().shape({
    [alert_file_name_template.name]: Yup.string()
      .trim()
      .required(`${alert_file_name_template.requiredErrorMsg}`)
      .matches(
        alert_file_name_template.customValidationRegex,
        alert_file_name_template.customValidationMessage
      )
      .max(
        alert_file_name_template.maxLength,
        MAX_LENGTH_MESSAGE(
          alert_file_name_template.label,
          alert_file_name_template.maxLength
        )
      ),
    [delivery_day.name]: Yup.string().required(
      `${delivery_day.requiredErrorMsg}`
    ),
    [noise_config.name]: Yup.string().required(),
    [noise_reduction.name]: Yup.object({
      columns: Yup.array(),
      lookback_days: Yup.number()
    }),
    [dedupe_config.name]: Yup.array(),
    [expected_compound_columns_to_be_unique.name]: Yup.array(),
    [output_columns.name]: Yup.array()
  })
];

export { validationSchema };
