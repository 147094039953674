import React, { useContext, useEffect, useState, useMemo } from 'react';
import AppContext from 'AppContext';
import { withTheme } from '@material-ui/core/styles';
import { Container, MenuItem, Select } from '@material-ui/core';
import Table from 'components/common/table';
import Breadcrumbs from 'components/common/breadcrumbs';
import { MonitoringService } from 'services';
import {
  MONITORING_STATUS,
  SETTING_ACTIVE_FILTER_MAPPING
} from 'constants/AppConstants';
import Alert from 'components/common/alert';
import MuiAlert from '@material-ui/lab/Alert';
import styled from 'styled-components';
import formatDate from 'utils/date';
import I18n from 'i18n';
import { trackView } from 'utils/analytics';
const { lookup } = new I18n();

const Status = styled(MuiAlert)(
  p => `
  display: inline-flex;
  margin-top: ${p.theme.space.sm};
  &.no-data {
    color: inherit;
    background: none;
    .MuiAlert-icon {
      color: inherit;
    }
  }
`
);

// move these functions to separate file when we've final designs
/******************/
const renderStatus = status => {
  return (
    <Status
      severity={MONITORING_STATUS[status].severity}
      className={MONITORING_STATUS[status].class}
    >
      {MONITORING_STATUS[status].label}
    </Status>
  );
};
/******************/

const Monitoring = ({ theme }) => {
  const appContext = useContext(AppContext);
  const monitoringService = useMemo(() => new MonitoringService(appContext), [
    appContext
  ]);
  const [state, setState] = useState({
    alerts: [],
    loading: true,
    settingActiveFilter: 'active'
  });

  const columns = useMemo(
    () => [
      {
        title: 'Customer',
        field: 'company_name',
        defaultSort: 'asc'
      },
      {
        title: 'Project Name',
        field: 'project_name'
      },
      {
        title: 'Alert Name',
        field: 'alert_name'
      },
      {
        title: 'Description',
        grouping: false,
        field: 'description'
      },
      {
        title: 'Alert ID',
        field: 'alert_id',
        grouping: false,
        type: 'numeric',
        hidden: true,
        hiddenByColumnsButton: true
      },
      {
        title: 'Project Data Rule ID',
        field: 'id',
        grouping: false,
        type: 'numeric',
        hidden: true,
        hiddenByColumnsButton: true
      },
      {
        title: 'Setting ID',
        field: 'project_data_rule_settings.id',
        grouping: false,
        type: 'numeric',
        hidden: true,
        hiddenByColumnsButton: true
      },
      {
        title: 'Setting Active',
        field: 'project_data_rule_settings.is_active',
        grouping: false,
        type: 'boolean',
        hidden: true,
        hiddenByColumnsButton: true,
        defaultFilter:
          SETTING_ACTIVE_FILTER_MAPPING[state.settingActiveFilter].value
      },
      {
        title: 'Cron Expression',
        field: 'project_data_rule_settings.cron_expression',
        grouping: false,
        hidden: true,
        hiddenByColumnsButton: true
      },
      {
        title: 'S3 Bucket Name',
        field: 's3_bucket_name',
        grouping: false,
        hidden: true,
        hiddenByColumnsButton: true
      },
      {
        title: 'S3 Key Prefix',
        field: 's3_key_prefix',
        grouping: false,
        hidden: true,
        hiddenByColumnsButton: true
      },
      {
        title: 'FTP Default Path',
        field: 'project_data_rule_settings.ftp_default_path',
        grouping: false,
        hidden: true,
        hiddenByColumnsButton: true
      },
      {
        title: 'FTP Host',
        field: 'project_data_rule_settings.ftp_host',
        grouping: false,
        hidden: true,
        hiddenByColumnsButton: true
      },
      {
        title: 'Notify Email Address',
        grouping: false,
        field: 'project_data_rule_settings.notify_email_address',
        hidden: true,
        hiddenByColumnsButton: true
      },
      {
        title: 'Previous Delivery',
        grouping: false,
        field: 'project_data_rule_settings.previous_delivery',
        render: rowData =>
          formatDate(rowData.project_data_rule_settings.previous_delivery)
      },
      {
        title: 'Recent Delivery',
        grouping: false,
        field: 'project_data_rule_settings.recent_delivery',
        render: rowData =>
          formatDate(rowData.project_data_rule_settings.recent_delivery)
      },
      {
        title: 'Next Delivery',
        grouping: false,
        field: 'project_data_rule_settings.next_delivery',
        render: rowData =>
          formatDate(rowData.project_data_rule_settings.next_delivery)
      },
      {
        title: 'Previous Status',
        field: 'project_data_rule_settings.previous_status',
        render: value =>
          renderStatus(
            value?.project_data_rule_settings?.previous_status || value
          )
      },
      {
        title: 'Status',
        field: 'project_data_rule_settings.recent_status',
        render: value =>
          renderStatus(
            value?.project_data_rule_settings?.recent_status || value
          )
      }
    ],
    [state.settingActiveFilter]
  );

  const init = () => {
    trackView('monitoring');
    monitoringService.get().then(
      alerts => {
        setState(prevState => ({
          ...prevState,
          alerts,
          loading: false
        }));
      },
      err => {
        setState(() => {
          throw new Error(err);
        });
      }
    );
  };

  useEffect(init, [monitoringService]);

  return (
    <Container>
      <Alert />
      <Table
        title={
          <Breadcrumbs
            data={[
              {
                name: lookup('breadcrumb_alert')
              }
            ]}
          />
        }
        options={{
          grouping: true,
          pageSize: 10,
          maxBodyHeight: 550,
          columnsButton: true,
          headerStyle: theme.typography.h6
        }}
        columns={columns}
        data={state.alerts}
        isLoading={state.loading}
        actions={[
          {
            icon: 'is_active',
            isFreeAction: true,
            onClick: () => {},
            onChange: settingActiveFilter =>
              setState({
                ...state,
                settingActiveFilter
              })
          }
        ]}
        components={{
          Action: props => (
            <Select
              id="active-filter-select"
              value={state.settingActiveFilter}
              onChange={e => {
                props.action.onChange(e.target.value);
              }}
            >
              {Object.keys(SETTING_ACTIVE_FILTER_MAPPING).map((key, index) => {
                return (
                  <MenuItem key={key} value={key}>
                    {SETTING_ACTIVE_FILTER_MAPPING[key].label}
                  </MenuItem>
                );
              })}
            </Select>
          )
        }}
      />
    </Container>
  );
};

export default withTheme(Monitoring);
