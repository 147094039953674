import React, { useContext, useState, useEffect, useMemo } from 'react';
import AppContext from 'AppContext';
import { DeliverySettingService } from 'services';
import Container from '@material-ui/core/Container';
import Table from 'components/common/table';
import Breadcrumbs from 'components/common/breadcrumbs';
import { required } from 'utils/validation';
import AppRoutes from 'constants/AppRoutes';
import Alert from 'components/common/alert';
import I18n from 'i18n';
import { trackEvent, trackView } from 'utils/analytics';
import formatDate from 'utils/date';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

const { lookup } = new I18n();
const MultiLineTextField = styled(TextField)`
  min-width: 200px;
`;
const columns = [
  { title: 'ID', field: 'id', type: 'numeric', editable: 'never' },
  {
    title: 'Cron Expression (PT)',
    field: 'cron_expression',
    type: 'string',
    validate: ({ cron_expression }) => required(cron_expression)
  },
  {
    title: 'Ftp Default Path',
    field: 'ftp_default_path',
    type: 'string'
  },
  {
    title: 'Ftp Host',
    field: 'ftp_host',
    type: 'string',
    validate: ({ ftp_host }) => required(ftp_host)
  },
  {
    title: 'Ftp Username',
    field: 'ftp_username',
    type: 'string',
    validate: ({ ftp_username }) => required(ftp_username)
  },
  {
    title: 'Ftp Password',
    field: 'ftp_password',
    type: 'string',
    validate: ({ ftp_password }) => required(ftp_password),
    render: ({ ftp_password }) => (
      <TextField disabled type={'password'} value={ftp_password} />
    )
  },
  {
    title: 'Is Active',
    field: 'is_active',
    type: 'boolean',
    initialEditValue: true
  },
  {
    title: 'Notify Email Address',
    field: 'notify_email_address',
    type: 'string',
    render: ({ notify_email_address }) => (
      <MultiLineTextField
        multiline
        rowsMax={4}
        value={notify_email_address || ''}
        disabled
      />
    ),
    editComponent: ({ value, onChange }) => (
      <MultiLineTextField
        multiline
        value={value || ''}
        onChange={e => onChange(e.target.value)}
      />
    )
  },
  {
    title: 'Project Data Rule ID',
    field: 'project_data_rule_id',
    type: 'numeric',
    editable: 'never'
  },
  {
    title: 'Created At',
    editable: 'never',
    field: 'created_at',
    render: ({ created_at }) => formatDate(created_at)
  },
  {
    title: 'Updated At',
    field: 'updated_at',
    editable: 'never',
    render: ({ updated_at }) => formatDate(updated_at)
  }
];

const DeliverySetting = props => {
  const { token, displayErrorMessage } = useContext(AppContext);
  const {
    companyId,
    projectId,
    companyName,
    projectName,
    projectDataRuleId
  } = props.match.params;
  const deliverySettingsService = useMemo(
    () => new DeliverySettingService(token),
    [token]
  );

  const [state, setState] = useState({
    deliverySettings: [],
    loading: true
  });

  const [alertMessage, setAlertMessage] = useState({
    message: '',
    open: false
  });

  const onAlertClose = () => {
    setAlertMessage({
      ...alertMessage,
      open: false
    });
  };

  /**
   * Update state on successful create/edit
   * @param {Array} deliverySetting updated deliverySetting
   * @param {string} message message to display on successful create/edit
   * @param {function} callback callback function
   */
  const onSave = (deliverySettings, message, callback) => {
    setState({
      ...state,
      deliverySettings
    });
    setAlertMessage({
      message,
      open: true
    });
    trackEvent('delivery_setting_saved', message);
    callback();
  };

  const init = () => {
    trackView('delivery_settings');
    deliverySettingsService
      .get(projectDataRuleId)
      .then(deliverySettings => {
        setState({
          deliverySettings,
          loading: false
        });
      })
      .catch(err => {
        setState(() => {
          throw new Error(err);
        });
      });
  };

  useEffect(init, [projectDataRuleId, deliverySettingsService]);

  return (
    <Container>
      <Alert onClose={onAlertClose} {...alertMessage} />
      <Table
        title={
          <Breadcrumbs
            data={[
              {
                name: lookup('breadcrumb_company'),
                href: AppRoutes.COMPANY
              },
              {
                name: `${lookup('breadcrumb_project')} (${companyName})`,
                href: `${AppRoutes.PROJECT}/${companyId}/${companyName}`
              },
              {
                name: `${lookup('breadcrumb_pdr')} (${projectName})`,
                href: `${AppRoutes.PROJECT_DATA_RULE}/${companyId}/${companyName}/${projectId}/${projectName}`
              },
              {
                name: lookup('breadcrumb_delivery_setting')
              }
            ]}
          />
        }
        data={state.deliverySettings}
        columns={columns}
        isLoading={state.loading}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              const data = {
                ...newData,
                project_data_rule_id: projectDataRuleId
              };
              deliverySettingsService.create(data).then(
                newDeliverySetting => {
                  const deliverySettings = [
                    newDeliverySetting,
                    ...state.deliverySettings
                  ];
                  onSave(
                    deliverySettings,
                    `${newDeliverySetting.id} ${lookup(
                      'table_create_message'
                    )}`,
                    resolve
                  );
                },
                err => {
                  displayErrorMessage(err, setAlertMessage, setState);
                  reject();
                }
              );
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const updatedTableData = [...state.deliverySettings];
              deliverySettingsService.update(newData).then(
                newDeliverySetting => {
                  updatedTableData[oldData.tableData.id] = newDeliverySetting;
                  onSave(
                    updatedTableData,
                    `${newDeliverySetting.id} ${lookup(
                      'table_update_message'
                    )}`,
                    resolve
                  );
                },
                err => {
                  displayErrorMessage(err, setAlertMessage, setState);
                  reject();
                }
              );
            })
        }}
      />
    </Container>
  );
};

export default React.memo(DeliverySetting);
