import ENDPOINT from 'utils/api';
import { handleErrors } from 'utils/error';
import { ProjectService, CompanyService } from '.';

class MonitoringService {
  /**
   * 'Constructor' for MonitoringService
   * @param {string} token auth0 token
   * @param {Array} companies array of companies
   * @param {string} role logged in user's role
   */
  constructor({ token, companies, role }) {
    this.path = `${ENDPOINT}alert_settings`;
    this.userCompanies = Array.isArray(companies)
      ? companies.map(({ id }) => id)
      : [];
    this.projectService = new ProjectService(token);
    this.companyService = new CompanyService({ token, companies, role });
    this.requestHeader = {
      headers: {
        'Access-Token': token,
        'Content-Type': 'application/json'
      }
    };
  }

  /**
   * Get Alert Settings
   * @async
   * @return {Promise} Alert Settings.
   */
  async get() {
    try {
      const companies = await this.companyService.get();
      // store company id / name pair
      const companiesLookup = companies.reduce(
        (obj, company) => Object.assign(obj, { [company.id]: company.name }),
        {}
      );

      const projects = await this.projectService.getByIds(this.userCompanies);
      // store project id / name - companyName pair
      const projectsLookup = projects.reduce(
        (obj, project) =>
          Object.assign(obj, {
            [project.id]: {
              name: project.name,
              companyName: companiesLookup[project.company_id]
            }
          }),
        {}
      );

      // query params for /alert_settings
      const params = new URLSearchParams();
      Object.keys(projectsLookup).forEach(id => {
        params.append('project_id', id);
      });

      const res = await fetch(
        `${this.path}?${params.toString()}`,
        this.requestHeader
      );
      if (!res.ok) {
        throw res;
      }

      // temp code
      /******************/

      const alerts = await res.json();
      let retVal = [];
      alerts.forEach(alert => {
        if (alert.project_data_rule_settings?.length > 0) {
          alert.project_data_rule_settings.forEach(pdr => {
            let tempAlert = { ...alert };
            tempAlert.project_name = projectsLookup[alert.project_id].name;
            tempAlert.company_name =
              projectsLookup[alert.project_id].companyName;
            tempAlert.project_data_rule_settings = pdr;
            tempAlert.project_data_rule_settings.previous_status =
              tempAlert.project_data_rule_settings.previous_status || 'no-data';
            tempAlert.project_data_rule_settings.recent_status =
              tempAlert.project_data_rule_settings.recent_status || 'no-data';
            retVal.push(tempAlert);
          });
        } else {
          let tempAlert = { ...alert };
          tempAlert.project_name = projectsLookup[alert.project_id].name;
          tempAlert.company_name = projectsLookup[alert.project_id].companyName;
          tempAlert.project_data_rule_settings.previous_status = 'no-data';
          tempAlert.project_data_rule_settings.recent_status = 'no-data';
          retVal.push(tempAlert);
        }
      });

      return retVal;

      /******************/
    } catch (e) {
      throw await handleErrors(e);
    }
  }
}

export { MonitoringService };
