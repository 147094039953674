import React from 'react';
import styled, { css } from 'styled-components';
import Container from '@material-ui/core/Container';

const Wrapper = styled.div(
  p => css`
    display: flex;
    align-items: center;
    height: 50px;
    background-color: ${p.theme.colors.brand};
    ${p.theme.typography.h5};
    color: ${p.theme.colors.light};
  `
);

const Footer = props => {
  return (
    <Wrapper>
      <Container>{props.children}</Container>
    </Wrapper>
  );
};

export default Footer;
