import React, { useContext, useMemo, useRef } from 'react';
import { at } from 'lodash';
import {
  InputAdornment,
  TextField as MuiTextField,
  IconButton,
  FormControl,
  Divider
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import HelperText from 'components/common/helperText';
import { UploadService } from 'services';
import AppContext from 'AppContext';
import styled, { css } from 'styled-components';
import { useField } from 'formik';
import { trackEvent } from 'utils/analytics';

const TextField = styled(MuiTextField)(
  p => css`
    background-color: ${p.theme.colors.white};
    max-width: 360px;
    .Mui-disabled {
      border-radius: 0;
      ${p.theme.typography.body2};
      color: inherit;
      text-transform: none;
      cursor: pointer;
    }
    .MuiInputBase-adornedEnd {
      padding: 0;
    }
  `
);

const AdornmentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UploadField = props => {
  const { token } = useContext(AppContext);
  const uploadService = useMemo(() => new UploadService(token), [token]);
  const {
    fullWidth = true,
    setFieldValue,
    setSubmitting,
    currentUploads,
    placeholder,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(rest);

  const onChange = ({ target }) => {
    const file = target.files[0];
    setSubmitting(true);

    uploadService
      .upload(file, props.uploadType)
      .then(
        res => {
          // update all file upload references
          setFieldValue(props.name, {
            id: res.id,
            name: res.file_name
          });
          setFieldValue(props.path, res.file_path);
          setFieldValue(props.flag, true);
          setFieldValue(props.uploadObject, {
            ...currentUploads,
            [props.uploadType]: res.id
          });
          trackEvent('file_uploaded', props.uploadType);
        },
        err => {
          resetField().then(() => {
            // set error and field touched but don't trigger any extra yup validations for API errors
            helpers.setTouched(true, false);
            helpers.setError(err);
          });
        }
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onDelete = e => {
    e.stopPropagation();
    resetField();
  };

  const resetField = async () => {
    // remove/reset all file upload references
    const { [props.uploadType]: _, ...newUploads } = currentUploads;
    return Promise.all([
      setFieldValue(props.name, ''),
      setFieldValue(props.path, ''),
      setFieldValue(props.flag, false),
      setFieldValue(props.uploadObject, newUploads)
    ]);
  };

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <HelperText error={true}>{error}</HelperText>;
    }
  };

  const fileInputRef = useRef();

  const fileInputProps = {
    style: { display: 'none' },
    type: 'file',
    onChange,
    onClick: e => {
      e.target.value = '';
    },
    ref: fileInputRef,
    accept: '.csv',
    id: `${props.name}-upload`
  };

  return (
    <>
      <FormControl fullWidth={fullWidth}>
        <TextField
          variant="outlined"
          disabled
          placeholder={placeholder}
          value={field.value && field.value.name}
          onClick={() => fileInputRef.current.click()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AdornmentContainer>
                  {field.value && field.value.name && (
                    <IconButton onClick={onDelete}>
                      <HighlightOffIcon />
                    </IconButton>
                  )}
                  <Divider orientation="vertical" flexItem />
                  <IconButton>
                    <ArrowUpward />
                  </IconButton>
                </AdornmentContainer>
              </InputAdornment>
            )
          }}
        />
        {renderHelperText()}
        <input {...fileInputProps} />
      </FormControl>
    </>
  );
};
