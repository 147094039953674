import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { SelectField } from '../../FormFields';
import { SAAS_FORM } from 'constants/AppConstants';
import { trackView } from 'utils/analytics';
const { CUSTOMER_FORM } = SAAS_FORM;

const SELECT_MAX_WIDTH = '432px';

const CustomerForm = props => {
  const {
    formField: {
      client,
      client_name,
      client_acronym,
      project,
      project_ta_acronym
    },
    formikProps: { values, setFieldValue },
    companies,
    projects,
    isCreate
  } = props;

  useEffect(() => {
    trackView('saas_customer');
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <SelectField
        name={client.name}
        label={client.label}
        placeholder={client.placeholder}
        data={companies}
        variant="outlined"
        disabled={!isCreate}
        displayEmpty
        onChange={e => {
          // custom onChange handler
          const { value } = e.target;
          const selectedCompany = companies.find(
            company => company.value === value
          );
          setFieldValue(client.name, value);
          // set additional values
          setFieldValue(client_name.name, selectedCompany.label);
          setFieldValue(client_acronym.name, selectedCompany.acronym);
          // reset project on client change
          setFieldValue(project.name, '');
        }}
        onClose={() => {
          setTimeout(() => {
            document.activeElement.blur();
          }, 0);
        }}
        fullWidth
        maxwidth={SELECT_MAX_WIDTH}
      />
      <SelectField
        name={project.name}
        label={project.label}
        placeholder={project.placeholder}
        data={projects.filter(
          project => project.company_id === values[client.name]
        )}
        disabled={!isCreate || !values[client.name]}
        variant="outlined"
        displayEmpty
        onChange={e => {
          // custom onChange handler
          const { value } = e.target;
          setFieldValue(project.name, value);
          setFieldValue(
            project_ta_acronym.name,
            projects.find(project => project.value === value).therapy_area
          );
        }}
        onClose={() => {
          setTimeout(() => {
            document.activeElement.blur();
          }, 0);
        }}
        fullWidth
        maxwidth={SELECT_MAX_WIDTH}
      />
    </Box>
  );
};

CustomerForm.label = CUSTOMER_FORM.label;
CustomerForm.path = CUSTOMER_FORM.path;

export { CustomerForm };
