import React from 'react';
import { useField } from 'formik';
import { Typography } from '@material-ui/core';
import { Slider as KomodoSlider } from '@komodohealth/fs-harmony.form.slider';
import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components';

const SliderWrapper = styled.div`
  max-width: 500px;
  width: 100%;
`;

const Slider = styled(KomodoSlider)(
  p => `
    width: 100%;
    ${
      p.labelhelpertext &&
      `
      padding-top: ${p.theme.space.md};
    `
    }
  `
);

const LabelHelperText = styled.div(
  p => `
    min-height: ${p.theme.space.md};
    display: flex;
    justify-content: space-between;
    text-transform: none;
  `
);

export const SliderField = props => {
  const { setFieldValue, labelHelperText, ...rest } = props;
  const [field] = useField(props);

  const debounced = useDebouncedCallback(value => {
    setFieldValue([field.name], value);
  }, 100);

  return (
    <SliderWrapper>
      <Slider
        // don't pass {..field}, change and blur event handlers don't wire themselves up correctly
        // set default value to [min, max]
        value={field.value.length > 0 ? field.value : [rest.min, rest.max]}
        onChange={(e, newVal) => debounced(newVal)}
        labelhelpertext={labelHelperText}
        {...rest}
      />
      {labelHelperText && (
        <LabelHelperText>
          <Typography variant="caption">
            {labelHelperText?.left &&
              field.value[0] === labelHelperText.left.value &&
              labelHelperText.left.label}
          </Typography>
          <Typography variant="caption">
            {labelHelperText?.right &&
              field.value[1] === labelHelperText.right.value &&
              labelHelperText.right.label}
          </Typography>
        </LabelHelperText>
      )}
    </SliderWrapper>
  );
};
