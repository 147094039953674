class User {
  /**
   * 'Constructor' for User Model
   * @param {Object} obj
   */
  constructor(obj) {
    if (obj) {
      this.id = obj.sub;
      this.displayName = obj?.nickname || '';
      this.logoPath = obj.logoPath;
      this.firstName = obj?.firstName || '';
      this.lastName = obj?.lastName || '';
      this.email = obj?.email || '';
    }
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export { User };
