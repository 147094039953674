import ENDPOINT from 'utils/api';
import { handleErrors } from 'utils/error';

class DataRuleService {
  /**
   * 'Constructor' for DataRuleService
   * @param {string} token auth0 token
   */
  constructor(token) {
    this.path = `${ENDPOINT}data_rules`;
    this.requestHeader = {
      headers: {
        'Access-Token': token,
        'Content-Type': 'application/json'
      }
    };
  }

  /**
   * Get all Data Rules
   * @async
   * @return {Promise} list of data rules.
   */
  async get() {
    try {
      const res = await fetch(this.path, this.requestHeader);
      if (!res.ok) {
        throw res;
      }
      return await res.json();
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Get all Data Rules as a map
   * @async
   * @return {Promise} map of data rules. Looks like {id: name}
   */
  async getDataRuleMap() {
    try {
      const fetchedDataRules = await this.get();
      return fetchedDataRules.reduce((acc, cur, index) => {
        acc[cur.id] = cur.name;
        return acc;
      }, {});
    } catch (e) {
      throw await handleErrors(e);
    }
  }
}

export { DataRuleService };
