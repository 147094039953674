/**
 * Send logged in user's information to analytics
 * @param {Object} user
 */
export const setIdentity = user => {
  // i.e auth0|5e95f112c225200cf2378cd2
  const id = user?.id?.split('|')[1];
  if (id) {
    window.analytics &&
      window.analytics.identify(id, {
        name: user.displayName,
        email: user.email
      });
  }
};

/**
 * Send events to analytics
 * @param {string} eventName
 * @param {string} val
 */
export const trackEvent = (eventName, val) => {
  window.analytics &&
    EVENTS[eventName] &&
    window.analytics.track(EVENTS[eventName], val && { label: val });
};

/**
 * Send page views to analytics
 * @param {string} pageName
 */
export const trackView = pageName => {
  window.analytics && PAGES[pageName] && window.analytics.page(PAGES[pageName]);
};

const EVENTS = {
  company_saved: 'Company Saved',
  project_saved: 'Project Saved',
  project_data_rule_saved: 'Project Data Rule Saved',
  delivery_setting_saved: 'Delivery Setting Saved',
  file_uploaded: 'File Uploaded',
  alert_category: 'Alert Category Selected',
  code_logic: 'Code Logic Selected',
  new_enrichment: 'New Enrichment Added',
  new_dts: 'New Distance to Clinical Trial Site Added',
  aggregation_selected: 'Enrichment Aggregation Selected',
  output_column_added: 'Output Column Added',
  output_column_removed: 'Output Column Removed',
  noise_reduction_changed: 'Noise Reduction Changed'
};

const PAGES = {
  monitoring: 'Monitoring',
  company: 'Company',
  project: 'Project',
  project_data_rule: 'Project Data Rule',
  delivery_settings: 'Delivery Settings',
  saas_list: 'SaaS Alert List',
  saas_customer: 'SaaS Client Setup',
  saas_cohort: 'SaaS Cohort',
  saas_alert_trigger: 'SaaS Alert Trigger',
  saas_enrichment: 'SaaS Enrichment',
  saas_output: 'SaaS Alert Output',
  saas_review: 'SaaS Review'
};
