import ENDPOINT from 'utils/api';
import { handleErrors } from 'utils/error';

class CodeService {
  /**
   * 'Constructor' for CodeService
   * @param {string} token auth0 token
   */
  constructor(token) {
    this.path = `${ENDPOINT}code_search`;
    this.requestHeader = {
      headers: {
        'Access-Token': token,
        'Content-Type': 'application/json'
      }
    };
  }

  /**
   * Search codes
   * @async
   * @param {string} code code string to search
   * @param {string} codeType selected code type
   * @return {Promise} Array of codes.
   */
  async search(code, codeType) {
    try {
      const res = await fetch(
        `${this.path}/suggest?max_result_window=50&code=${code}&code_type=${codeType}`,
        this.requestHeader
      );
      if (!res.ok) {
        throw res;
      }
      const codes = await res.json();
      const hits = codes.hits.hits;
      // filter out range codes i.e 150-15999 from response
      return Object.keys(hits)
        .map(key => hits[key]._source)
        .filter(item => !item.id.includes('-'));
    } catch (e) {
      // return empty array for any error
      // print error for debugging
      console.error(e);
      return [];
    }
  }

  /**
   * Bulk validate codes
   * @async
   * @param {Object} data object of codes and code type
   * @return {Promise} Array of invalid_codes or empty array.
   */
  async validate(data) {
    const requestOptions = {
      ...this.requestHeader,
      method: 'POST',
      body: JSON.stringify(data)
    };
    try {
      const res = await fetch(`${this.path}/bulk`, requestOptions);
      if (!res.ok) {
        throw res;
      }
      const { invalid_codes } = await res.json();
      if (Array.isArray(invalid_codes)) {
        return invalid_codes;
      } else {
        throw Error();
      }
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Helper function to parse codes for bulk validation
   * C/P from Prism
   * @param {string} val codes string
   * @return {Array} Array of codes
   */
  parseCodes(val) {
    return val
      ?.replace(/[-.'"]+/g, '')
      ?.split(/[-|:, \s/]+/)
      .filter(Boolean);
  }
}

export { CodeService };
