import React, { useState, useContext, useMemo } from 'react';
import { at } from 'lodash';
import Chip from '@komodohealth/fs-harmony.ui.chip';
import Multiselect from '@komodohealth/fs-harmony.form.multiselect';
import DropdownItem from '@komodohealth/fs-harmony.form.dropdown-item';
import { Button } from '@komodohealth/fs-harmony.ui.button';
import { TextInput } from '@komodohealth/fs-harmony.form.text-input';
import Dialog from 'components/common/dialog';
import Alert from 'components/common/alert';
import HelperText from 'components/common/helperText';
import {
  FormControl,
  TextField,
  CircularProgress,
  Popper,
  Link,
  Typography,
  Checkbox,
  FormControlLabel,
  Box
} from '@material-ui/core';
import { CodeService } from 'services';
import AppContext from 'AppContext';
import { useDebouncedCallback } from 'use-debounce';
import { useField } from 'formik';
import styled, { css } from 'styled-components';
import I18n from 'i18n';
import { VirtuosoGrid } from 'react-virtuoso';
import { makeStyles } from '@material-ui/core/styles';
import ResizeObserver from 'resize-observer-polyfill';

const countFormatter = Intl.NumberFormat('en', { notation: 'compact' });

// polyfill for ResizeObserver which is required by VirtuosoGrid
if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

const { lookup } = new I18n();

const useStyles = makeStyles(theme => ({
  renderTags: {
    width: '100%'
  }
}));

const BulkAddLink = styled(Link)(
  p => css`
    position: absolute;
    bottom: 5px;
    right: 5px;
    cursor: pointer;
    // temp style, will move this link
    font-size: 12px;
  `
);

const KomodoChip = styled(Chip)(
  p => `
    width: 100%;
    justify-content: flex-start;
    .MuiChip-label{
      padding-left: ${p.theme.space.sm};
      padding-right: 0;
    }
    .MuiChip-deleteIcon{
      margin-left: auto;
      margin-right: 2px;
    }
  `
);

const ItemContainer = styled.div`
  ${({ theme }) => `
    width: 33%;
    display: flex;
    flex: none;
    align-content: stretch;
    ${theme.breakpoints.down('sm')} {
      width: 50%;
    }
  `}
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CODE_MAP = {
  ICD: { bgColor: 'rgb(240, 95, 20)', label: 'ICD' },
  NDC: { bgColor: 'rgb(15, 198, 30)', label: 'NDC' },
  Procedure: { bgColor: 'rgb(48, 176, 229)', label: 'CPT' }
};

const popperStyle = {
  zIndex: 0
};

const renderDropdownItem = (value, codeType) => {
  return (
    <DropdownItem
      title={value.id}
      titleSupport={`(${countFormatter.format(value.counts)})`}
      hasIcon
      iconColor={CODE_MAP[codeType].bgColor}
      iconContent={CODE_MAP[codeType].label}
      description={value.description}
    />
  );
};

const renderTags = (value, getCustomizedTagProps, codeType) => {
  // set height based on code count
  // max height = 200px / 5 rows
  const heightMultiplier = value.length <= 12 ? Math.ceil(value.length / 3) : 5;

  return (
    <VirtuosoGrid
      style={{ height: `${heightMultiplier * 40}px` }}
      totalCount={value.length}
      components={{
        Item: ItemContainer,
        List: ListContainer
      }}
      itemContent={index => {
        return (
          <KomodoChip
            label={value[index]}
            hasIcon
            iconContent={CODE_MAP[codeType].label}
            iconColor={CODE_MAP[codeType].bgColor}
            {...getCustomizedTagProps({ index })}
          />
        );
      }}
    />
  );
};

export const CodeSelectField = props => {
  const { token } = useContext(AppContext);
  const classes = useStyles();
  const codeService = useMemo(() => new CodeService(token), [token]);
  const {
    setFieldValue,
    codeType,
    placeholder,
    fullWidth = true,
    ...rest
  } = props;
  const [field, meta] = useField(rest);

  const [options, setOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [bulkCodes, setBulkCodes] = useState('');
  const [bulkCodesLoading, setBulkCodesLoading] = useState(false);
  const [bulkModalOpen, setBulkModalOpen] = useState(false);
  const [bulkValidationDisabled, setBulkValidationDisabled] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    message: '',
    open: false
  });

  const dismissErrorMessage = () => {
    setErrorMessage({
      ...errorMessage,
      open: false
    });
  };

  const debouncedOnChangeHandler = useDebouncedCallback(e => {
    const { value } = e.target;
    if (value.length > 2) {
      setOptions([]);
      setOptionsLoading(true);
      codeService.search(value, codeType).then(res => {
        setOptions(res);
        setOptionsLoading(false);
        // no need for error handler, service will return empty []
      });
    }
  }, 300);

  const validateBulkCodes = () => {
    const codesToValidate = codeService.parseCodes(bulkCodes);
    if (bulkValidationDisabled) {
      setFieldValue(field.name, [
        ...new Set([...field.value, ...codesToValidate])
      ]);
      dismissErrorMessage();
      setBulkModalOpen(false);
      setBulkValidationDisabled(false);
    } else {
      setBulkCodesLoading(true);
      codeService
        .validate({
          codes: codesToValidate.toString(),
          code_type: codeType
        })
        .then(
          invalid_codes => {
            if (invalid_codes.length > 0) {
              setErrorMessage({
                message: `${lookup(
                  'bulk_codes_modal_invalid_codes_label'
                )} ${invalid_codes.toString()}`,
                type: 'error',
                open: true
              });
            } else {
              setFieldValue(field.name, [
                ...new Set([...field.value, ...codesToValidate])
              ]);
              dismissErrorMessage();
              setBulkModalOpen(false);
            }
          },
          err => {
            setErrorMessage({
              message: lookup('unhandled_exception_label'),
              type: 'error',
              open: true
            });
          }
        )
        .finally(() => {
          setBulkCodesLoading(false);
        });
    }
  };

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <HelperText error={true}>{error}</HelperText>;
    }
  };

  return (
    <>
      <FormControl fullWidth={fullWidth}>
        <Multiselect
          name={field.name}
          value={field.value}
          freeSolo
          classes={classes}
          error={meta.touched && meta.error && true}
          onChange={(e, newVal) => {
            setFieldValue(
              field.name,
              newVal.map(val => val.id || val)
            );
          }}
          options={options}
          loading={optionsLoading}
          renderOption={option => renderDropdownItem(option, codeType)}
          getOptionSelected={(option, value) => {
            return option.id === value;
          }}
          filterOptions={options => options}
          renderTags={(value, getCustomizedTagProps) =>
            renderTags(value, getCustomizedTagProps, codeType)
          }
          PopperComponent={({ style, ...props }) => (
            <Popper
              style={{ ...popperStyle, ...style }}
              {...props}
              modifiers={{
                flip: {
                  enabled: false
                }
              }}
            />
          )}
          maxRowsBeforeScroll={5}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={placeholder}
              variant="outlined"
              onChange={debouncedOnChangeHandler}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {optionsLoading && <CircularProgress size={20} />}
                    {field.value.length > 0 && params.InputProps.endAdornment}
                    <BulkAddLink
                      type="button"
                      onClick={() => setBulkModalOpen(true)}
                    >
                      {lookup('bulk_codes_add_button')}
                    </BulkAddLink>
                  </>
                )
              }}
            />
          )}
        />
        <Dialog
          title={placeholder}
          body={
            <>
              <Typography variant="h6" paragraph>
                {lookup('bulk_codes_modal_info_label')}
              </Typography>
              <Alert
                onClose={dismissErrorMessage}
                {...errorMessage}
                noMinHeight
              />
              {errorMessage.open && (
                <Box pt={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bulkValidationDisabled}
                        onChange={e =>
                          setBulkValidationDisabled(e.target.checked)
                        }
                      />
                    }
                    label={
                      <Typography variant="h6">
                        {lookup('bulk_codes_modal_disable_validation_label')}
                      </Typography>
                    }
                  />
                </Box>
              )}
              <Box pt={1}>
                <TextInput
                  multiline
                  value={bulkCodes}
                  onChange={e => setBulkCodes(e.target.value)}
                  rows={5}
                  rowsMax={5}
                />
              </Box>
            </>
          }
          handleClose={() => setBulkModalOpen(false)}
          isOpen={bulkModalOpen}
          footer={
            <>
              <Button
                variant="outlined"
                onClick={() => setBulkModalOpen(false)}
              >
                {lookup('bulk_codes_modal_cancel_button')}
              </Button>
              <Button
                disabled={bulkCodes.length < 3 || bulkCodesLoading}
                onClick={validateBulkCodes}
              >
                {lookup('bulk_codes_modal_add_button')}{' '}
                {bulkCodesLoading && (
                  <CircularProgress color="inherit" size={12} />
                )}
              </Button>
            </>
          }
        />
        {renderHelperText()}
      </FormControl>
    </>
  );
};
