import React, { useContext, useEffect, useState, useMemo } from 'react';
import AppContext from 'AppContext';
import Alert from 'components/common/alert';
import { withTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from 'components/common/link';
import Table from 'components/common/table';
import Breadcrumbs from 'components/common/breadcrumbs';
import AppRoutes from 'constants/AppRoutes';
import { required } from 'utils/validation';
import formatDate from 'utils/date';
import { CompanyService } from 'services';
import { trackView, trackEvent } from 'utils/analytics';
import I18n from 'i18n';
const { lookup } = new I18n();

const Company = props => {
  const appContext = useContext(AppContext);
  const { displayErrorMessage } = appContext;
  const companyService = useMemo(() => new CompanyService(appContext), [
    appContext
  ]);

  const [state, setState] = useState({
    companies: [],
    loading: true
  });

  const columns = useMemo(
    () => [
      {
        title: 'Company Name',
        field: 'name',
        validate: ({ name }) => required(name),
        render: ({ name, id }) => (
          <Link
            component="button"
            color="inherit"
            variant="button"
            underline="always"
            onClick={() => {
              props.history.push(`${AppRoutes.PROJECT}/${id}/${name}`);
            }}
          >
            {name}
          </Link>
        )
      },
      {
        title: 'Acronym',
        field: 'acronym',
        editable: 'onAdd',
        validate: ({ acronym }) => required(acronym)
      },
      {
        title: 'ID',
        field: 'id',
        type: 'numeric',
        align: 'justify',
        editable: 'never'
      },
      {
        title: 'Created At',
        editable: 'never',
        field: 'created_at',
        render: ({ created_at }) => formatDate(created_at)
      },
      {
        title: 'Updated At',
        field: 'updated_at',
        editable: 'never',
        render: ({ updated_at }) => formatDate(updated_at)
      }
    ],
    [props.history]
  );

  const [alertMessage, setAlertMessage] = useState({
    message: '',
    open: false
  });

  const onAlertClose = () => {
    setAlertMessage({
      ...alertMessage,
      open: false
    });
  };

  /**
   * Update state on successful create/edit
   * @param {Array} companies updated companies
   * @param {string} message message to display on successful create/edit
   * @param {function} callback callback function
   */
  const onSave = (companies, message, callback) => {
    setState({
      ...state,
      companies
    });
    setAlertMessage({
      message,
      open: true
    });
    trackEvent('company_saved', message);
    callback();
  };

  const init = () => {
    trackView('company');
    companyService.get().then(
      companies => {
        setState({
          companies,
          loading: false
        });
      },
      err => {
        setState(() => {
          throw new Error(err);
        });
      }
    );
  };

  useEffect(init, [companyService]);

  return (
    <Container>
      <Alert onClose={onAlertClose} {...alertMessage} />
      <Table
        title={
          <Breadcrumbs
            data={[
              {
                name: lookup('breadcrumb_company')
              }
            ]}
          />
        }
        columns={columns}
        data={state.companies}
        isLoading={state.loading}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              companyService.create(newData).then(
                newCompany => {
                  const companies = [newCompany, ...state.companies];
                  onSave(
                    companies,
                    `${newCompany.name} ${lookup('table_create_message')}`,
                    resolve
                  );
                },
                err => {
                  displayErrorMessage(err, setAlertMessage, setState);
                  reject();
                }
              );
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const companies = [...state.companies];
              companyService.update(newData).then(
                updatedCompany => {
                  companies[oldData.tableData.id] = updatedCompany;
                  onSave(
                    companies,
                    `${updatedCompany.name} ${lookup('table_update_message')}`,
                    resolve
                  );
                },
                err => {
                  displayErrorMessage(err, setAlertMessage, setState);
                  reject();
                }
              );
            })
        }}
      />
    </Container>
  );
};

export default withTheme(Company);
