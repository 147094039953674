import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import styled from 'styled-components';
import Collapse from '@material-ui/core/Collapse';

const DEFAULT_TYPE = 'success';

const FlexContainer = styled.div`
  ${({ noMinHeight }) =>
    !noMinHeight &&
    `
    min-height: 60px;
  `}
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
`;

const AlertContainer = styled.div`
  width: 100%;
`;

const Alert = ({
  type = DEFAULT_TYPE,
  message,
  open,
  onClose,
  noMinHeight
}) => {
  return (
    <FlexContainer noMinHeight={noMinHeight}>
      <AlertContainer>
        <Collapse in={open}>
          <MuiAlert severity={type} onClose={onClose}>
            {message}
          </MuiAlert>
        </Collapse>
      </AlertContainer>
    </FlexContainer>
  );
};

export default Alert;
