import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import styled, { css } from 'styled-components';

const DialogTitle = styled(MuiDialogTitle)(
  p => css`
    ${p.theme.typography.h4};
  `
);

const Dialog = props => {
  const { title, body, footer, isOpen, handleClose } = props;

  return (
    <>
      {isOpen && (
        <MuiDialog onClose={handleClose} open={isOpen}>
          <DialogTitle disableTypography id="dialog-title">
            {title}
          </DialogTitle>
          <DialogContent dividers>{body}</DialogContent>
          <DialogActions>{footer}</DialogActions>
        </MuiDialog>
      )}
    </>
  );
};

export default Dialog;
