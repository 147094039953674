import 'react-app-polyfill/ie11';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ErrorBoundary from 'components/common/error';
import { Auth0Provider } from 'auth0/react-auth0-spa';
import config from 'auth0/auth_config.json';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import Theme from '@komodohealth/fs-harmony.theme.pulse-theme';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <MuiThemeProvider theme={Theme}>
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <ErrorBoundary>
        <Auth0Provider
          domain={config.domain}
          client_id={config.clientId}
          redirect_uri={window.location.origin}
          audience={config.audience}
        >
          <App />
        </Auth0Provider>
      </ErrorBoundary>
    </ThemeProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
