import React, { useEffect } from 'react';
import { Typography, Paper, Box } from '@material-ui/core';
import {
  InputField,
  SliderField,
  TextInputTitleField,
  CodeSelectField,
  UploadField
} from '../../FormFields';
import { SAAS_FORM } from 'constants/AppConstants';
import { criteriaBoxOptions, CriteriaBox } from './Shared';
import { trackView } from 'utils/analytics';
import I18n from 'i18n';

const { lookup } = new I18n();
const { COHORT_FORM } = SAAS_FORM;

const CohortForm = props => {
  const {
    formField: {
      cohort_name,
      dx_codes_allow_list,
      rx_codes_allow_list,
      px_codes_allow_list,
      dx_codes_deny_list,
      rx_codes_deny_list,
      px_codes_deny_list,
      patient_age,
      hcp_npi_allow_list,
      hcp_npi_deny_list,
      include_any_hcp_specialties,
      exclude_any_hcp_specialties,
      lookback_period
    },
    formikProps: {
      setFieldValue,
      setSubmitting,
      values: { _ui_uploads }
    }
  } = props;

  useEffect(() => {
    trackView('saas_cohort');
  }, []);

  return (
    <>
      <Typography variant="h6" paragraph>
        {lookup('cohort_form_title')}
      </Typography>
      <Paper square>
        <Box p={2}>
          <TextInputTitleField
            name={cohort_name.name}
            placeholder={cohort_name.placeholder}
          ></TextInputTitleField>
          <p>{lookup('cohort_form_fields_title')}</p>
          <CriteriaBox
            title={dx_codes_allow_list.label}
            {...criteriaBoxOptions}
          >
            <CodeSelectField
              name={dx_codes_allow_list.name}
              codeType={dx_codes_allow_list.codeType}
              placeholder={dx_codes_allow_list.placeholder}
              setFieldValue={setFieldValue}
            />
          </CriteriaBox>

          <CriteriaBox
            title={px_codes_allow_list.label}
            {...criteriaBoxOptions}
          >
            <CodeSelectField
              name={px_codes_allow_list.name}
              codeType={px_codes_allow_list.codeType}
              placeholder={px_codes_allow_list.placeholder}
              setFieldValue={setFieldValue}
            />
          </CriteriaBox>

          <CriteriaBox
            title={rx_codes_allow_list.label}
            {...criteriaBoxOptions}
          >
            <CodeSelectField
              name={rx_codes_allow_list.name}
              codeType={rx_codes_allow_list.codeType}
              placeholder={rx_codes_allow_list.placeholder}
              setFieldValue={setFieldValue}
            />
          </CriteriaBox>
          <CriteriaBox title={dx_codes_deny_list.label} {...criteriaBoxOptions}>
            <CodeSelectField
              name={dx_codes_deny_list.name}
              codeType={dx_codes_deny_list.codeType}
              placeholder={dx_codes_deny_list.placeholder}
              setFieldValue={setFieldValue}
            />
          </CriteriaBox>
          <CriteriaBox title={px_codes_deny_list.label} {...criteriaBoxOptions}>
            <CodeSelectField
              name={px_codes_deny_list.name}
              codeType={px_codes_deny_list.codeType}
              placeholder={px_codes_deny_list.placeholder}
              setFieldValue={setFieldValue}
            />
          </CriteriaBox>
          <CriteriaBox title={rx_codes_deny_list.label} {...criteriaBoxOptions}>
            <CodeSelectField
              name={rx_codes_deny_list.name}
              codeType={rx_codes_deny_list.codeType}
              placeholder={rx_codes_deny_list.placeholder}
              setFieldValue={setFieldValue}
            />
          </CriteriaBox>
          <CriteriaBox title={patient_age.label} {...criteriaBoxOptions}>
            <SliderField
              name={patient_age.name}
              min={1}
              max={89}
              labelValues={{
                1: lookup('cohort_form_patient_age_left_boundary_label'),
                89: lookup('cohort_form_patient_age_right_boundary_label')
              }}
              labelHelperText={{
                left: {
                  value: 1,
                  label: lookup(
                    'cohort_form_patient_age_left_boundary_helper_text'
                  )
                },
                right: {
                  value: 89,
                  label: lookup(
                    'cohort_form_patient_age_right_boundary_helper_text'
                  )
                }
              }}
              setFieldValue={setFieldValue}
            />
          </CriteriaBox>
          <CriteriaBox
            title={hcp_npi_allow_list.label}
            {...criteriaBoxOptions}
            {...hcp_npi_allow_list.tooltip}
          >
            <UploadField
              {...hcp_npi_allow_list}
              currentUploads={_ui_uploads}
              setFieldValue={setFieldValue}
              setSubmitting={setSubmitting}
            />
          </CriteriaBox>
          <CriteriaBox
            title={hcp_npi_deny_list.label}
            {...criteriaBoxOptions}
            {...hcp_npi_deny_list.tooltip}
          >
            <UploadField
              {...hcp_npi_deny_list}
              currentUploads={_ui_uploads}
              setFieldValue={setFieldValue}
              setSubmitting={setSubmitting}
            />
          </CriteriaBox>
          <CriteriaBox
            title={include_any_hcp_specialties.label}
            {...criteriaBoxOptions}
            {...include_any_hcp_specialties.tooltip}
          >
            <UploadField
              {...include_any_hcp_specialties}
              currentUploads={_ui_uploads}
              setFieldValue={setFieldValue}
              setSubmitting={setSubmitting}
            />
          </CriteriaBox>
          <CriteriaBox
            title={exclude_any_hcp_specialties.label}
            {...criteriaBoxOptions}
            {...exclude_any_hcp_specialties.tooltip}
          >
            <UploadField
              {...exclude_any_hcp_specialties}
              currentUploads={_ui_uploads}
              setFieldValue={setFieldValue}
              setSubmitting={setSubmitting}
            />
          </CriteriaBox>
          <CriteriaBox
            title={lookback_period.label}
            {...criteriaBoxOptions}
            {...lookback_period.tooltip}
          >
            <InputField
              name={lookback_period.name}
              type="number"
              placeholder={lookback_period.placeholder}
              fullWidth={false}
            />
            <Box pl={1}>{lookup('cohort_form_lookback_period_info')}</Box>
          </CriteriaBox>
        </Box>
      </Paper>
    </>
  );
};

CohortForm.label = COHORT_FORM.label;
CohortForm.path = COHORT_FORM.path;

export { CohortForm };
