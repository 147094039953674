import React, { useContext, useEffect, useState, useMemo } from 'react';
import AppContext from 'AppContext';
import Alert from 'components/common/alert';
import { withTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from 'components/common/link';
import Table from 'components/common/table';
import Breadcrumbs from 'components/common/breadcrumbs';
import { required } from 'utils/validation';
import formatDate from 'utils/date';
import { ProjectService } from 'services';
import AppRoutes from 'constants/AppRoutes';
import I18n from 'i18n';
import { trackView, trackEvent } from 'utils/analytics';
const { lookup } = new I18n();

const Project = props => {
  const { token, displayErrorMessage } = useContext(AppContext);
  const projectService = useMemo(() => new ProjectService(token), [token]);
  const { companyId, companyName } = props.match.params;
  const [state, setState] = useState({
    projects: [],
    loading: true
  });

  const columns = useMemo(
    () => [
      {
        title: 'Project Name',
        field: 'name',
        validate: ({ name }) => required(name),
        render: ({ name, id }) => (
          <Link
            component="button"
            color="inherit"
            variant="button"
            underline="always"
            onClick={() => {
              props.history.push(
                `${AppRoutes.PROJECT_DATA_RULE}/${companyId}/${companyName}/${id}/${name}`
              );
            }}
          >
            {name}
          </Link>
        )
      },
      {
        title: 'TA Acronym',
        field: 'therapy_area',
        editable: 'onAdd',
        validate: ({ therapy_area }) => required(therapy_area)
      },
      {
        title: 'Description',
        field: 'description',
        validate: ({ description }) => required(description)
      },
      {
        title: 'ID',
        field: 'id',
        type: 'numeric',
        align: 'justify',
        editable: 'never'
      },
      {
        title: 'Created At',
        editable: 'never',
        field: 'created_at',
        render: ({ created_at }) => formatDate(created_at)
      },
      {
        title: 'Updated At',
        field: 'updated_at',
        editable: 'never',
        render: ({ updated_at }) => formatDate(updated_at)
      }
    ],
    [companyId, companyName, props.history]
  );

  const [alertMessage, setAlertMessage] = useState({
    message: '',
    open: false
  });

  const onAlertClose = () => {
    setAlertMessage({
      ...alertMessage,
      open: false
    });
  };

  /**
   * Update state on successful create/edit
   * @param {Array} projects updated projects
   * @param {string} message message to display on successful create/edit
   * @param {function} callback callback function
   */
  const onSave = (projects, message, callback) => {
    setState({
      ...state,
      projects
    });
    setAlertMessage({
      message,
      open: true
    });
    trackEvent('project_saved', message);
    callback();
  };

  const init = () => {
    trackView('project');
    projectService.get(companyId).then(
      projects => {
        setState({
          projects,
          loading: false
        });
      },
      err => {
        setState(() => {
          throw new Error(err);
        });
      }
    );
  };

  useEffect(init, [companyId, projectService]);

  return (
    <Container>
      <Alert onClose={onAlertClose} {...alertMessage} />
      <Table
        title={
          <Breadcrumbs
            data={[
              {
                name: lookup('breadcrumb_company'),
                href: AppRoutes.COMPANY
              },
              {
                name: `${lookup('breadcrumb_project')} (${companyName})`
              }
            ]}
          />
        }
        columns={columns}
        data={state.projects}
        isLoading={state.loading}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              const data = {
                ...newData,
                company_id: companyId
              };
              projectService.create(data).then(
                newProject => {
                  const projects = [newProject, ...state.projects];
                  onSave(
                    projects,
                    `${newProject.name} ${lookup('table_create_message')}`,
                    resolve
                  );
                },
                err => {
                  displayErrorMessage(err, setAlertMessage, setState);
                  reject();
                }
              );
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const projects = [...state.projects];
              projectService.update(newData).then(
                updatedProject => {
                  projects[oldData.tableData.id] = updatedProject;
                  onSave(
                    projects,
                    `${updatedProject.name} ${lookup('table_update_message')}`,
                    resolve
                  );
                },
                err => {
                  displayErrorMessage(err, setAlertMessage, setState);
                  reject();
                }
              );
            })
        }}
      />
    </Container>
  );
};

export default withTheme(Project);
