import React, { useState } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextInputTitle as KomodoTextInputTitle } from '@komodohealth/fs-harmony.form.text-input';
import HelperText from 'components/common/helperText';
import styled, { css } from 'styled-components';

const TextInputTitle = styled(KomodoTextInputTitle)(
  ({ theme }) => css`
    div {
      ${theme.typography.h5};
      align-items: center;
    }
  `
);

export const TextInputTitleField = props => {
  const { customOnChange, ...rest } = props;
  const [field, meta, { setTouched, setValue }] = useField(props);

  const [inputVal, setInputVal] = useState(field.value);

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <HelperText error={true}>{error}</HelperText>;
    }
  };

  const handleUpdate = () => {
    setTouched(true);
    setValue(inputVal);
    customOnChange && customOnChange(inputVal);
  };

  return (
    <>
      <TextInputTitle
        error={meta.touched && meta.error && true}
        typographyVariant="h5"
        hideClearIcon={true}
        value={inputVal}
        onChange={e => {
          setInputVal(e.target.value);
        }}
        onBlur={handleUpdate}
        keyValuesToWatch={['Enter']}
        onKeyPress={handleUpdate}
        {...rest}
      />
      {renderHelperText()}
    </>
  );
};
