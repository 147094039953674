/**
 * Download a file
 * @param {string} data
 * @param {string} fileName name of the file
 * @param {string} extension file extension
 */
export const download = (data, fileName, extension) => {
  let element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(data)
  );
  element.setAttribute('download', `${fileName}.${extension}`);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
