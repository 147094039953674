import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormControl as MuiFormControl,
  Select as MuiSelect,
  MenuItem
} from '@material-ui/core';
import styled, { css } from 'styled-components';
import HelperText from 'components/common/helperText';

//temp - replace with fs-component-library
const Select = styled(MuiSelect)(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    ${theme.typography.body2};
    text-transform: none;
    border-radius: 0;
  `
);

const FormControl = styled(MuiFormControl)(
  ({ maxwidth }) =>
    maxwidth &&
    `
    max-width: ${maxwidth};
  `
);

export const SelectField = props => {
  const { label, data, onChange, onClose, displayEmpty, ...rest } = props;
  let [field, meta] = useField(props);
  if (onChange) {
    field.onChange = onChange;
  }
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const renderHelperText = () => {
    if (isError) {
      return <HelperText error={true}>{error}</HelperText>;
    }
  };

  return (
    <FormControl {...rest} error={isError}>
      {label && <p>{label}</p>}
      <Select
        {...field}
        value={selectedValue ? selectedValue : ''}
        displayEmpty={displayEmpty}
        onClose={onClose}
      >
        <MenuItem value="" disabled>
          {rest.placeholder}
        </MenuItem>
        {data.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {renderHelperText()}
    </FormControl>
  );
};

SelectField.defaultProps = {
  data: []
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired
};
