import React, { Component } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import styled, { css } from 'styled-components';
import Container from '@material-ui/core/Container';
import I18n from 'i18n';
const { lookup } = new I18n();

const ErrorMessage = styled.div(
  p => css`
    ${p.theme.typography.body};
    display: flex;
    align-items: center;
    background-color: ${p.theme.colors.errorBackground};
    padding: ${p.theme.space.sm} 0;
    color: ${p.theme.colors.errorText};
    span {
      padding-left: ${p.theme.space.sm};
    }
  `
);
/**
 * https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Error reporting/bugsnag is not active, can be enabled later on
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container disableGutters>
          <ErrorMessage>
            <ErrorOutlineIcon color="error" />
            <span>{lookup('unhandled_exception_label')}</span>
          </ErrorMessage>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
