import React, { useEffect } from 'react';
import { Typography, Paper, Box } from '@material-ui/core';
import {
  InputField,
  TextInputTitleField,
  CodeSelectField
} from '../../FormFields';
import {
  ALERT_CATEGORIES,
  ALERT_CATEGORIES_ARRAY,
  CONTINUOUS_THERAPY_NOISE_REDUCTION_OPTIONS,
  SAAS_FORM
} from 'constants/AppConstants';
import { SelectField } from '../../FormFields';
import { criteriaBoxOptions, CriteriaBox, CODE_LOGIC } from './Shared';
import I18n from 'i18n';
import styled from 'styled-components';
import { trackView, trackEvent } from 'utils/analytics';
const { lookup } = new I18n();
const { ALERT_TRIGGER_FORM } = SAAS_FORM;

const CODE_TYPES = ['px', 'dx', 'rx'];
export const getSelectedCodeTypes = codeLogic => {
  // get code types from code logic value
  // i.e "filter_by_px_and_dx_or_rx" would return ['px', 'dx', 'rx']
  // i.e "filter_by_rx_only" would return ['rx']
  return codeLogic
    .split('filter_by_')[1]
    .split('_')
    .filter(logic => CODE_TYPES.includes(logic));
};

export const formatStrWithHyphens = text => {
  return text?.split?.(/\s+/).join('-') || '';
};

const WindowInputField = styled(InputField)`
  width: 280px; // Temporary added to adjust width for displaying longer placeholder
`;

const AlertTriggerForm = props => {
  const {
    formField: {
      alert_class_name,
      alert_name,
      alert_description,
      alert_category,
      alert_category_name,
      medical_event_name,
      code_logic,
      era,
      qualifying_window,
      project_ta_acronym,
      project_directory,
      noise_config,
      noise_reduction,
      dedupe_config,
      expected_compound_columns_to_be_unique
    },
    formikProps: { values, setFieldValue },
    dataRules,
    isSubmitted
  } = props;

  // -----
  // temporary code to display limited alert categories only and sort them
  const filteredDataRules = dataRules
    .filter(dataRule => ALERT_CATEGORIES_ARRAY.includes(dataRule.label))
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  // -----

  const renderCodes = codeLogic => {
    return getSelectedCodeTypes(codeLogic).map((code, id) => {
      const targetField = props.formField[`medical_event_${code}_codes`];
      return (
        <CriteriaBox
          key={`code-${targetField.name}`}
          title={targetField.label}
          {...criteriaBoxOptions}
        >
          <CodeSelectField
            name={targetField.name}
            codeType={targetField.codeType}
            placeholder={targetField.placeholder}
            setFieldValue={setFieldValue}
          />
        </CriteriaBox>
      );
    });
  };

  const handleChangeAlertCategory = e => {
    // custom onChange handler
    const { value } = e.target;
    const selectedAlertCategory = filteredDataRules.find(
      dataRule => dataRule.value === value
    );
    setFieldValue(alert_category.name, value);

    // set additional values
    setFieldValue(alert_category_name.name, selectedAlertCategory.label);
    setFieldValue(
      alert_class_name.name,
      `Pulse${selectedAlertCategory.label.replace(/\s+/, '')}`
    );
    // Sets templated project directory (ie. TA-{AlertID}-Start-Therapy)
    setFieldValue(
      project_directory.name,
      `${values[project_ta_acronym.name]}-{AlertID}-${formatStrWithHyphens(
        selectedAlertCategory.label
      )}`
    );

    // clear era value for CONTINUOUS_THERAPY
    // optionally clear noise reduction
    if (selectedAlertCategory.label === ALERT_CATEGORIES.CONTINUOUS_THERAPY) {
      setFieldValue(era.name, '');
      // reset noise reduction if previously selected noise reduction is not valid for CONTINUOUS_THERAPY
      if (
        values[noise_config.name] &&
        !CONTINUOUS_THERAPY_NOISE_REDUCTION_OPTIONS.includes(
          values[noise_config.name]
        )
      ) {
        setFieldValue(noise_config.name, '');
        setFieldValue(noise_reduction.name, '');
        setFieldValue(dedupe_config.name, '');
        setFieldValue(expected_compound_columns_to_be_unique.name, '');
      }
    }

    trackEvent('alert_category', selectedAlertCategory.label);
  };

  useEffect(() => {
    trackView('saas_alert_trigger');
  }, []);

  return (
    <>
      <Typography variant="h4" paragraph>
        {lookup('alert_trigger_form_title')}
      </Typography>
      <Paper square>
        <Box p={2}>
          <CriteriaBox title={alert_name.label} {...criteriaBoxOptions}>
            <InputField
              name={alert_name.name}
              placeholder={alert_name.placeholder}
            />
          </CriteriaBox>
          <CriteriaBox title={alert_description.label} {...criteriaBoxOptions}>
            <InputField
              name={alert_description.name}
              placeholder={alert_description.placeholder}
            />
          </CriteriaBox>
          <CriteriaBox
            title={alert_category.label}
            {...criteriaBoxOptions}
            {...alert_category.tooltip}
          >
            <SelectField
              name={alert_category.name}
              placeholder={alert_category.placeholder}
              displayEmpty
              data={filteredDataRules}
              variant="outlined"
              onChange={handleChangeAlertCategory}
              disabled={isSubmitted}
            />
          </CriteriaBox>
        </Box>
      </Paper>
      {values[alert_category.name] && (
        <Paper square>
          <Box p={2} mt={2}>
            <TextInputTitleField
              name={medical_event_name.name}
              placeholder={medical_event_name.placeholder}
            />
            <p>{lookup('alert_trigger_form_medical_event_title')}</p>
            <CriteriaBox
              title={code_logic.label}
              {...criteriaBoxOptions}
              {...code_logic.tooltip}
            >
              <SelectField
                name={code_logic.name}
                placeholder={code_logic.placeholder}
                displayEmpty
                data={CODE_LOGIC}
                variant="outlined"
                onChange={e => {
                  // custom onChange handler
                  const { value } = e.target;
                  setFieldValue(code_logic.name, value);
                  // reset unselected
                  const selected = getSelectedCodeTypes(value);
                  CODE_TYPES.forEach(logic => {
                    if (!selected.includes(logic)) {
                      setFieldValue(`medical_event_${logic}_codes`, []);
                    }
                  });
                  trackEvent('code_logic', value);
                }}
              />
            </CriteriaBox>

            {values[code_logic.name] && (
              <>
                {renderCodes(values[code_logic.name])}
                {values[alert_category_name.name] !==
                  ALERT_CATEGORIES.CONTINUOUS_THERAPY && (
                  <CriteriaBox
                    title={era.label}
                    {...criteriaBoxOptions}
                    {...era.tooltip}
                  >
                    <WindowInputField
                      name={era.name}
                      type="number"
                      placeholder={era.placeholder}
                      fullWidth={false}
                    />
                    <Box pl={1}>{lookup('alert_trigger_form_era_info')}</Box>
                  </CriteriaBox>
                )}
                <CriteriaBox
                  title={qualifying_window.label}
                  {...criteriaBoxOptions}
                  {...qualifying_window.tooltip}
                >
                  <WindowInputField
                    name={qualifying_window.name}
                    type="number"
                    placeholder={qualifying_window.placeholder}
                    fullWidth={false}
                    inputProps={{
                      inputProps: {
                        min: 1,
                        step: 1
                      }
                    }}
                  />
                  <Box pl={1}>{lookup('alert_trigger_form_window_info')}</Box>
                </CriteriaBox>
              </>
            )}
          </Box>
        </Paper>
      )}
    </>
  );
};

AlertTriggerForm.label = ALERT_TRIGGER_FORM.label;
AlertTriggerForm.path = ALERT_TRIGGER_FORM.path;

export { AlertTriggerForm };
