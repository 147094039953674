import ENDPOINT from 'utils/api';
import { handleErrors } from 'utils/error';
import { ROLES } from 'constants/AppConstants';

class CompanyService {
  /**
   * 'Constructor' for CompanyService
   * @param {string} token auth0 token
   * @param {Array} companies array of companies
   * @param {string} role logged in user's role
   */
  constructor({ token, companies, role }) {
    this.path = `${ENDPOINT}companies`;
    this.userCompanies = Array.isArray(companies)
      ? companies.map(({ id }) => id)
      : [];
    this.userRole = role;
    this.requestHeader = {
      headers: {
        'Access-Token': token,
        'Content-Type': 'application/json'
      }
    };
  }

  /**
   * Get Companies
   * @async
   * @return {Promise} companies.
   */
  async get() {
    try {
      const res = await fetch(`${this.path}`, this.requestHeader);
      if (!res.ok) {
        throw res;
      }
      return this.filterByRole(await res.json());
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Create Company
   * @async
   * @param {Object} data new company
   * @return {Promise} new company.
   */
  async create(data) {
    const requestOptions = {
      ...this.requestHeader,
      method: 'POST',
      body: JSON.stringify(data)
    };
    try {
      const res = await fetch(`${this.path}`, requestOptions);
      if (!res.ok) {
        throw res;
      }
      return await res.json();
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Update Company
   * @async
   * @param {Object} data updated company
   * @return {Promise} updated company.
   */
  async update(data) {
    const requestOptions = {
      ...this.requestHeader,
      method: 'PUT',
      body: JSON.stringify(data)
    };
    try {
      const res = await fetch(`${this.path}/${data.id}`, requestOptions);
      if (!res.ok) {
        throw res;
      }
      return await res.json();
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Filter companies by logged in user's role and assigned companies
   * @param {Array} companies all companies
   * @returns {Array} companies
   */
  filterByRole(companies) {
    let filteredCompanies;
    switch (this.userRole) {
      case ROLES.ADMIN:
        filteredCompanies = companies;
        break;
      case ROLES.CX:
        filteredCompanies = companies;
        // CX sees all companies in v0
        // filteredCompanies = companies.filter(company =>
        //   this.userCompanies.includes(company.id)
        // );
        break;
      default:
        throw new Error('Unknown Role');
    }
    return filteredCompanies;
  }
}

export { CompanyService };
