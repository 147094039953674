import React from 'react';
import { FormHelperText as MuiFormHelperText } from '@material-ui/core';
import OutlineIcon from '@material-ui/icons/ErrorOutline';
import styled, { css } from 'styled-components';

const FormHelperText = styled(MuiFormHelperText)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin: 0;
    padding-top: ${theme.space.sm};
    ${theme.typography.body};
    white-space: pre-wrap;
    text-transform: none;
    .helper-icon {
      margin-right: ${theme.space.sm};
    }
  `
);

const HelperText = props => {
  return (
    <FormHelperText {...props}>
      <OutlineIcon className="helper-icon" />
      {props.children}
    </FormHelperText>
  );
};

export default HelperText;
