import ENDPOINT from 'utils/api';
import { handleErrors } from 'utils/error';
import { groupBy } from 'lodash';
import { CODE_TYPES } from 'constants/AppConstants';
import I18n from 'i18n';
const { lookup } = new I18n();

const ALERT_OUTPUT_FILENAME_SUFFIX = lookup('output_file_extension');

/**
 * Convert array of codes to key: value
 * @param {string} key key name
 * @param {Array} val codes array
 * @return {Object|string} key: value pair or empty string
 */
const arrayToKeyValuePair = (key, val) => {
  return Array.isArray(val) && val.length > 0 ? { [key]: val } : '';
};

/**
 * Format array of enrichments before saving to json_blob
 * @param {Array} enrichments array of enrichments
 * @return {Array} Array of formatted enrichments
 */
const formatEnrichmentForYAML = enrichments => {
  let retVal = [];

  Array.isArray(enrichments) &&
    enrichments.forEach(enrichment => {
      const {
        event_name,
        window_in_days,
        codes: { dx_codes, rx_codes, px_codes }
      } = enrichment;
      let codes = [];
      dx_codes.forEach(item => {
        codes.push({ code: item, code_type: CODE_TYPES.ICD });
      });
      px_codes.forEach(item => {
        codes.push({ code: item, code_type: CODE_TYPES.PROCEDURE });
      });
      rx_codes.forEach(item => {
        codes.push({ code: item, code_type: CODE_TYPES.NDC });
      });
      enrichment.enrichment_columns.forEach(column => {
        retVal.push({
          ...column,
          event_name,
          codes,
          window_in_days
        });
      });
    });

  return retVal;
};

/**
 * Format array of enrichments before displaying in UI
 * @param {Array} enrichments array of enrichments saved in json_blob
 * @return {Array} Array of formatted enrichments
 */
const formatEnrichmentForUI = enrichments => {
  let retVal = [];

  Object.entries(groupBy(enrichments, 'event_name')).forEach(group => {
    const event_name = group[0];
    let enrichment = {
      event_name,
      enrichment_columns: []
    };
    group[1].forEach((column, index) => {
      const {
        aggregation,
        codes,
        enrichment_column,
        enrichment_level,
        fieldname,
        window_in_days
      } = column;
      if (index === 0) {
        // codes are same for each column, get codes from 1st column
        const dx_codes = codes
          .filter(code => code.code_type === CODE_TYPES.ICD)
          .map(item => item.code);
        const px_codes = codes
          .filter(code => code.code_type === CODE_TYPES.PROCEDURE)
          .map(item => item.code);
        const rx_codes = codes
          .filter(code => code.code_type === CODE_TYPES.NDC)
          .map(item => item.code);
        enrichment = {
          ...enrichment,
          codes: {
            dx_codes,
            px_codes,
            rx_codes
          },
          window_in_days
        };
      }
      enrichment.enrichment_columns.push({
        aggregation,
        enrichment_column,
        enrichment_level,
        fieldname
      });
    });
    retVal.push(enrichment);
  });

  return retVal;
};

/**
 * Transform json_blob
 * @param {Object} data json_blob
 * @return {string} stringified json_blob to store
 */
const transformJSONBlob = data => {
  const {
    dx_codes_allow_list,
    px_codes_allow_list,
    rx_codes_allow_list,
    dx_codes_deny_list,
    px_codes_deny_list,
    rx_codes_deny_list,
    medical_event_dx_codes,
    medical_event_rx_codes,
    medical_event_px_codes,
    output_filename_template,
    claims_enrichment,
    ...rest
  } = data;

  // cohort allowlist/denylist
  const dxAllow = arrayToKeyValuePair('codes', dx_codes_allow_list);
  const rxAllow = arrayToKeyValuePair('codes', rx_codes_allow_list);
  const pxAllow = arrayToKeyValuePair('codes', px_codes_allow_list);
  const dxDeny = arrayToKeyValuePair('codes', dx_codes_deny_list);
  const rxDeny = arrayToKeyValuePair('codes', rx_codes_deny_list);
  const pxDeny = arrayToKeyValuePair('codes', px_codes_deny_list);
  const lookback_window_in_months = rest.lookback_window_in_months;

  // medical event codes
  const dxMedical = arrayToKeyValuePair('dx_codes', medical_event_dx_codes);
  const rxMedical = arrayToKeyValuePair('rx_codes', medical_event_rx_codes);
  const pxMedical = arrayToKeyValuePair('px_codes', medical_event_px_codes);

  // enrichment
  const formattedEnrichment = formatEnrichmentForYAML(claims_enrichment);

  // patient age for age_filter_configs
  const patientAgeObj =
    rest._ui_patient_age?.length > 0
      ? {
          age_min_in_years: rest._ui_patient_age[0],
          age_max_in_years: rest._ui_patient_age[1]
        }
      : null;

  let retVal = {
    ...(formattedEnrichment.length > 0 && {
      claims_enrichment: formattedEnrichment
    }),
    ...((dxAllow || rxAllow || pxAllow) && {
      patient_allowlist_by_codes: {
        ...(dxAllow && {
          dx: [
            {
              ...dxAllow,
              lookback_window_in_months
            }
          ]
        }),
        ...(rxAllow && {
          rx: [
            {
              ...rxAllow,
              lookback_window_in_months
            }
          ]
        }),
        ...(pxAllow && {
          px: [
            {
              ...pxAllow,
              lookback_window_in_months
            }
          ]
        })
      }
    }),
    ...((dxDeny || rxDeny || pxDeny) && {
      patient_denylist_by_codes: {
        ...(dxDeny && {
          dx: [
            {
              ...dxDeny,
              lookback_window_in_months
            }
          ]
        }),
        ...(rxDeny && {
          rx: [
            {
              ...rxDeny,
              lookback_window_in_months
            }
          ]
        }),
        ...(pxDeny && {
          px: [
            {
              ...pxDeny,
              lookback_window_in_months
            }
          ]
        })
      }
    }),
    ...(patientAgeObj &&
      (dxMedical || rxMedical || pxMedical) && {
        age_filter_configs: {
          ...(dxMedical && {
            dx: [
              {
                codes: dxMedical.dx_codes,
                ...patientAgeObj
              }
            ]
          }),
          ...(rxMedical && {
            rx: [
              {
                codes: rxMedical.rx_codes,
                ...patientAgeObj
              }
            ]
          }),
          ...(pxMedical && {
            px: [
              {
                codes: pxMedical.px_codes,
                ...patientAgeObj
              }
            ]
          }),
          type: rest.filter_dx_rx_px
        }
      }),
    ...dxMedical,
    ...(rxMedical && {
      ...rxMedical,
      include_rx_claims: true
    }),
    ...pxMedical,
    // era_threshold_weeks is optional
    lookback_window_in_weeks: rest.era_threshold_weeks
      ? rest.era_threshold_weeks + rest.qualifying_window_in_weeks
      : rest.qualifying_window_in_weeks,
    ...(output_filename_template && {
      output_filename_template: `${output_filename_template}${ALERT_OUTPUT_FILENAME_SUFFIX}`
    }),
    ...rest
  };

  // remove undefined, null, "", {}, [] values from json_blob
  Object.keys(retVal).forEach(item => {
    if (
      !retVal[item] ||
      JSON.stringify(retVal[item]) === '{}' ||
      JSON.stringify(retVal[item]) === '[]'
    ) {
      delete retVal[item];
    } else {
      // yup's trim is used for validation only
      // trim any root level string prop to make sure there are no leading or trailing spaces
      if (typeof retVal[item] === 'string') {
        retVal[item] = retVal[item].trim();
      }
    }
  });

  return JSON.stringify(retVal);
};

/**
 * Flatten json_blob
 * @param {Object} data json_blob
 * @return {Object} flat json_blob
 */
const flattenJSONBlob = data => {
  const {
    patient_allowlist_by_codes,
    patient_denylist_by_codes,
    dx_codes,
    rx_codes,
    px_codes,
    output_filename_template,
    claims_enrichment,
    ...rest
  } = data;

  return {
    dx_codes_allow_list: patient_allowlist_by_codes?.dx?.[0]?.codes || [],
    px_codes_allow_list: patient_allowlist_by_codes?.px?.[0]?.codes || [],
    rx_codes_allow_list: patient_allowlist_by_codes?.rx?.[0]?.codes || [],
    dx_codes_deny_list: patient_denylist_by_codes?.dx?.[0]?.codes || [],
    px_codes_deny_list: patient_denylist_by_codes?.px?.[0]?.codes || [],
    rx_codes_deny_list: patient_denylist_by_codes?.rx?.[0]?.codes || [],
    medical_event_dx_codes: dx_codes || [],
    medical_event_rx_codes: rx_codes || [],
    medical_event_px_codes: px_codes || [],
    claims_enrichment: formatEnrichmentForUI(claims_enrichment),
    output_filename_template:
      output_filename_template?.split(ALERT_OUTPUT_FILENAME_SUFFIX)[0] || '',
    ...rest
  };
};

class FormService {
  /**
   * 'Constructor' for FormService
   * @param {string} token auth0 token
   */
  constructor(token) {
    this.path = `${ENDPOINT}saas_forms`;
    this.requestHeader = {
      headers: {
        'Access-Token': token,
        'Content-Type': 'application/json'
      }
    };
  }

  /**
   * Get all SaaS forms or get by form id
   * @param {string} id form id
   * @async
   * @return {Promise} in-progress forms.
   */
  async get(id) {
    try {
      const res = await fetch(
        `${this.path}${id ? `/${id}` : ''}`,
        this.requestHeader
      );
      if (!res.ok) {
        throw res;
      }
      let data = await res.json();
      // no need to flatten for list view
      if (id) {
        data.raw_json_blob = data.json_blob;
        data.json_blob = flattenJSONBlob(data.json_blob);
      }
      return data;
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Create New SaaS Form
   * @async
   * @param {Object} data new form
   * @return {Promise} new form.
   */
  async create(data) {
    data.json_blob = transformJSONBlob(data.json_blob);
    const requestOptions = {
      ...this.requestHeader,
      method: 'POST',
      body: JSON.stringify(data)
    };
    try {
      const res = await fetch(`${this.path}`, requestOptions);
      if (!res.ok) {
        throw res;
      }
      let data = await res.json();
      data.raw_json_blob = data.json_blob;
      data.json_blob = flattenJSONBlob(data.json_blob);
      return data;
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Update SaaS Form
   * @async
   * @param {Object} data updated form
   * @return {Promise} updated form.
   */
  async update(data, id) {
    data.json_blob = transformJSONBlob(data.json_blob);
    const requestOptions = {
      ...this.requestHeader,
      method: 'PUT',
      body: JSON.stringify(data)
    };
    try {
      const res = await fetch(`${this.path}/${id}`, requestOptions);
      if (!res.ok) {
        throw res;
      }
      let data = await res.json();
      data.raw_json_blob = data.json_blob;
      data.json_blob = flattenJSONBlob(data.json_blob);
      return data;
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Comparator function, compares json_blob in state with new values in form
   * @param {Object} oldValues
   * @param {Object} newValues
   * @return {boolean}
   */
  hasDataChanged(oldValues, newValues) {
    const orderedOldValues = JSON.stringify(
      Object.fromEntries(Object.entries(oldValues).sort())
    );
    const orderedNewValues = JSON.stringify(
      Object.fromEntries(Object.entries(newValues).sort())
    );

    return !(orderedNewValues === orderedOldValues);
  }
}

export { FormService };
export const testables = {
  transformJSONBlob,
  flattenJSONBlob,
  arrayToKeyValuePair,
  formatEnrichmentForUI,
  formatEnrichmentForYAML
};
