import React from 'react';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from 'components/common/link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router-dom';

const Wrapper = styled(MuiBreadcrumbs)(
  p => css`
    padding: ${p.theme.space.md} 0;
    ${p.theme.typography.h5};
  `
);

const Breadcrumbs = ({ data, history }) => {
  const handleClick = (e, href) => {
    e.preventDefault();
    history.push(href);
  };

  if (data) {
    return (
      <Wrapper
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {data.map(({ href, name }, key) => {
          if (href) {
            return (
              <Link
                key={key}
                color="inherit"
                href="/"
                underline="always"
                onClick={e => handleClick(e, href)}
              >
                {name}
              </Link>
            );
          } else {
            return <span key={key}>{name}</span>;
          }
        })}
      </Wrapper>
    );
  }

  return <></>;
};

export default withRouter(Breadcrumbs);
