import { BRD_TEMPLATE } from 'constants/AppConstants';

/**
 * Util function to group by multiple fields
 * @param {Array} data all rows in sheet
 * @param {Array} groups array of column names
 * @param {string} codeColumn name of Code Column
 * @returns {Object} rows grouped by multiple fields
 */
const groupByMultipleFields = (data, groups, codeColumn) => {
  let grouped = {};
  data.forEach(a => {
    groups
      .reduce((o, g, i) => {
        o[a[g]] = o[a[g]] || (i + 1 === groups.length ? [] : {});
        return o[a[g]];
      }, grouped)
      .push(a[codeColumn]);
  });
  return grouped;
};

/**
 * Util function to apply transformations to grouped codes
 * @param {Object} data grouped codes
 * @param {boolean} is2d flag for 2d arrays
 * @returns {Object} filtered grouped codes
 */
const applyTransformations = (data, is2d) => {
  const retVal = data;

  if (retVal) {
    Object.keys(retVal).forEach(item => {
      // remove decimals and trim
      retVal[item] = retVal[item].map(code => {
        return code.replace(/\./g, '').trim();
      });

      // remove dupes
      retVal[item] = Array.from(new Set(retVal[item]));

      // sort
      retVal[item].sort();

      // convert to 2d array
      if (is2d) {
        const newArr = retVal[item].map(arrItem => {
          return [arrItem];
        });
        retVal[item] = newArr;
      }

      // delete empty keys from result
      // i.e "": ['123','444']
      if (!item) {
        delete retVal[item];
      }
    });
  }

  return retVal;
};

/**
 * Util function to filter rows by alert ID
 * @param {Array} rows all rows in sheet
 * @param {string} alertId selected Alert ID
 * @returns {Array} filtered rows
 */
const filterByAlertId = (rows, alertId) => {
  return rows.filter(row => row[BRD_TEMPLATE.ALERT_ID] === alertId);
};

/**
 * Util function to get code type prefix - px, dx, rx
 * @param {Array} rows all rows in sheet
 * @param {string} columnName code type column name
 * @returns {Array} filtered rows
 */
const getCodeTypePrefix = (rows, columnName) => {
  rows.forEach((item, index) => {
    rows[index][columnName] = item[columnName].slice(0, 2).toLowerCase();
  });
  return rows;
};

/**
 * Util function to filter rows by column name and value to exclude or include
 * @param {Array} rows all rows in sheet
 * @param {string} key column name
 * @param {string} value column value
 * @param {boolean} shouldInclude flag for equality operator, true for '==='
 * @returns {Array} filtered rows
 */
const filterByKeyAndValue = (rows, key, value, shouldInclude) => {
  return rows.filter(row => {
    return shouldInclude ? row[key] === value : row[key] !== value;
  });
};

/**
 * Util function to get grouped values for Code Used For Column
 * @param {Array} rows all rows in sheet
 * @param {string} codeColumn name of Code Column
 * @param {string} alertId selected Alert ID
 * @param {string} codeUsedForColumn name of Code Used for Column
 * @param {string} codeUsedForName selected value for Code Used for Column, i.e Allowlist, Denylist
 * @returns {Object} Object containing grouped codes
 */
export const getCodeUsedFor = ({
  rows,
  codeColumn,
  alertId,
  codeUsedForColumn,
  codeUsedForName
}) => {
  const groups = [codeUsedForColumn, BRD_TEMPLATE.CODE_TYPE];

  rows = getCodeTypePrefix(
    filterByAlertId(rows, alertId),
    BRD_TEMPLATE.CODE_TYPE
  );

  const grouped = groupByMultipleFields(rows, groups, codeColumn);

  return applyTransformations(grouped[codeUsedForName]);
};

/**
 * Util function to get grouped values for Enrichment
 * @param {Array} rows all rows in sheet
 * @param {string} codeColumn name of Code Column
 * @param {string} alertId selected Alert ID
 * @param {string} selectedColumn name of Enrichment Column
 * @param {string} codeUsedForColumn name of Code Used for Column
 * @returns {Object} Object containing grouped codes
 */
export const getEnrichment = ({
  rows,
  codeColumn,
  alertId,
  selectedColumn,
  codeUsedForColumn
}) => {
  const groups = [selectedColumn];

  rows = getCodeTypePrefix(
    filterByAlertId(rows, alertId),
    BRD_TEMPLATE.CODE_TYPE
  );

  rows = filterByKeyAndValue(rows, codeUsedForColumn, BRD_TEMPLATE.FLAG, true);

  const grouped = groupByMultipleFields(rows, groups, codeColumn);

  return applyTransformations(grouped);
};

/**
 * Util function to get grouped values for Subtype, Product and Regimen map
 * @param {Array} rows all rows in sheet
 * @param {string} codeColumn name of Code Column
 * @param {string} alertId selected Alert ID
 * @param {string} selectedColumn name of target column
 * @param {string} codeUsedForColumn name of Code Used for Column
 * @returns {Object} Object containing grouped codes
 */
export const getCustom2dMappingByColumnName = ({
  rows,
  codeColumn,
  alertId,
  selectedColumn,
  codeUsedForColumn
}) => {
  const groups = [BRD_TEMPLATE.CODE_TYPE, selectedColumn];

  rows = getCodeTypePrefix(
    filterByAlertId(rows, alertId),
    BRD_TEMPLATE.CODE_TYPE
  );

  rows = filterByKeyAndValue(rows, codeUsedForColumn, BRD_TEMPLATE.FLAG);

  let grouped = groupByMultipleFields(rows, groups, codeColumn);

  Object.keys(grouped).forEach(code => {
    grouped[code] = applyTransformations(grouped[code], true);
    // remove empty keys from parent object
    if (!code || Object.keys(grouped[code]).length === 0) {
      delete grouped[code];
    }
  });

  return grouped;
};

/**
 * Util function to get subset
 * @param {Array} rows all rows in sheet
 * @param {string} codeColumn name of Code Column
 * @param {string} codeUsedForColumn name of Code Used for Column
 * @returns {Object} Object containing grouped codes
 */
export const getSubset = ({ rows, codeColumn, codeUsedForColumn }) => {
  const groups = [BRD_TEMPLATE.CODE_TYPE];

  rows = getCodeTypePrefix(rows, BRD_TEMPLATE.CODE_TYPE);

  rows = filterByKeyAndValue(rows, codeUsedForColumn, BRD_TEMPLATE.FLAG);

  const grouped = groupByMultipleFields(rows, groups, codeColumn);

  return applyTransformations(grouped);
};

/**
 * Util function to get unique Alert IDs frow all rows
 * @param {Array} rows all rows in sheet
 * @returns {Array} Array containing unique Alert IDs
 */
export const getUniqueAlertIds = rows => {
  return [...new Set(rows.map(item => item[BRD_TEMPLATE.ALERT_ID]))].filter(
    e => e
  );
};

/**
 * Util function to get code counts
 * @param {Object} codes all codes grouped by code type
 * @param {boolean} is2d flag for 2d arrays
 * @returns {string} code counts
 */
export const getCodeCounts = (codes, is2d) => {
  let counts;
  if (is2d) {
    counts = Object.keys(codes).map(code => {
      let total = 0;
      Object.keys(codes[code]).forEach(item => {
        total += codes[code][item].length;
      });
      return ` ${code}: ${total}`;
    });
  } else {
    counts = Object.keys(codes).map(code => {
      return ` ${code}: ${codes[code].length}`;
    });
  }
  return `( ${counts} )`;
};
