import { alertFormModel } from '.';
const {
  formField: {
    alert_class_name,
    client,
    client_name,
    client_acronym,
    project,
    project_ta_acronym,
    project_directory,
    cohort_name,
    dx_codes_allow_list,
    rx_codes_allow_list,
    px_codes_allow_list,
    dx_codes_deny_list,
    rx_codes_deny_list,
    px_codes_deny_list,
    patient_age,
    hcp_npi_allow_list,
    hcp_npi_deny_list,
    include_any_hcp_specialties,
    exclude_any_hcp_specialties,
    dts_clinical_trial_site_list,
    specialties_to_include_path,
    specialties_to_exclude_path,
    npis_to_include_path,
    npis_to_exclude_path,
    allowlist_specialties_flag,
    denylist_specialties_flag,
    allowlist_npis_flag,
    denylist_npis_flag,
    uploads,
    lookback_period,
    alert_name,
    alert_description,
    alert_category,
    alert_category_name,
    medical_event_name,
    code_logic,
    medical_event_dx_codes,
    medical_event_rx_codes,
    medical_event_px_codes,
    era,
    qualifying_window,
    alert_file_name_template,
    delivery_day,
    noise_config,
    noise_reduction,
    dedupe_config,
    expected_compound_columns_to_be_unique,
    enrichment,
    output_columns,
    dts_flag,
    distance_to_site
  }
} = alertFormModel;

export const formInitialValues = jsonBlob => {
  return {
    [alert_class_name.name]: jsonBlob[alert_class_name.name] || '',
    [client.name]: jsonBlob[client.name] || '',
    [client_name.name]: jsonBlob[client_name.name] || '',
    [client_acronym.name]: jsonBlob[client_acronym.name] || '',
    [project.name]: jsonBlob[project.name] || '',
    [project_ta_acronym.name]: jsonBlob[project_ta_acronym.name] || '',
    [project_directory.name]: jsonBlob[project_directory.name] || '',
    [cohort_name.name]: jsonBlob[cohort_name.name] || '',
    [dx_codes_allow_list.name]: jsonBlob[dx_codes_allow_list.name] || [],
    [px_codes_allow_list.name]: jsonBlob[px_codes_allow_list.name] || [],
    [rx_codes_allow_list.name]: jsonBlob[rx_codes_allow_list.name] || [],
    [dx_codes_deny_list.name]: jsonBlob[dx_codes_deny_list.name] || [],
    [px_codes_deny_list.name]: jsonBlob[px_codes_deny_list.name] || [],
    [rx_codes_deny_list.name]: jsonBlob[rx_codes_deny_list.name] || [],
    [patient_age.name]: jsonBlob[patient_age.name] || [1, 89],
    [hcp_npi_allow_list.name]: jsonBlob[hcp_npi_allow_list.name] || '',
    [hcp_npi_deny_list.name]: jsonBlob[hcp_npi_deny_list.name] || '',
    [include_any_hcp_specialties.name]:
      jsonBlob[include_any_hcp_specialties.name] || '',
    [exclude_any_hcp_specialties.name]:
      jsonBlob[exclude_any_hcp_specialties.name] || '',
    [dts_clinical_trial_site_list.name]:
      jsonBlob[dts_clinical_trial_site_list.name] || '',
    [distance_to_site.name]: jsonBlob[distance_to_site.name] || '',
    [dts_flag.name]: jsonBlob[dts_flag.name] || '',
    [specialties_to_include_path.name]:
      jsonBlob[specialties_to_include_path.name] || '',
    [specialties_to_exclude_path.name]:
      jsonBlob[specialties_to_exclude_path.name] || '',
    [npis_to_include_path.name]: jsonBlob[npis_to_include_path.name] || '',
    [npis_to_exclude_path.name]: jsonBlob[npis_to_exclude_path.name] || '',
    [allowlist_specialties_flag.name]:
      jsonBlob[allowlist_specialties_flag.name] || false,
    [denylist_specialties_flag.name]:
      jsonBlob[denylist_specialties_flag.name] || false,
    [allowlist_npis_flag.name]: jsonBlob[allowlist_npis_flag.name] || false,
    [denylist_npis_flag.name]: jsonBlob[denylist_npis_flag.name] || false,
    [uploads.name]: jsonBlob[uploads.name] || {},
    [lookback_period.name]: jsonBlob[lookback_period.name] || '',
    [alert_name.name]: jsonBlob[alert_name.name] || '',
    [alert_description.name]: jsonBlob[alert_description.name] || '',
    [alert_category.name]: jsonBlob[alert_category.name] || '',
    [alert_category_name.name]: jsonBlob[alert_category_name.name] || '',
    [medical_event_name.name]: jsonBlob[medical_event_name.name] || '',
    [code_logic.name]: jsonBlob[code_logic.name] || '',
    [medical_event_dx_codes.name]: jsonBlob[medical_event_dx_codes.name] || [],
    [medical_event_rx_codes.name]: jsonBlob[medical_event_rx_codes.name] || [],
    [medical_event_px_codes.name]: jsonBlob[medical_event_px_codes.name] || [],
    [era.name]: jsonBlob[era.name] || '',
    [qualifying_window.name]: jsonBlob[qualifying_window.name] || '',
    [alert_file_name_template.name]:
      jsonBlob[alert_file_name_template.name] ||
      (jsonBlob[client_name.name] &&
      jsonBlob[project_ta_acronym.name] &&
      jsonBlob[alert_name.name]
        ? `${jsonBlob[client_name.name]}_${jsonBlob[project_ta_acronym.name]}_${
            jsonBlob[alert_name.name]
          }`.replace(/[^A-Z0-9-.]+/gi, '_')
        : ''),
    [delivery_day.name]: jsonBlob[delivery_day.name] || '',
    [noise_config.name]: jsonBlob[noise_config.name] || '',
    [noise_reduction.name]: jsonBlob[noise_reduction.name] || '',
    [dedupe_config.name]: jsonBlob[dedupe_config.name] || '',
    [expected_compound_columns_to_be_unique.name]:
      jsonBlob[expected_compound_columns_to_be_unique.name] || '',
    [enrichment.name]: jsonBlob[enrichment.name] || [],
    [output_columns.name]: jsonBlob[output_columns.name] || []
  };
};
