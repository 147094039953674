import YAML from './yaml.svg';
import React from 'react';

// temp inline styling for dts tooltip, TODO: check if we can use styled components here w/o impacting performance
const dtsTooltipCSS = `
    .dts-tooltip thead tr {
      background-color: #E4E6EC;
      text-align: left;
    }
    .dts-tooltip th, td {
      padding: 8px;
    }
`;

export const ENLocalizedStrings = {
  //header
  header_configuration: 'Configuration',
  header_monitoring: 'Monitoring',
  header_yaml: <img alt="yaml" width="35px" src={YAML} />,
  header_alert_list: 'My Alerts',
  header_logout: 'Sign Out',
  header_alert_views: 'Back to My Alerts',

  //breadcrumbs
  breadcrumb_company: 'Companies',
  breadcrumb_project: 'Projects',
  breadcrumb_alert: 'Alerts',
  breadcrumb_pdr: 'Project Data Rules',
  breadcrumb_delivery_setting: 'Delivery Settings',
  breadcrumb_saas_list: 'Alerts',

  //table
  table_actions_edit: 'Edit',
  table_create_message: 'successfully created',
  table_update_message: 'successfully updated',

  //footer
  copyright: '©',
  footer_text: 'Komodo Health Inc.',

  //access denied
  access_denied_dialog_title: 'Access Denied',
  access_denied_dialog_body:
    "doesn't have access to Pulse Implementation Tooling.",

  //error handling
  unhandled_exception_label: 'Something went wrong.',

  //empty state
  empty_yaml: 'No YAML Configured',

  //yaml
  yaml_error_fetching_label: 'Error fetching sheet.',
  yaml_info_label: (
    <>
      Please share your spreadsheet with{' '}
      <strong>
        pulse-google-sheets@pulse-sheets-api.iam.gserviceaccount.com
      </strong>{' '}
      and enter the URL of <strong>BRD Codes Tab.</strong>
    </>
  ),
  yaml_url_label: 'BRD Codes Tab URL',
  yaml_button_fetch: 'Fetch',
  yaml_button_fetching: 'Fetching',
  yaml_button_generate: 'Generate YAML',
  yaml_button_download: 'Download',
  yaml_radio_format_yaml: 'YAML',
  yaml_radio_format_json: 'JSON',
  yaml_url_placeholder:
    'https://docs.google.com/spreadsheets/d/1WAT8TkW_VaE__e--mgLiKAupVv6p_N7pqfvoNM03KNo/edit#gid=248995125',
  yaml_filter_info: (
    <>
      Following transformations will be applied
      <ul>
        <li>
          <strong>Remove decimals</strong>
        </li>
        <li>
          <strong>Remove dupes</strong>
        </li>
        <li>
          <strong>Sort</strong>
        </li>
      </ul>
    </>
  ),
  yaml_verify_label: <strong>Please verify the following</strong>,
  yaml_alert_id_label: <strong>Alert # (doesn't apply to Subset)</strong>,
  yaml_code_used_for_label: <strong>Code Used For &nbsp;</strong>,
  yaml_accordion_header_no_data_label: 'No data',

  //saas
  saas_list_new_button: 'CREATE ALERT',
  saas_form_next_button: 'Next',
  saas_form_submit_button: 'Submit',
  cohort_form_title:
    'Describe the pool of patients you would like to build alerts for.',
  cohort_form_fields_title: (
    <>
      <strong>All</strong> of the following criteria match.{' '}
      <strong>None</strong> of these are required fields.
    </>
  ),
  cohort_form_lookback_period_info: 'months before (as of today)',
  cohort_form_patient_age_left_boundary_label: '1*',
  cohort_form_patient_age_left_boundary_helper_text:
    '* also includes anyone under 1',
  cohort_form_patient_age_right_boundary_label: '89+',
  cohort_form_patient_age_right_boundary_helper_text: 'includes anyone over 89',
  alert_trigger_form_title: 'Define Alert Trigger',
  alert_trigger_form_era_info: 'weeks before',
  alert_trigger_form_window_info: 'weeks before',
  alert_trigger_form_medical_event_title: (
    <>
      <strong>All</strong> of the following criteria match.
    </>
  ),
  enrichment_form_window_info: 'Days',
  output_form_title: 'Define Output',
  output_form_columns_title: 'Review Columns in Alert File',
  output_form_selected_columns_title: 'Selected Output Columns',
  output_form_selected_columns_reset_label: 'Reset to',
  output_form_edit_column_label: 'Edit',
  output_form_edit_value_label: 'Column Name',
  output_form_edit_default_value_label: 'Default Column Name:',
  output_form_edit_placeholder: 'Enter New Column Name',
  output_form_edit_ok_label: 'OK',
  output_form_edit_cancel_label: 'Cancel',
  output_form_enrichment_subtitle: 'Medical Event Enrichment',
  output_form_dts_subtitle: 'Distance to Site Enrichment',
  output_file_extension: '_{date}.csv',
  review_form_extra_yaml_hide_label: 'Hide Extra Config',
  review_form_extra_yaml_show_label: 'Show Extra Config',
  bulk_codes_add_button: '+ Bulk Add',
  bulk_codes_modal_invalid_codes_label: 'Unmatched Codes:',
  bulk_codes_modal_info_label:
    'Please separate each code with a comma or white space (spaces, line breaks, etc.).',
  bulk_codes_modal_disable_validation_label:
    'Turn off code validation to add unmatched codes',
  bulk_codes_modal_add_button: 'Add Codes',
  bulk_codes_modal_cancel_button: 'Cancel',
  dx_codes_placeholder: 'Enter any ICD Codes',
  px_codes_placeholder: 'Enter any CPT Codes',
  rx_codes_placeholder: 'Enter any NDC Codes',

  //enrichment
  enrichment_form_title: 'Add Enrichments',
  enrichment_form_add_new_label: 'Add Enrichment Event',
  enrichment_form_add_new_title: 'Add Patient Codes and Lookback Window',
  enrichment_form_add_new_subtitle:
    'Get deeper insight into the medical history of the alerted patients.',
  enrichment_form_add_new_dts_label: 'Add Clinical Trial Sites',
  enrichment_form_add_new_dts_title: 'See Distance to Clinical Trial Sites',
  enrichment_form_add_new_dts_subtitle:
    'Gauge how likely alerted patients are to travel to your clinical trial sites.',
  enrichment_form_dts_title: 'Distance to Clinical Trial Site',
  enrichment_form_dts_subtitle:
    'See how far a patient’s provider is from your nearest clinical trial site, as well as the name of that site.',
  enrichment_form_upload_label: 'Import your site list using our CSV formats.',
  enrichment_form_dts_unit_label: 'Display distance to site in:',
  enrichment_column_title: 'Select Aggregation(s)',
  enrichment_form_dts_tooltip: (
    <>
      Upload your site locations in a <strong>CSV file</strong> using either{' '}
      <strong>addresses</strong> or <strong>latitude/longitude</strong>{' '}
      coordinates.
      <br />
      <br />
      Your file must
      <ul>
        <li>
          Be named <strong>distance_to_site.csv</strong>
        </li>
        <li>Use one of the following column formats</li>
        <li>Not exceed 5 mb</li>
      </ul>
      <h4>ADDRESS FORMAT</h4>
      <table className="dts-tooltip">
        <style>{dtsTooltipCSS}</style>
        <thead>
          <tr>
            <th>Site Name</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Norton Healthcare</td>
            <td>123 Main Street, Ste. 4</td>
            <td>Anytown</td>
            <td>NY</td>
            <td>12345</td>
          </tr>
        </tbody>
      </table>
      <h4>LATITUDE /LONGITUDE FORMAT</h4>
      <table className="dts-tooltip">
        <thead>
          <tr>
            <th>Site Name</th>
            <th>Latitude</th>
            <th>Longitude</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Norton Healthcare</td>
            <td>41.40338</td>
            <td>2.17403</td>
          </tr>
        </tbody>
      </table>
    </>
  ),

  //saas - review screen
  //TODO: use these in alertFormModel for consistency
  review_cohort_title: 'Cohort Definition',
  review_tab_yaml_label: 'YAML',
  review_tab_default_label: 'Review',
  review_cohort_name_label: 'Cohort Name',
  review_cohort_dx_label: 'DX Codes Allow List',
  review_cohort_rx_label: 'RX Codes Allow List',
  review_cohort_px_label: 'PX Codes Allow List',
  review_cohort_dx_deny_label: 'DX Codes Deny List',
  review_cohort_rx_deny_label: 'RX Codes Deny List',
  review_cohort_px_deny_label: 'PX Codes Deny List',
  review_cohort_patient_age_unit: 'years',
  review_trigger_title: 'Alert Trigger Definition',
  review_trigger_medical_event_label: 'Medical Event',
  review_enrichment_title: 'Enrichment Definition',
  review_enrichment_name_label: 'Name',
  review_enrichment_type_label: 'Enrichment Type',
  review_enrichment_dts_file_label: 'Site List File',
  review_enrichment_dts_unit_label: 'Distance Unit',
  review_enrichment_deselected_column_label: '(not included)',
  review_output_title: 'Output Definition',
  review_output_columns_title: 'Output File Column Order',
  review_output_columns_label: 'Output Columns',
  review_output_delivery_day_label: 'Deliver On',
  review_noise_reduction_label: 'Noise Reduction',
  review_noise_reduction_unit_label: 'Days',
  review_edit_link: 'Edit',

  // input labels
  cohort_npi_allow_label: 'Include Only These NPIs',
  cohort_npi_deny_label: 'Exclude These NPIs',
  cohort_specialties_allow_label: 'Include Only These HCP Specialties',
  cohort_specialties_deny_label: 'Exclude These HCP Specialties',
  cohort_lookback_label: 'Lookback Window',
  cohort_patient_age_label: 'Patient Age',
  trigger_alert_name_label: 'Alert Name',
  trigger_alert_description_label: 'Alert Description',
  trigger_alert_category_label: 'Alert Category',
  trigger_code_logic_label: 'Code Logic',
  trigger_dx_label: 'DX Codes',
  trigger_rx_label: 'RX Codes',
  trigger_px_label: 'PX Codes',
  trigger_era_label: 'Event Gap Threshold',
  trigger_qualifying_window: 'Qualifying Window',
  enrichment_event_name_label: 'Medical Event',
  enrichment_dx_label: 'DX Codes',
  enrichment_rx_label: 'RX Codes',
  enrichment_px_label: 'PX Codes',
  enrichment_lookback_window_label: 'Lookback Window',
  output_file_template_label: 'Alert File Name',
  noise_reduction_select_days_label: 'Select number of days',
  noise_reduction_select_days_unit_label: 'days',
  csv_upload_placeholder: 'Select a .csv file to upload',

  // tooltips
  tooltip_npi_include_exclude: (
    <>
      Upload a csv file with one value per line.
      <br />
      <br />
      Please include a column header.
      <br />
      <br />
      For example:
      <br />
      <br />
      npi
      <br />
      1234567891
      <br />
      4321432143
      <br />
      5432543254
    </>
  ),
  tooltip_specialties_include_exclude: (
    <>
      Upload a csv file with one value per line.
      <br />
      <br />
      Please include a column header.
      <br />
      <br />
      For example:
      <br />
      <br />
      specialty
      <br />
      Breast Cancer
      <br />
      Lung Cancer
    </>
  ),
  tooltip_cohort_lookback_window:
    'Define how far back in time from the Alert Run Date should Pulse look for patient claims that meet the all cohort criteria',
  tooltip_alert_category:
    'Selecting an Alert Category changes the criteria you need for alert definition',
  tooltip_code_logic:
    'Selecting a Code Logic changes the criteria you need for alert definition',
  tooltip_event_gap_threshold:
    'Define what amount of time should have passed before the Qualifying Window where the Medical Event should not have occurred',
  tooltip_qualifying_window:
    'Define a window of time before the Alert Run Date where Pulse should look for the Medical Event to have occurred',
  tooltip_enrichment_lookback_window:
    'How far back in time from the Alert Run Date should Pulse look for instances of this Medical Event',
  tooltip_alert_file_name: (
    <>
      Enter a file name for your alert output. If left empty, a placeholder will
      be used.
      <br />
      <br />
      Pulse will automatically append the alert run date to every output file.
      <br />
      <br />
      The date will be formatted YYYYMMDD.
    </>
  ),

  //custom validations
  output_column_min_chars: 'Column Name must be at least 3 characters',
  output_column_special_chars: 'Special characters and spaces are not allowed',
  output_column_uniqueness: 'Column name already exists',
  output_column_reserved_name:
    'Column name is not allowed. Please use a different column name.'
};
