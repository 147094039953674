import React, { useState, useContext, useEffect, useMemo } from 'react';
import AppContext from 'AppContext';
import { Container } from '@material-ui/core';
import { Button } from '@komodohealth/fs-harmony.ui.button';
import { Stepper } from '@komodohealth/fs-harmony.ui.stepper';
import { Formik, Form } from 'formik';
import {
  AlertTriggerForm,
  AlertOutputForm,
  CohortForm,
  CustomerForm,
  ReviewForm,
  EnrichmentForm
} from './Forms';
import {
  alertFormModel,
  formInitialValues,
  validationSchema
} from './FormModel';
import Alert from 'components/common/alert';
import Loader from 'components/common/loader';
import styled from 'styled-components';
import {
  CompanyService,
  ProjectService,
  FormService,
  DataRuleService,
  FormDefaultsService
} from 'services';
import ScrollToError from 'utils/scrollToError';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  Prompt,
  useLocation
} from 'react-router-dom';
import AppRoutes from 'constants/AppRoutes';
import I18n from 'i18n';
const { lookup } = new I18n();

const FormWrapper = styled(Container)`
  ${({ theme }) =>
    `
    padding-top: ${theme.spacing(10)}px;
  `}
`;

const Wrapper = styled(Container)`
  ${({ theme }) =>
    `
    .MuiBackdrop-root{
      z-index: ${theme.zIndex.drawer + 1};
      background-color: ${theme.colors.loaderBackdrop};
    }
  `}
`;

// Order of steps should match the order in yup validationSchema
const formSteps = [
  CustomerForm,
  CohortForm,
  AlertTriggerForm,
  EnrichmentForm,
  AlertOutputForm,
  ReviewForm
];

const { formId, formField } = alertFormModel;

export default withRouter(function AlertPage(props) {
  const { alertId } = props;
  const appContext = useContext(AppContext);
  const { token, role, displayErrorMessage } = appContext;
  const location = useLocation();
  const companyService = useMemo(() => new CompanyService({ token, role }), [
    token,
    role
  ]);
  const projectService = useMemo(() => new ProjectService(token), [token]);
  const dataRuleService = useMemo(() => new DataRuleService(token), [token]);
  const formService = useMemo(() => new FormService(token), [token]);
  const formDefaultsService = useMemo(() => new FormDefaultsService(token), [
    token
  ]);

  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === formSteps.length - 1;
  const isCreate = alertId === 'create';

  const [state, setState] = useState({
    companies: [],
    projects: [],
    dataRules: [],
    defaultFormValues: {},
    is_submitted: false,
    json_blob: {},
    extra_config_blob: {},
    loading: true
  });
  // for YAML Preview/Download page
  const [rawJSONBlob, setRawJSONBlob] = useState({});

  const [errorMessage, setErrorMessage] = useState({
    message: '',
    open: false
  });

  const dismissErrorMessage = () => {
    setErrorMessage({
      ...errorMessage,
      open: false
    });
  };

  const handleSubmit = (values, actions) => {
    // SaaS data to save
    const data = {
      user_id: appContext.userId,
      company_id: values.company_id,
      project_id: values._ui_project_id,
      is_submitted: state.is_submitted || isLastStep,
      json_blob: values
    };

    // Create new
    if (activeStep === 0 && isCreate) {
      formService
        .create(data)
        .then(res => {
          setState({
            ...state,
            json_blob: res.json_blob,
            extra_config_blob: res.extra_config_blob
          });
          setRawJSONBlob(res.raw_json_blob);
          handleNext(res.id, actions);
        })
        .catch(err => {
          handleError(err, actions);
        });
    } else {
      // Update existing form
      // don't make a call if data hasn't changed
      if (
        formService.hasDataChanged(
          formInitialValues(state.json_blob),
          values
        ) ||
        isLastStep
      ) {
        // update form
        formService
          .update(data, alertId)
          .then(res => {
            setState({
              ...state,
              json_blob: res.json_blob,
              extra_config_blob: res.extra_config_blob
            });
            setRawJSONBlob(res.raw_json_blob);
            handleNext(alertId, actions, isLastStep);
          })
          .catch(err => {
            handleError(err, actions);
          });
      } else {
        handleNext(alertId, actions);
      }
    }
  };

  const handleError = (err, formikActions) => {
    displayErrorMessage(err, setErrorMessage, setState);
    formikActions.setSubmitting(false);
    window.scrollTo(0, 0);
  };

  const handleNext = (id, formikActions, isLastStep) => {
    dismissErrorMessage();
    if (isLastStep) {
      props.history.push(AppRoutes.ALERT_LIST);
    } else {
      props.history.push(
        `${AppRoutes.ALERT_LIST}/${id}/${formSteps[activeStep + 1].path}`
      );
      formikActions.setTouched({});
      formikActions.setSubmitting(false);
      window.scrollTo(0, 0);
    }
  };

  const handleBack = formikProps => {
    // reset form when user hits stepper's back button
    formikProps.resetForm();
    dismissErrorMessage();
    props.history.push(
      `${AppRoutes.ALERT_LIST}/${alertId}/${formSteps[activeStep - 1].path}`
    );
    window.scrollTo(0, 0);
  };

  const init = () => {
    Promise.all([
      companyService.get(),
      projectService.get(),
      dataRuleService.get(),
      formDefaultsService.get(),
      !isCreate && formService.get(alertId)
    ])
      .then(res => {
        // move this logic to it's own function when we implement actual components
        setState({
          companies: res[0].map(company => {
            return {
              value: company.id,
              label: company.name,
              acronym: company.acronym
            };
          }),
          projects: res[1].map(project => {
            return {
              value: project.id,
              label: project.name,
              company_id: project.company_id,
              therapy_area: project.therapy_area
            };
          }),
          dataRules: res[2].map(rule => {
            return { value: rule.id, label: rule.name };
          }),
          defaultFormValues: res[3] || {},
          is_submitted: res[4] ? res[4].is_submitted : false,
          json_blob: res[4] ? res[4].json_blob : {},
          extra_config_blob: res[4] ? res[4].extra_config_blob : {},
          loading: false
        });
        setRawJSONBlob(res[4].raw_json_blob);
      })
      .catch(err => {
        setState(() => {
          throw new Error(err);
        });
      });
  };

  useEffect(init, [
    companyService,
    projectService,
    formService,
    dataRuleService,
    formDefaultsService,
    alertId,
    isCreate
  ]);

  useEffect(() => {
    // detect and set active step
    setActiveStep(
      formSteps.findIndex(step => location.pathname.includes(step.path))
    );
  }, [location]);

  return (
    <Wrapper disableGutters>
      <Loader loading={state.loading} />
      {!state.loading && (
        <Formik
          initialValues={formInitialValues(state.json_blob)}
          validationSchema={currentValidationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {props => (
            <>
              <ScrollToError />
              <Prompt
                message={(location, action) => {
                  // reset form when user hits browser's back button
                  if (action === 'POP') {
                    props.resetForm();
                    dismissErrorMessage();
                    window.scrollTo(0, 0);
                  }
                  return true;
                }}
              />
              <Form id={formId}>
                <Loader loading={props.isSubmitting} />
                <Stepper
                  activeStep={activeStep}
                  steps={formSteps.map(step => step.label)}
                  disableRipple={true}
                  hasCompletedStepIndicator={true}
                  customLeftComponent={
                    <Button
                      hasArrowLeft={true}
                      disabled={activeStep === 0 || props.isSubmitting}
                      onClick={() => handleBack(props)}
                    />
                  }
                  customRightComponent={
                    <Button
                      type="submit"
                      hasArrowRight={!isLastStep}
                      color="primary"
                      disabled={props.isSubmitting}
                    >
                      {isLastStep
                        ? lookup('saas_form_submit_button')
                        : lookup('saas_form_next_button')}
                    </Button>
                  }
                />
                <FormWrapper maxWidth="md">
                  <Alert
                    onClose={dismissErrorMessage}
                    {...errorMessage}
                    noMinHeight
                  />
                  <Switch>
                    <Route
                      exact
                      path={AppRoutes.ALERT_FORM_CUSTOMER(':alertId?')}
                      render={() => (
                        <CustomerForm
                          formField={formField}
                          formikProps={props}
                          companies={state.companies}
                          projects={state.projects}
                          isCreate={isCreate}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={AppRoutes.ALERT_FORM_COHORT(':alertId?')}
                      render={() => (
                        <CohortForm formField={formField} formikProps={props} />
                      )}
                    />
                    <Route
                      exact
                      path={AppRoutes.ALERT_FORM_ALERT_TRIGGER(':alertId?')}
                      render={() => (
                        <AlertTriggerForm
                          dataRules={state.dataRules}
                          formField={formField}
                          formikProps={props}
                          isSubmitted={state.is_submitted}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={AppRoutes.ALERT_FORM_ENRICHMENT(':alertId?')}
                      render={() => (
                        <EnrichmentForm
                          formField={formField}
                          formikProps={props}
                          defaultEnrichment={
                            state.defaultFormValues.default_claims_enrichment
                          }
                          defaultDTSColumns={
                            state.defaultFormValues.output_columns_map
                              .dts_columns
                          }
                        />
                      )}
                    />
                    <Route
                      exact
                      path={AppRoutes.ALERT_FORM_ALERT_OUTPUT(':alertId?')}
                      render={() => (
                        <AlertOutputForm
                          formField={formField}
                          formikProps={props}
                          defaultColumns={
                            state.defaultFormValues.output_columns_map
                          }
                          defaultNoiseReduction={
                            state.defaultFormValues
                              .default_noise_reduction_mapping
                          }
                        />
                      )}
                    />
                    <Route
                      exact
                      path={AppRoutes.ALERT_FORM_REVIEW(':alertId?')}
                      render={() => (
                        <ReviewForm
                          formField={formField}
                          projects={state.projects}
                          alertId={alertId}
                          rawData={rawJSONBlob}
                          extraConfigBlob={state.extra_config_blob}
                          data={state.json_blob}
                          defaultNoiseReduction={
                            state.defaultFormValues
                              .default_noise_reduction_mapping
                          }
                          defaultDTSColumns={
                            state.defaultFormValues.output_columns_map
                              .dts_columns
                          }
                        />
                      )}
                    />
                    <Redirect to={AppRoutes.ALERT_FORM_CUSTOMER(':alertId?')} />
                  </Switch>
                </FormWrapper>
              </Form>
            </>
          )}
        </Formik>
      )}
    </Wrapper>
  );
});
