import { GoogleSpreadsheet } from 'google-spreadsheet';
import { handleErrors } from 'utils/error';
import creds from './config/pulse-sheets-api.json';

class GoogleSheetService {
  /**
   * 'Constructor' for GoogleSheetService
   * @param {string} URL google sheet URL
   */
  constructor(url) {
    this.url = new URL(url);
  }

  /**
   * Get Google Sheet
   * @async
   * @return {Promise} Google Sheet
   */
  async get() {
    try {
      const sheetId = this.url.pathname.split('/')[3];
      const tabId = this.url.hash.split('=')[1];

      const doc = new GoogleSpreadsheet(sheetId);
      await doc.useServiceAccountAuth(creds);
      await doc.loadInfo();

      const sheet = doc.sheetsById[tabId];
      const rows = await sheet.getRows();

      return { doc, sheet, rows };
    } catch (e) {
      throw await handleErrors(e);
    }
  }
}

export { GoogleSheetService };
