import { CriteriaBox as KomodoCriteriaBox } from '@komodohealth/fs-harmony.ui.criteria-box';
import styled from 'styled-components';

export const CriteriaBox = styled(KomodoCriteriaBox)(
  p => `
  .Criteria-Box-Title {
    min-width: 230px;
  }
  .Criteria-Box-Actions{
    padding: 0;
    .Tooltip-Children{
      margin-left: ${p.theme.space.md};
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`
);

export const CriteriaBoxEnrichment = styled(KomodoCriteriaBox)(
  p => `
    padding-left: ${p.theme.space.md};
    padding-right: ${p.theme.space.sm};
    margin-bottom: ${p.theme.space.sm};
    .Criteria-Box-Title {
      min-width: 90px;
    }
    .Criteria-Box-Actions{
      padding: 0;
      .Tooltip-Children{
        margin-left: ${p.theme.space.md};
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  `
);

export const criteriaBoxOptions = {
  hideActionsUntilHover: false
};

export const multilineProps = {
  multiline: true,
  rowsMax: 5,
  inputProps: {
    style: {
      padding: 0
    }
  }
};

export const CODE_LOGIC = [
  {
    value: 'filter_by_px_only',
    label: 'PX'
  },
  {
    value: 'filter_by_dx_only',
    label: 'DX'
  },
  {
    value: 'filter_by_rx_only',
    label: 'RX'
  },
  {
    value: 'filter_by_px_and_dx',
    label: 'PX AND DX'
  },
  {
    value: 'filter_by_px_or_dx',
    label: 'PX OR DX'
  },
  {
    value: 'filter_by_px_and_rx',
    label: 'PX AND RX'
  },
  {
    value: 'filter_by_px_or_rx',
    label: 'PX OR RX'
  },
  {
    value: 'filter_by_dx_and_rx',
    label: 'DX AND RX'
  },
  {
    value: 'filter_by_dx_or_rx',
    label: 'DX OR RX'
  },
  {
    value: 'filter_by_px_and_dx_and_rx',
    label: 'PX AND DX AND RX'
  },
  {
    value: 'filter_by_px_or_dx_or_rx',
    label: 'PX OR DX OR RX'
  },
  {
    value: 'filter_by_px_and_dx_or_rx',
    label: 'PX AND DX OR RX'
  },
  {
    value: 'filter_by_px_and_rx_or_dx',
    label: 'PX AND RX OR DX'
  },
  {
    value: 'filter_by_dx_and_rx_or_px',
    label: 'DX AND RX OR PX'
  },
  {
    value: 'filter_by_px_or_dx_and_rx',
    label: 'PX OR DX AND RX'
  },
  {
    value: 'filter_by_px_or_rx_and_dx',
    label: 'PX OR RX AND DX'
  },
  {
    value: 'filter_by_dx_or_rx_and_px',
    label: 'DX OR RX AND PX'
  }
];
