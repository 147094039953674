import React, { useEffect } from 'react';
import { Box, TextField as MuiTextField } from '@material-ui/core';
import { useField } from 'formik';
import { SelectField } from '.';
import {
  ALERT_CATEGORIES,
  NOISE_REDUCTION_OPTIONS_WITH_DAYS,
  CONTINUOUS_THERAPY_NOISE_REDUCTION_OPTIONS
} from 'constants/AppConstants';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import { trackEvent } from 'utils/analytics';
import I18n from 'i18n';
const { lookup } = new I18n();

const NoiseReductionContainer = styled.div`
  display: grid;
`;

const LookbackDaysContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TextField = styled(MuiTextField)(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  .MuiInputBase-root {
    border-radius: 0;
  }
`
);

export const NoiseReductionField = props => {
  const { defaultNoiseReduction, setFieldValue, formFields } = props;
  let filteredDefaultNoiseReduction = defaultNoiseReduction;

  const {
    alert_category_name,
    noise_config,
    noise_reduction,
    dedupe_config,
    client_acronym,
    project_directory,
    expected_compound_columns_to_be_unique
  } = formFields;

  const [alertCategory] = useField(alert_category_name.name);
  const [noiseConfig] = useField(noise_config.name);
  const [noiseReduction] = useField(noise_reduction.name);
  const [clientAcronym] = useField(client_acronym.name);
  const [projectDirectory] = useField(project_directory.name);
  const [dedupeConfig] = useField(dedupe_config.name);

  // filter noise reduction array if alert category is CONTINUOUS_THERAPY
  if (alertCategory.value === ALERT_CATEGORIES.CONTINUOUS_THERAPY) {
    filteredDefaultNoiseReduction = defaultNoiseReduction.filter(config =>
      CONTINUOUS_THERAPY_NOISE_REDUCTION_OPTIONS.includes(config.value)
    );
  }

  const handleChangeNoiseReduction = e => {
    const { value: newValue } = e.target;

    // set _ui_ field
    setFieldValue(noiseConfig.name, newValue);

    // start updating noise_reduction, dedupe_config and expected_compound_columns_to_be_unique
    let newConfig = cloneDeep(
      filteredDefaultNoiseReduction.find(({ value }) => value === newValue)
    );

    // set noise_reduction
    setFieldValue(noiseReduction.name, {
      ...newConfig.noise_reduction,
      ...(NOISE_REDUCTION_OPTIONS_WITH_DAYS.includes(newValue) && {
        lookback_days:
          noiseReduction.value?.lookback_days ||
          newConfig.noise_reduction.lookback_days
      })
    });

    // set dedupe_config
    if (newConfig.dedupe_config) {
      newConfig.dedupe_config[0][clientAcronym.name] = clientAcronym.value;
      newConfig.dedupe_config[0][projectDirectory.name] =
        projectDirectory.value;
      if (NOISE_REDUCTION_OPTIONS_WITH_DAYS.includes(newValue)) {
        newConfig.dedupe_config[0].lookback_days =
          noiseReduction.value?.lookback_days ||
          newConfig.noise_reduction.lookback_days;
      }
    }

    setFieldValue(
      dedupeConfig.name,
      newConfig.dedupe_config ? [...newConfig.dedupe_config] : undefined
    );

    // set expected_compound_columns_to_be_unique
    setFieldValue(
      expected_compound_columns_to_be_unique.name,
      newConfig.expected_compound_columns_to_be_unique
    );

    trackEvent('noise_reduction_changed', newValue);
  };

  const LookbackDays = () => {
    return (
      <Box>
        <Box pt={1} pb={1}>
          {lookup('noise_reduction_select_days_label')}
        </Box>
        <LookbackDaysContainer>
          <TextField
            type="number"
            value={noiseReduction.value.lookback_days}
            onChange={e => {
              const newValue = e.target.value;
              // set lookback days for noise_reduction
              const lookback_days = newValue ? parseInt(newValue) : '';
              setFieldValue(noiseReduction.name, {
                ...noiseReduction.value,
                lookback_days
              });
              // set lookback days for dedupe_config
              let newDedupeConfig = [...dedupeConfig.value];
              newDedupeConfig[0].lookback_days = lookback_days;
              setFieldValue(dedupeConfig.name, newDedupeConfig);
            }}
            variant="outlined"
            InputProps={{
              inputProps: {
                min: 7,
                max: 99999,
                step: 1,
                required: true
              }
            }}
          />
          <Box pl={1}>{lookup('noise_reduction_select_days_unit_label')}</Box>
        </LookbackDaysContainer>
      </Box>
    );
  };

  useEffect(() => {
    /**
     * CONTINUOUS_THERAPY
     * - use last index as default
     * OTHER TYPES
     * - use first item [0] as default
     **/

    const defaultIndex =
      alertCategory.value === ALERT_CATEGORIES.CONTINUOUS_THERAPY
        ? filteredDefaultNoiseReduction.length - 1
        : 0;
    let defaultSelected = cloneDeep(
      filteredDefaultNoiseReduction[defaultIndex]
    );

    if (alertCategory.value === ALERT_CATEGORIES.CONTINUOUS_THERAPY) {
      defaultSelected.dedupe_config[0][clientAcronym.name] =
        clientAcronym.value;
      defaultSelected.dedupe_config[0][projectDirectory.name] =
        projectDirectory.value;
    }

    if (!noiseConfig.value) {
      setFieldValue(noiseConfig.name, defaultSelected.value);
      setFieldValue(noiseReduction.name, defaultSelected.noise_reduction);
      setFieldValue(dedupeConfig.name, defaultSelected.dedupe_config);
      setFieldValue(
        expected_compound_columns_to_be_unique.name,
        defaultSelected.expected_compound_columns_to_be_unique
      );
    }
  }, [
    setFieldValue,
    clientAcronym,
    projectDirectory,
    alertCategory.value,
    noiseConfig.value,
    noiseConfig.name,
    noiseReduction.name,
    dedupeConfig.name,
    expected_compound_columns_to_be_unique.name,
    filteredDefaultNoiseReduction
  ]);

  return (
    <NoiseReductionContainer>
      <SelectField
        name={noiseConfig.name}
        placeholder={noise_config.placeholder}
        displayEmpty
        data={filteredDefaultNoiseReduction}
        variant="outlined"
        onChange={handleChangeNoiseReduction}
        fullWidth
      />
      {NOISE_REDUCTION_OPTIONS_WITH_DAYS.includes(noiseConfig.value) &&
        LookbackDays()}
    </NoiseReductionContainer>
  );
};
