import React, { useState } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextInput as KomodoTextInput } from '@komodohealth/fs-harmony.form.text-input';
import { FormControl, TextField as MuiTextField } from '@material-ui/core';
import styled from 'styled-components';
import HelperText from 'components/common/helperText';

const defaultNumberInputProps = {
  inputProps: {
    min: 0.1,
    step: 'any'
  }
};

const TextInput = styled(KomodoTextInput)(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  .MuiInputBase-root {
    border-radius: 0;
  }
`
);

const TextField = styled(MuiTextField)(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  .MuiInputBase-root {
    border-radius: 0;
  }
`
);

export const InputField = props => {
  const {
    fullWidth = true,
    type,
    inputProps = defaultNumberInputProps,
    customOnChange,
    ...rest
  } = props;
  const [field, meta, { setTouched, setValue }] = useField(props);

  const [inputVal, setInputVal] = useState(field.value);

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <HelperText error={true}>{error}</HelperText>;
    }
  };

  const handleUpdate = () => {
    setTouched(true);
    setValue(inputVal);
    customOnChange && customOnChange(inputVal);
  };

  const handleNumberUpdate = () => {
    setTouched(true);
    setValue(inputVal ? parseFloat(inputVal) : '');
    customOnChange && customOnChange(inputVal);
  };

  // TODO: NumberInput in fs-component-library is buggy atm, replace in the future
  // https://github.com/komodohealth/fs-component-library/issues/95
  if (type === 'number') {
    return (
      <FormControl fullWidth={fullWidth}>
        <TextField
          type={type}
          error={meta.touched && meta.error && true}
          variant="outlined"
          InputProps={inputProps}
          {...rest}
          value={inputVal}
          onChange={e => {
            setInputVal(e.target.value);
          }}
          onBlur={handleNumberUpdate}
          onKeyPress={e => {
            if (e.code === 'Enter') {
              handleNumberUpdate();
            }
          }}
        />
        {renderHelperText()}
      </FormControl>
    );
  }

  return (
    <FormControl fullWidth={fullWidth}>
      <TextInput
        error={meta.touched && meta.error && true}
        hideClearIcon={true}
        {...rest}
        value={inputVal}
        onChange={e => {
          setInputVal(e.target.value);
        }}
        onBlur={handleUpdate}
        keyValuesToWatch={['Enter']}
        onKeyPress={handleUpdate}
        label=""
      />
      {renderHelperText()}
    </FormControl>
  );
};
