import yaml from 'js-yaml';
import ENDPOINT from 'utils/api';
import { handleErrors } from 'utils/error';

class ProjectDataRuleService {
  /**
   * 'Constructor' for ProjectDataRuleService
   * @param {string} token auth0 token
   */
  constructor(token) {
    this.path = `${ENDPOINT}project_data_rules`;
    this.requestHeader = {
      headers: {
        'Access-Token': token
      }
    };
    this.jsonRequestHeader = {
      headers: {
        ...this.requestHeader.headers,
        'Content-Type': 'application/json'
      }
    };
  }

  /**
   * Get ProjectDataRules
   * @async
   * @param {integer} projectId project this data rule is assigned to
   * @return {Promise} projectDataRules.
   */
  async get(projectId) {
    try {
      const res = await fetch(
        `${this.path}?project_id=${projectId}`,
        this.requestHeader
      );
      if (!res.ok) {
        throw res;
      }
      let response = await res.json();
      response = response.map(record => {
        record.extra_config_blob = formatConfigBlob(
          record.extra_config_blob,
          'yaml'
        );
        return record;
      });
      return response;
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Create ProjectDataRule
   * @async
   * @param {Object} data new projectDataRule
   * @return {Promise} new projectDataRule.
   */
  async create(data) {
    const formattedData = {
      ...data,
      extra_config_blob: formatConfigBlob(data.extra_config_blob, 'json')
    };
    const requestOptions = {
      ...this.jsonRequestHeader,
      method: 'POST',
      body: JSON.stringify(formattedData)
    };
    try {
      const res = await fetch(`${this.path}`, requestOptions);
      if (!res.ok) {
        throw res;
      }
      let response = await res.json();
      response.extra_config_blob = formatConfigBlob(
        response.extra_config_blob,
        'yaml'
      );
      return response;
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Update ProjectDataRule
   * @async
   * @param {Object} data updated projectDataRule
   * @return {Promise} updated projectDataRule.
   */
  async update(data) {
    const formattedData = {
      ...data,
      extra_config_blob: formatConfigBlob(data.extra_config_blob, 'json')
    };
    const requestOptions = {
      ...this.jsonRequestHeader,
      method: 'PUT',
      body: JSON.stringify(formattedData)
    };
    try {
      const res = await fetch(`${this.path}/${data.id}`, requestOptions);
      if (!res.ok) {
        throw res;
      }
      let response = await res.json();
      response.extra_config_blob = formatConfigBlob(
        response.extra_config_blob,
        'yaml'
      );
      return response;
    } catch (e) {
      throw await handleErrors(e);
    }
  }
}

export const formatConfigBlob = (configBlob, format, sortKeys = false) => {
  let formattedVal = null;

  if (!configBlob || (typeof configBlob === 'string' && !configBlob.trim())) {
    return formattedVal;
  }

  switch (format) {
    case 'json':
      const jsonConfigBlob = yaml.load(configBlob.trim());
      return JSON.stringify(jsonConfigBlob);

    case 'yaml':
      const yamlStr = yaml.dump(configBlob, { forceQuotes: true, sortKeys });
      return yamlStr.trim();

    default:
      break;
  }
  return formattedVal;
};

export { ProjectDataRuleService };
