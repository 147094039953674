import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { TextInput } from '@komodohealth/fs-harmony.form.text-input';
import { Button } from '@komodohealth/fs-harmony.ui.button';
import {
  Typography,
  Paper,
  Box,
  Grid,
  Tooltip,
  Avatar as MuiAvatar,
  IconButton
} from '@material-ui/core';
import Dialog from 'components/common/dialog';
import HelperText from 'components/common/helperText';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import EditIcon from '@material-ui/icons/Edit';
import RestoreIcon from '@material-ui/icons/Restore';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useField } from 'formik';
import { groupBy } from 'lodash';
import styled, { css } from 'styled-components';
import {
  MAX_LENGTH_MESSAGE,
  RESERVED_COLUMN_NAMES
} from 'constants/AppConstants';
import * as yup from 'yup';
import { trackEvent } from 'utils/analytics';
import I18n from 'i18n';
const { lookup } = new I18n();

const KEYS = {
  SELECTED_ITEMS: 'selectedItems',
  AVAILABLE_ITEMS: 'availableItems'
};

const DraggableTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))(
  p => css`
    & .MuiTooltip-tooltip {
      background-color: ${p.theme.colors.white};
      box-shadow: ${p.theme.effects.boxShadow};
      ${p.theme.typography.h6};
      padding: ${p.theme.space.sm};
    }
  `
);

const List = styled.div(
  p => css`
    width: auto;
    height: 100%;
    padding: ${p.theme.space.md};
    overflow: auto;
    /** TODO: remove temp color here **/
    background: ${p.isDraggingOver && !p.isDraggingDisabled
      ? '#C4CCE1'
      : p.theme.colors.light};

    ${!p.noBorder &&
    `
      border: dashed 1px ${p.theme.colors.primary};
    `}

    ${(p.displayOverlay || p.isEmpty) &&
    css`
      .list-group {
        display: none;
      }
      overflow: hidden;
      &:before {
        ${p.theme.typography.h5};
        content: '\\2192 Drop here to remove';
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    `}
  `
);

const ListTitle = styled(Typography)(
  p => `
  padding-bottom: ${p.theme.space.sm};
  word-break: break-all;
`
);

const ListItem = styled.div(
  p => `
  max-width: 350px;
  word-break: break-all;
  background-color: ${p.theme.colors.white};
  display: flex;
  align-items: center;
  padding: ${p.theme.space.sm} 0;
  // temp borders, will change 
  border-radius: 4px;
  border: 2px solid ${p.theme.colors.light};
`
);

const ListItemContent = styled.div(
  p => `
  padding-left: ${p.theme.space.md};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
);

const ListNumber = styled(MuiAvatar)(
  p => css`
    width: 24px;
    height: 24px;
    ${p.theme.typography.h6};
  `
);

const DefaultColumnName = styled(Typography)`
  word-break: break-all;
`;

const EditModalBody = styled.div`
  width: 300px;
`;

/**
 * Get Initial State
 * @param {Array} savedColumns columns saved in json_blob
 * @param {Object} default_selected, additional_columns default columns from saas_defaults
 * @param {Array} enrichment available enrichment columns
 * @return {Object} initial state with available and selected items
 */
const getInitialState = (
  savedColumns,
  { default_selected, additional_columns, dts_columns },
  enrichment,
  dtsEnabled
) => {
  let retVal = {
    [KEYS.AVAILABLE_ITEMS]: [],
    [KEYS.SELECTED_ITEMS]: []
  };

  let flatEnrichmentColumns = [];

  // init enrichment columns for output columns map
  enrichment.forEach(item => {
    item.enrichment_columns.forEach(({ fieldname }) => {
      flatEnrichmentColumns.push({
        group: item.event_name,
        source_column_name: fieldname,
        target_column_name: fieldname,
        default_target_column_name: fieldname,
        is_enrichment: true
      });
    });
  });

  if (!savedColumns.length > 0) {
    retVal[KEYS.AVAILABLE_ITEMS] = additional_columns;
    retVal[KEYS.SELECTED_ITEMS] = [
      ...default_selected,
      ...flatEnrichmentColumns,
      ...(dtsEnabled && dts_columns)
    ];
  } else {
    const allColumns = [
      ...flatEnrichmentColumns,
      ...(dtsEnabled && dts_columns),
      ...default_selected,
      ...additional_columns
    ];
    savedColumns.forEach(item => {
      const indexFound = allColumns.findIndex(
        fd => fd.source_column_name === item.source_column_name
      );
      retVal[KEYS.SELECTED_ITEMS].push({
        ...allColumns[indexFound],
        ...item
      });
      if (indexFound > -1) {
        allColumns.splice(indexFound, 1);
      }
    });
    retVal[KEYS.AVAILABLE_ITEMS] = allColumns;
  }

  return retVal;
};

/**
 * Util function to get all target_column_name values
 * @param {Object} state current state
 * @return {Array} Array of all target_column_name values
 */
const getAllTargetColumnNames = state => {
  const allColumns = [...state.availableItems, ...state.selectedItems];
  return allColumns.map(item => item.target_column_name.toLowerCase());
};

export const AlertOutputField = props => {
  const {
    outputColumns,
    setFieldValue,
    defaultColumns,
    enrichment,
    dtsFlag
  } = props;
  const [field] = useField(outputColumns);
  const [enrichmentField] = useField(enrichment);
  const [dtsEnabled] = useField(dtsFlag);
  const dtsSourceColumns = defaultColumns.dts_columns.map(
    column => column.source_column_name
  );

  const [state, setState] = useState(
    getInitialState(
      field.value,
      defaultColumns,
      enrichmentField.value,
      dtsEnabled.value
    )
  );

  const [isNotDroppable, setIsNotDroppable] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalContent, setEditModalContent] = useState({});
  const [editModalInputVal, setEditModalInputVal] = useState('');
  const [editModalValidationText, setEditModalValidationText] = useState('');

  const editModalValidationSchema = yup.object().shape({
    columnName: yup
      .string()
      .min(3, lookup('output_column_min_chars'))
      .matches(/^[a-zA-Z0-9_]*$/, lookup('output_column_special_chars'))
      .max(115, MAX_LENGTH_MESSAGE(lookup('output_form_edit_value_label'), 115))
      .test(
        'test-uniqueness',
        lookup('output_column_uniqueness'),
        value =>
          editModalContent.target_column_name.toLowerCase() ===
            editModalInputVal.toLowerCase() ||
          !getAllTargetColumnNames(state).includes(value.toLowerCase())
      )
      .test(
        'test-reserved-name',
        lookup('output_column_reserved_name'),
        value => !RESERVED_COLUMN_NAMES.includes(value.toLowerCase())
      )
  });

  const onDragStart = ({ source }) => {
    // Disable drop for locked columns and available items
    const isLocked = Boolean(state[source.droppableId][source.index].is_locked);
    const isAvailableItems = source.droppableId === KEYS.AVAILABLE_ITEMS;
    setIsNotDroppable(isLocked || isAvailableItems);
  };

  const onDragEnd = ({ source, destination }) => {
    // reset flag
    setIsNotDroppable(false);

    if (!destination) {
      return;
    }

    // Sorting in same list
    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        state[source.droppableId],
        source.index,
        destination.index
      );
      setState({
        ...state,
        [source.droppableId]: items
      });
    }
    // Interlist movement
    else {
      const result = move(
        state[source.droppableId],
        state[destination.droppableId],
        source,
        destination
      );
      updateState(result);
    }
  };

  const handleRemove = index => {
    const source = { droppableId: KEYS.SELECTED_ITEMS, index };
    const destination = {
      droppableId: KEYS.AVAILABLE_ITEMS,
      index: state.availableItems.length
    };

    const result = move(
      state[source.droppableId],
      state[destination.droppableId],
      source,
      destination
    );

    updateState(result);
  };

  const handleAdd = index => {
    const source = { droppableId: KEYS.AVAILABLE_ITEMS, index };
    const destination = {
      droppableId: KEYS.SELECTED_ITEMS,
      index: state.selectedItems.length
    };

    const result = move(
      state[source.droppableId],
      state[destination.droppableId],
      source,
      destination
    );
    updateState(result);
  };

  const updateState = result => {
    setState({
      [KEYS.AVAILABLE_ITEMS]: result.availableItems,
      [KEYS.SELECTED_ITEMS]: result.selectedItems
    });
  };

  useEffect(() => {
    // update output field value any time state changes
    setFieldValue(
      outputColumns.name,
      state.selectedItems.map(
        ({ source_column_name, target_column_name, is_enrichment }) => {
          return {
            ...(is_enrichment && { is_enrichment }),
            source_column_name,
            target_column_name
          };
        }
      )
    );
  }, [state, outputColumns.name, setFieldValue]);

  // reorder list
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // Move item from one list to other
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    if (droppableDestination.droppableId === KEYS.AVAILABLE_ITEMS) {
      const lastIndex =
        state.availableItems.map(el => el.group).lastIndexOf(removed.group) + 1;
      destClone.splice(lastIndex, 0, removed);
      trackEvent('output_column_removed', removed.source_column_name);
    } else {
      destClone.splice(droppableDestination.index, 0, removed);
      trackEvent('output_column_added', removed.source_column_name);
    }

    return {
      [droppableSource.droppableId]: sourceClone,
      [droppableDestination.droppableId]: destClone
    };
  };

  const handleColumnReset = item => {
    const newState = { ...state };
    const objIndex = state.selectedItems.findIndex(obj => obj === item);
    newState.selectedItems[objIndex].target_column_name =
      item.default_target_column_name;
    setState(newState);
  };

  const handleColumnUpdate = () => {
    editModalValidationSchema
      .validate({
        columnName: editModalInputVal
      })
      .then(valid => {
        const newState = { ...state };
        const objIndex = state.selectedItems.findIndex(
          item => item === editModalContent
        );
        newState.selectedItems[objIndex].target_column_name = editModalInputVal;
        setState(newState);
        closeEditModal();
      })
      .catch(err => {
        setEditModalValidationText(err.errors.toString());
      });
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditModalInputVal('');
    setEditModalValidationText('');
  };

  const renderAvailableColumns = data => {
    const sortedData = [...data].sort((x, y) => x.group.localeCompare(y.group));
    return Object.entries(groupBy(sortedData, 'group')).map(group => {
      return (
        <Box key={group[0]} className="list-group" pb={1}>
          <ListTitle variant="h6">{group[0]}</ListTitle>
          {group[1].map(item => {
            const indexToUse = data.indexOf(item);
            return (
              <Draggable
                key={item.default_target_column_name}
                draggableId={item.default_target_column_name}
                index={indexToUse}
              >
                {(provided, snapshot) => (
                  <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <DragIndicatorIcon color="action" />
                    <ListItemContent>
                      <div>
                        {item.target_column_name}
                        {item.is_enrichment && (
                          <Typography variant="caption" component="p">
                            {lookup('output_form_enrichment_subtitle')}
                          </Typography>
                        )}
                        {dtsSourceColumns.includes(item.source_column_name) && (
                          <Typography variant="caption" component="p">
                            {lookup('output_form_dts_subtitle')}
                          </Typography>
                        )}
                      </div>
                      <IconButton
                        onClick={() => handleAdd(indexToUse)}
                        size="small"
                      >
                        <AddIcon color="action" />
                      </IconButton>
                    </ListItemContent>
                  </ListItem>
                )}
              </Draggable>
            );
          })}
        </Box>
      );
    });
  };

  return (
    <>
      <Paper square>
        <Box p={2} mt={2}>
          <Typography variant="h5" paragraph>
            {lookup('output_form_columns_title')}
          </Typography>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={8} md={8} lg={8}>
                <Droppable droppableId={KEYS.SELECTED_ITEMS}>
                  {(provided, snapshot) => (
                    <List
                      isDraggingOver={snapshot.isDraggingOver}
                      ref={provided.innerRef}
                    >
                      <ListTitle variant="h6">
                        {lookup('output_form_selected_columns_title')}
                      </ListTitle>
                      {state.selectedItems.map((item, index) => (
                        <Draggable
                          key={item.default_target_column_name}
                          draggableId={item.default_target_column_name}
                          isLocked={Boolean(item.is_locked)}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <DragIndicatorIcon color="action" />
                              <ListNumber>{index + 1}</ListNumber>
                              <ListItemContent>
                                <div>
                                  {item.target_column_name}
                                  {item.is_enrichment && (
                                    <Typography variant="caption" component="p">
                                      {lookup(
                                        'output_form_enrichment_subtitle'
                                      )}
                                    </Typography>
                                  )}
                                  {dtsSourceColumns.includes(
                                    item.source_column_name
                                  ) && (
                                    <Typography variant="caption" component="p">
                                      {lookup('output_form_dts_subtitle')}
                                    </Typography>
                                  )}
                                </div>
                                {item.is_locked ? (
                                  <IconButton size="small" disabled>
                                    <LockIcon />
                                  </IconButton>
                                ) : (
                                  <Box display="flex">
                                    {item.target_column_name !==
                                      item.default_target_column_name && (
                                      <DraggableTooltip
                                        title={`${lookup(
                                          'output_form_selected_columns_reset_label'
                                        )} ${item.default_target_column_name}`}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            handleColumnReset(item)
                                          }
                                          size="small"
                                        >
                                          <RestoreIcon color="action" />
                                        </IconButton>
                                      </DraggableTooltip>
                                    )}
                                    <IconButton
                                      onClick={() => {
                                        setEditModalContent(item);
                                        setIsEditModalOpen(true);
                                      }}
                                      size="small"
                                    >
                                      <EditIcon color="action" />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => handleRemove(index)}
                                      size="small"
                                    >
                                      <CloseIcon color="action" />
                                    </IconButton>
                                  </Box>
                                )}
                              </ListItemContent>
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Droppable
                  droppableId={KEYS.AVAILABLE_ITEMS}
                  isDropDisabled={isNotDroppable}
                >
                  {(provided, snapshot) => {
                    return (
                      <List
                        isDraggingOver={snapshot.isDraggingOver}
                        isDraggingDisabled={
                          snapshot.draggingOverWith ===
                          snapshot.draggingFromThisWith
                        }
                        ref={provided.innerRef}
                        displayOverlay={
                          snapshot.draggingOverWith &&
                          !snapshot.draggingFromThisWith
                        }
                        isEmpty={state.availableItems.length === 0}
                        noBorder
                      >
                        {renderAvailableColumns(state.availableItems)}
                        {provided.placeholder}
                      </List>
                    );
                  }}
                </Droppable>
              </Grid>
            </Grid>
          </DragDropContext>
        </Box>
      </Paper>

      <Dialog
        title={lookup('output_form_edit_column_label')}
        body={
          <EditModalBody>
            <DefaultColumnName variant="h6" paragraph>
              {`${lookup('output_form_edit_default_value_label')} ${
                editModalContent.default_target_column_name
              }`}
            </DefaultColumnName>
            <TextInput
              placeholder={lookup('output_form_edit_placeholder')}
              onChange={e => {
                setEditModalInputVal(e.target.value);
              }}
            />
            {editModalValidationText && (
              <HelperText error={true}>{editModalValidationText}</HelperText>
            )}
          </EditModalBody>
        }
        handleClose={closeEditModal}
        isOpen={isEditModalOpen}
        footer={
          <>
            <Button variant="outlined" onClick={closeEditModal}>
              {lookup('output_form_edit_cancel_label')}
            </Button>
            <Button onClick={handleColumnUpdate}>
              {lookup('output_form_edit_ok_label')}
            </Button>
          </>
        }
      />
    </>
  );
};
