import ENDPOINT from 'utils/api';
import { handleErrors } from 'utils/error';

class DeliverySettingService {
  /**
   * 'Constructor' for file delivery setting
   * @param {string} token auth0 token
   */
  constructor(token) {
    this.path = `${ENDPOINT}file_delivery_settings`;
    this.requestHeader = {
      headers: {
        'Access-Token': token
      }
    };
    this.jsonRequestHeader = {
      headers: {
        ...this.requestHeader.headers,
        'Content-Type': 'application/json'
      }
    };
  }

  /**
   * Get File Delivery Setting
   * @async
   * @param {integer} projectDataRuleId project this file delivery setting is assigned to
   * @return {Promise} file delivery setting.
   */
  async get(projectDataRuleId) {
    try {
      const res = await fetch(
        `${this.path}?project_data_rule_id=${projectDataRuleId}`,
        this.requestHeader
      );
      if (!res.ok) {
        throw res;
      }
      return await res.json();
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Create File Delivery Setting
   * @async
   * @param {integer} projectDataRuleId project data rule that this file setting is assigned to
   * @param {Object} data new file delivery setting
   * @return {Promise} new file delivery setting.
   */
  async create(data) {
    const requestOptions = {
      ...this.jsonRequestHeader,
      method: 'POST',
      body: JSON.stringify({ ...data })
    };
    try {
      const res = await fetch(`${this.path}`, requestOptions);
      if (!res.ok) {
        throw res;
      }
      return await res.json();
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Update File Delivery Setting
   * @async
   * @param {Object} data updated file delivery setting
   * @return {Promise} updated file delivery setting.
   */
  async update(data) {
    const requestOptions = {
      ...this.jsonRequestHeader,
      method: 'PUT',
      body: JSON.stringify(data)
    };
    try {
      const res = await fetch(`${this.path}/${data.id}`, requestOptions);
      if (!res.ok) {
        throw res;
      }
      return await res.json();
    } catch (e) {
      throw await handleErrors(e);
    }
  }
}

export { DeliverySettingService };
