import ENDPOINT from 'utils/api';
import { handleErrors } from 'utils/error';

class ProjectService {
  /**
   * 'Constructor' for ProjectService
   * @param {string} token auth0 token
   */
  constructor(token) {
    this.path = `${ENDPOINT}projects`;
    this.requestHeader = {
      headers: {
        'Access-Token': token,
        'Content-Type': 'application/json'
      }
    };
  }

  /**
   * Get All Projects or get by companyId
   * @async
   * @param {string} companyId
   * @return {Promise} projects.
   */
  async get(companyId) {
    try {
      const res = await fetch(
        `${this.path}${companyId ? `?company_id=${companyId}` : ''}`,
        this.requestHeader
      );
      if (!res.ok) {
        throw res;
      }
      return await res.json();
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Get Projects for multiple companies
   * @async
   * @param {Array} companyIds
   * @return {Promise} projects.
   */
  async getByIds(companyIds) {
    const params = new URLSearchParams();
    companyIds.forEach(id => {
      params.append('company_id', id);
    });

    try {
      const res = await fetch(
        `${this.path}?${params.toString()}`,
        this.requestHeader
      );
      if (!res.ok) {
        throw res;
      }
      return await res.json();
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Create Project
   * @async
   * @param {Object} data new project
   * @return {Promise} new project.
   */
  async create(data) {
    const requestOptions = {
      ...this.requestHeader,
      method: 'POST',
      body: JSON.stringify(data)
    };
    try {
      const res = await fetch(`${this.path}`, requestOptions);
      if (!res.ok) {
        throw res;
      }
      return await res.json();
    } catch (e) {
      throw await handleErrors(e);
    }
  }

  /**
   * Update Project
   * @async
   * @param {Object} data updated project
   * @return {Promise} updated project.
   */
  async update(data) {
    const requestOptions = {
      ...this.requestHeader,
      method: 'PUT',
      body: JSON.stringify(data)
    };
    try {
      const res = await fetch(`${this.path}/${data.id}`, requestOptions);
      if (!res.ok) {
        throw res;
      }
      return await res.json();
    } catch (e) {
      throw await handleErrors(e);
    }
  }
}

export { ProjectService };
