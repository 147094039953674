import I18n from 'i18n';
const { lookup } = new I18n();

const GENERIC_ERROR = 'GenericError';
const VALIDATION_ERROR = 'ValidationError';
const VALIDATION_ERROR_CODE = 409;
const UNHANDLED_ERROR_MESSAGE = lookup('unhandled_exception_label');

class APIError extends Error {
  /**
   * 'Constructor' for APIError
   * @augments Error
   * @param {string} message
   * @param {integer} status
   */
  constructor(message, { status }) {
    super();
    this.message = message;
    this.type =
      status === VALIDATION_ERROR_CODE ? VALIDATION_ERROR : GENERIC_ERROR;
  }
}

/**
 * Handle API errors
 * @async
 * @param {Object} err error object
 * @throws {Error}
 */
export async function handleErrors(err) {
  if (err.json) {
    const { message = null } = await err.json();
    if (message) {
      throw new APIError(message, err);
    } else {
      throw err;
    }
  }
  throw err;
}

/**
 * Helper function to get validation error message
 * @param {Object} err error object
 * @returns {string} error message
 */
export async function getValidationErrorMessage(err) {
  if (err.json) {
    const { message = null } = await err.json();
    if (message && err?.status === VALIDATION_ERROR_CODE) {
      return message;
    } else {
      return UNHANDLED_ERROR_MESSAGE;
    }
  }
  return UNHANDLED_ERROR_MESSAGE;
}

/**
 * Handle API errors
 * @param {Object} err error object
 * @param {function} validationErrorHandler error handler for validation errors
 * @param {function} genericErrorHandler error handler for generic errors
 */
export const displayErrorMessage = (
  err,
  validationErrorHandler,
  genericErrorHandler
) => {
  if ([VALIDATION_ERROR, GENERIC_ERROR].includes(err.type)) {
    validationErrorHandler({
      message: formatErrorMessage(err),
      type: 'error',
      open: true
    });
  } else {
    genericErrorHandler(() => {
      throw new Error(err);
    });
  }
};

/**
 * Format Error Message
 * @param {(string|Array)} message error message
 * @param {string} type error type
 * @returns {string} message or formatted message based on type
 */
const formatErrorMessage = ({ message, type }) => {
  if (type === VALIDATION_ERROR) {
    try {
      return message.map(error => {
        return `${error}\n`;
      });
    } catch (err) {
      // edge case, message should be array
      return message;
    }
  }
  return message;
};

export const testables = {
  handleErrors,
  displayErrorMessage,
  formatErrorMessage
};
