import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const ERROR_CLASS = '.Mui-error';

const ScrollToError = () => {
  const currentForm = useFormikContext();
  const isSubmitting = currentForm?.isSubmitting;

  useEffect(() => {
    // select first error
    const error = document.querySelector(ERROR_CLASS);
    // scroll to parent if exists or default to error
    const elementToScroll = error?.parentElement ?? error;
    if (elementToScroll) {
      // header + subheader = 134px
      // + 20px for extra padding top
      const yOffset = -154;
      const y =
        elementToScroll.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    // watch for isSubmitting
  }, [isSubmitting]);
  return null;
};

export default ScrollToError;
