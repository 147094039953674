import ENDPOINT from 'utils/api';
import { getValidationErrorMessage } from 'utils/error';

class UploadService {
  /**
   * 'Constructor' for UploadService
   * @param {string} token auth0 token
   */
  constructor(token) {
    this.path = `${ENDPOINT}uploads`;
    this.requestHeader = {
      headers: {
        'Access-Token': token
      }
    };
  }

  /**
   * Upload single file
   * @async
   * @param {Object} file file to upload
   * @param {string} uploadType selected upload type
   * @return {Promise} Uploaded file's data
   */
  async upload(file, uploadType) {
    let formData = new FormData();
    formData.append('file', file);

    const requestOptions = {
      ...this.requestHeader,
      method: 'POST',
      body: formData
    };

    try {
      const res = await fetch(
        `${this.path}?upload_type=${uploadType}`,
        requestOptions
      );
      if (!res.ok) {
        throw res;
      }
      return await res.json();
    } catch (e) {
      throw await getValidationErrorMessage(e);
    }
  }
}

export { UploadService };
