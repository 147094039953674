import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Typography,
  Paper,
  Box,
  Container as MuiContainer,
  Link,
  Grid,
  Divider
} from '@material-ui/core';
import {
  ToggleButton,
  ToggleButtonGroup
} from '@komodohealth/fs-harmony.form.toggle-button';
import Panel from '@komodohealth/fs-harmony.ui.panel';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { yaml } from 'react-syntax-highlighter/dist/cjs/languages/hljs';
import { qtcreatorLight } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import styled from 'styled-components';
import { SAAS_FORM, DTS_UNITS } from 'constants/AppConstants';
import { CODE_LOGIC } from './Shared';
import AppRoutes from 'constants/AppRoutes';
import { formatConfigBlob } from 'services/ProjectDataRuleService';
import { download } from 'utils/download';
import { trackView } from 'utils/analytics';
import I18n from 'i18n';
const { lookup } = new I18n();
const { REVIEW_FORM } = SAAS_FORM;

// Register line below is required for using light build for react-syntax-highlighter
SyntaxHighlighter.registerLanguage('yaml', yaml);

const Container = styled(MuiContainer)`
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinkContainer = styled.div`
  display: flex;
  float: right;
  flex-direction: column;
  align-items: flex-end;
`;

const LinkRow = styled(Link)`
  float: right;

  &:hover {
    cursor: pointer;
  }
`;

const GridRowLabel = styled(Typography)`
  text-transform: uppercase;
`;

const GridRowValue = styled.div`
  overflow-wrap: break-word;
`;

const CodesRow = ({ label, val }) => {
  if (Array.isArray(val) && val.length > 0) {
    return <GridRow label={label} val={val.join(', ')} />;
  }
  return null;
};

const GridRow = ({ label, val }) => {
  if (val) {
    return (
      <Grid container spacing={1} item xs={12} md={11}>
        <Grid item xs={3}>
          <GridRowLabel variant="h6" component="span">
            {label}
          </GridRowLabel>
        </Grid>
        <Grid item xs={9}>
          <GridRowValue>{val}</GridRowValue>
        </Grid>
      </Grid>
    );
  }
  return null;
};

const ReviewPanel = props => {
  return (
    <Panel
      isCollapsible
      renderHeaderContent={
        <>
          <Panel.Actions align="left">
            <Typography variant="h5">{props.title}</Typography>
          </Panel.Actions>
          <Panel.Actions>
            <Panel.ExpandButton />
          </Panel.Actions>
        </>
      }
    >
      {props.children}
    </Panel>
  );
};

const EnrichmentSection = ({
  enrichments,
  distanceToSite,
  distanceToSiteFileName,
  defaultDTSColumns,
  outputColumns
}) => {
  const allEnrichments = [
    ...(distanceToSite
      ? [
          {
            ...distanceToSite,
            isDTS: true
          }
        ]
      : []),
    ...enrichments
  ];

  const dtsSourceColumns = defaultDTSColumns.map(
    column => column.source_column_name
  );
  const appliedEnrichments = outputColumns
    .map((column, id) => {
      return {
        ...column,
        columnId: id + 1
      };
    })
    .filter(
      column =>
        column.is_enrichment ||
        dtsSourceColumns.includes(column.source_column_name)
    );

  return allEnrichments.map((enrichment, id) => {
    let aggregationTypes = [];

    if (enrichment.isDTS) {
      defaultDTSColumns.forEach(dtsColumn => {
        const appliedColumn = appliedEnrichments.find(enrichment => {
          return enrichment.source_column_name === dtsColumn.source_column_name;
        });
        aggregationTypes.push({
          name: ` ${dtsColumn.default_target_column_name}`,
          ...(appliedColumn && {
            columnId: appliedColumn.columnId
          })
        });
      });
    } else {
      enrichment.enrichment_columns.forEach(column => {
        const aggregationType = column.fieldname
          .replace(`${enrichment.event_name}`, '')
          .replace(/_/g, ' ');

        const appliedColumn = appliedEnrichments.find(enrichment => {
          return enrichment.source_column_name === column.fieldname;
        });
        if (appliedColumn) {
          const columnId = appliedColumn.columnId;
          aggregationTypes.push({
            name: `${aggregationType}`,
            columnId: `${columnId}`
          });
        } else {
          aggregationTypes.push({
            name: `${aggregationType}`
          });
        }
      });
    }

    return (
      <div key={`enrichment-${id}`}>
        <Grid container spacing={2} justify="flex-end">
          {enrichment.isDTS && (
            <>
              <GridRow
                label={lookup('review_enrichment_type_label')}
                val={lookup('enrichment_form_dts_title')}
              />
              <GridRow
                label={lookup('review_enrichment_dts_file_label')}
                val={distanceToSiteFileName}
              />
              <GridRow
                label={lookup('review_enrichment_dts_unit_label')}
                val={DTS_UNITS[enrichment.units_of_measure].label}
              />
            </>
          )}
          {!enrichment.isDTS && (
            <>
              <GridRow
                label={lookup('review_enrichment_type_label')}
                val={lookup('enrichment_event_name_label')}
              />
              <GridRow
                label={lookup('review_enrichment_name_label')}
                val={enrichment.event_name}
              />
              <CodesRow
                label={lookup('enrichment_dx_label')}
                val={enrichment.codes?.dx_codes}
              />
              <CodesRow
                label={lookup('enrichment_rx_label')}
                val={enrichment.codes?.rx_codes}
              />
              <CodesRow
                label={lookup('enrichment_px_label')}
                val={enrichment.codes?.px_codes}
              />
              <GridRow
                label={lookup('enrichment_lookback_window_label')}
                val={`${enrichment.window_in_days} ${lookup(
                  'enrichment_form_window_info'
                )}`}
              />
            </>
          )}
          <GridRow
            label={lookup('review_output_columns_label')}
            val={aggregationTypes.map(({ name, columnId }, id) => {
              return (
                <span key={id}>
                  {name}
                  {columnId ? (
                    <Typography variant="h6" component="span">
                      {' '}
                      (#{columnId})
                    </Typography>
                  ) : (
                    <Typography variant="h6" component="span">
                      {' '}
                      {lookup('review_enrichment_deselected_column_label')}
                    </Typography>
                  )}
                  {id !== aggregationTypes.length - 1 && <>,</>}
                </span>
              );
            })}
          />
        </Grid>
        {id !== allEnrichments.length - 1 && (
          <Box py={3} px={0}>
            <Divider variant="middle" />
          </Box>
        )}
      </div>
    );
  });
};

const ReviewForm = props => {
  const {
    alertId,
    rawData,
    projects,
    extraConfigBlob,
    defaultNoiseReduction,
    defaultDTSColumns,
    data: {
      claims_enrichment,
      distance_to_site,
      cohort_name,
      company_name,
      _ui_project_id,
      alert_name,
      medical_event_name,
      _ui_patient_age,
      dx_codes_allow_list,
      rx_codes_allow_list,
      px_codes_allow_list,
      dx_codes_deny_list,
      rx_codes_deny_list,
      px_codes_deny_list,
      _ui_hcp_npi_allow_list,
      _ui_hcp_npi_deny_list,
      _ui_include_any_hcp_specialties,
      _ui_exclude_any_hcp_specialties,
      _ui_dts_clinical_trial_site_list,
      alert_description,
      data_rule_name,
      filter_dx_rx_px,
      medical_event_dx_codes,
      medical_event_px_codes,
      medical_event_rx_codes,
      era_threshold_weeks,
      qualifying_window_in_weeks,
      lookback_window_in_months,
      delivery_day,
      output_columns_map,
      noise_reduction,
      _ui_noise_reduction
    }
  } = props;

  const { output_filename_template, ta_id } = rawData;
  const [tab, setTab] = useState('review');
  const [showExtraConfig, setShowExtraConfig] = useState(true);

  const transformYAML = data => {
    const {
      alert_name,
      alert_description,
      company_id,
      company_name,
      cohort_name,
      data_rule_id,
      data_rule_name,
      medical_event_name,
      lookback_window_in_months,
      ...rest
    } = data;

    // Filters out keys not used for yaml output
    let filteredData = Object.fromEntries(
      Object.entries(rest).filter(([key, val]) => !key.startsWith('_ui'))
    );

    if (showExtraConfig) {
      filteredData = {
        ...filteredData,
        ...extraConfigBlob
      };
    }

    return formatConfigBlob(filteredData, 'yaml', true);
  };

  const yamlOutput = transformYAML(rawData);

  const toggleShowConfig = () => {
    setShowExtraConfig(!showExtraConfig);
  };

  const editHandler = (e, path) => {
    window.scrollTo({ top: 0 });
    props.history.push(path);
  };

  const downloadYAML = () => {
    const downloadFileName = `${company_name}_${ta_id}_${data_rule_name
      .split(' ')
      .join('_')}_preview`;

    download(yamlOutput, downloadFileName, 'yaml');
  };

  useEffect(() => {
    trackView('saas_review');
  }, []);

  return (
    <>
      <Box pb={2}>
        <Title>
          <Typography variant="h4">{`${company_name} | ${
            projects.find(project => project.value === _ui_project_id).label
          }`}</Typography>

          <ToggleButtonGroup
            value={tab}
            exclusive
            onChange={(e, val) => {
              val !== null && setTab(val);
            }}
            size="medium"
          >
            <ToggleButton value={'review'}>
              {lookup('review_tab_default_label')}
            </ToggleButton>
            <ToggleButton value={'yaml'}>
              {lookup('review_tab_yaml_label')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Title>
      </Box>

      {tab === 'review' && (
        <>
          <ReviewPanel title={lookup('review_cohort_title')}>
            <LinkRow
              type="button"
              onClick={e =>
                editHandler(e, AppRoutes.ALERT_FORM_COHORT(alertId))
              }
            >
              {lookup('review_edit_link')}
            </LinkRow>
            <Grid container spacing={2} justify="flex-end">
              <GridRow
                label={lookup('review_cohort_name_label')}
                val={cohort_name}
              />
              <CodesRow
                label={lookup('review_cohort_dx_label')}
                val={dx_codes_allow_list}
              />
              <CodesRow
                label={lookup('review_cohort_px_label')}
                val={px_codes_allow_list}
              />
              <CodesRow
                label={lookup('review_cohort_rx_label')}
                val={rx_codes_allow_list}
              />
              {lookback_window_in_months && (
                <GridRow
                  label={lookup('cohort_lookback_label')}
                  val={`${lookback_window_in_months} ${lookup(
                    'cohort_form_lookback_period_info'
                  )}`}
                />
              )}
              <GridRow
                label={lookup('cohort_patient_age_label')}
                val={
                  _ui_patient_age &&
                  `${_ui_patient_age.toString().replace(',', '-')} ${lookup(
                    'review_cohort_patient_age_unit'
                  )}`
                }
              />
              <GridRow
                label={lookup('cohort_npi_allow_label')}
                val={_ui_hcp_npi_allow_list?.name}
              />
              <GridRow
                label={lookup('cohort_npi_deny_label')}
                val={_ui_hcp_npi_deny_list?.name}
              />
              <GridRow
                label={lookup('cohort_specialties_allow_label')}
                val={_ui_include_any_hcp_specialties?.name}
              />
              <GridRow
                label={lookup('cohort_specialties_deny_label')}
                val={_ui_exclude_any_hcp_specialties?.name}
              />
              <CodesRow
                label={lookup('review_cohort_dx_deny_label')}
                val={dx_codes_deny_list}
              />
              <CodesRow
                label={lookup('review_cohort_px_deny_label')}
                val={px_codes_deny_list}
              />
              <CodesRow
                label={lookup('review_cohort_rx_deny_label')}
                val={rx_codes_deny_list}
              />
            </Grid>
          </ReviewPanel>

          <ReviewPanel title={lookup('review_trigger_title')}>
            <LinkRow
              type="button"
              onClick={e =>
                editHandler(e, AppRoutes.ALERT_FORM_ALERT_TRIGGER(alertId))
              }
            >
              {lookup('review_edit_link')}
            </LinkRow>
            <Grid container spacing={2} justify="flex-end">
              <GridRow
                label={lookup('trigger_alert_name_label')}
                val={alert_name}
              />
              <GridRow
                label={lookup('trigger_alert_description_label')}
                val={alert_description}
              />
              <GridRow
                label={lookup('trigger_alert_category_label')}
                val={data_rule_name}
              />
              <GridRow
                label={lookup('review_trigger_medical_event_label')}
                val={medical_event_name}
              />
              <GridRow
                label={lookup('trigger_code_logic_label')}
                val={
                  CODE_LOGIC.find(
                    codeLogic => codeLogic.value === filter_dx_rx_px
                  )?.label
                }
              />
              <CodesRow
                label={lookup('trigger_dx_label')}
                val={medical_event_dx_codes}
              />
              <CodesRow
                label={lookup('trigger_rx_label')}
                val={medical_event_rx_codes}
              />
              <CodesRow
                label={lookup('trigger_px_label')}
                val={medical_event_px_codes}
              />
              {era_threshold_weeks && (
                <GridRow
                  label={lookup('trigger_era_label')}
                  val={`${era_threshold_weeks} ${lookup(
                    'alert_trigger_form_era_info'
                  )}`}
                />
              )}
              <GridRow
                label={lookup('trigger_qualifying_window')}
                val={`${qualifying_window_in_weeks} ${lookup(
                  'alert_trigger_form_window_info'
                )}`}
              />
            </Grid>
          </ReviewPanel>

          {(claims_enrichment.length > 0 || distance_to_site) && (
            <ReviewPanel title={lookup('review_enrichment_title')}>
              <LinkRow
                type="button"
                onClick={e =>
                  editHandler(e, AppRoutes.ALERT_FORM_ENRICHMENT(alertId))
                }
              >
                {lookup('review_edit_link')}
              </LinkRow>

              <EnrichmentSection
                enrichments={claims_enrichment}
                distanceToSite={distance_to_site}
                distanceToSiteFileName={_ui_dts_clinical_trial_site_list?.name}
                defaultDTSColumns={defaultDTSColumns}
                outputColumns={output_columns_map}
              />
            </ReviewPanel>
          )}

          <ReviewPanel title={lookup('review_output_title')}>
            <LinkRow
              type="button"
              onClick={e =>
                editHandler(e, AppRoutes.ALERT_FORM_ALERT_OUTPUT(alertId))
              }
            >
              {lookup('review_edit_link')}
            </LinkRow>
            <Grid container spacing={2} justify="flex-end">
              <GridRow
                label={lookup('output_file_template_label')}
                val={output_filename_template}
              />
              <GridRow
                label={lookup('review_output_delivery_day_label')}
                val={delivery_day}
              />
              <GridRow
                label={lookup('review_noise_reduction_label')}
                val={
                  // replace ' X ' with ' ${noise_reduction.lookback_days} '
                  defaultNoiseReduction
                    .find(({ value }) => value === _ui_noise_reduction)
                    .label.replace(' X ', ` ${noise_reduction.lookback_days} `)
                }
              />
            </Grid>
          </ReviewPanel>
          <ReviewPanel title={lookup('review_output_columns_title')}>
            <LinkRow
              type="button"
              onClick={e =>
                editHandler(e, AppRoutes.ALERT_FORM_ALERT_OUTPUT(alertId))
              }
            >
              {lookup('review_edit_link')}
            </LinkRow>
            <Grid container spacing={2} justify="flex-end">
              {output_columns_map.map((column, index) => {
                return (
                  <GridRow
                    key={`output-column-${index}`}
                    label={index + 1}
                    val={column.target_column_name}
                  />
                );
              })}
            </Grid>
          </ReviewPanel>
        </>
      )}

      {tab === 'yaml' && (
        <Paper square>
          <Box pt={2}>
            <Container>
              <LinkContainer>
                {extraConfigBlob && (
                  <LinkRow type="button" onClick={toggleShowConfig}>
                    {showExtraConfig
                      ? lookup('review_form_extra_yaml_hide_label')
                      : lookup('review_form_extra_yaml_show_label')}
                  </LinkRow>
                )}
                <LinkRow type="button" onClick={downloadYAML}>
                  {lookup('yaml_button_download')}
                </LinkRow>
              </LinkContainer>

              <SyntaxHighlighter language="yaml" style={qtcreatorLight}>
                {yamlOutput}
              </SyntaxHighlighter>
            </Container>
          </Box>
        </Paper>
      )}
    </>
  );
};

ReviewForm.label = REVIEW_FORM.label;
ReviewForm.path = REVIEW_FORM.path;

export default withRouter(ReviewForm);
