import React, { useState } from 'react';
import { GoogleSheetService } from 'services';
import Filters from './components/filters';
import { TextField, Button, Container, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import I18n from 'i18n';
const { lookup } = new I18n();

const initialState = {
  doc: null,
  sheet: null,
  rows: null,
  error: null
};

const YAML = props => {
  const [{ doc, sheet, error, rows }, setState] = useState(initialState);
  const [sheetUrl, setSheetUrl] = useState('');
  const [loading, setLoading] = useState(false);

  async function init() {
    try {
      setLoading(true);
      setState({ ...initialState });

      const googleSheetService = new GoogleSheetService(sheetUrl);
      const { sheet, doc, rows } = await googleSheetService.get();

      setState(prevState => ({
        ...prevState,
        doc,
        sheet,
        rows
      }));
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        error
      }));
    } finally {
      setLoading(false);
    }
  }

  const handleURLChange = e => {
    setSheetUrl(e.target.value);
  };

  return (
    <Container>
      <Box pt={2}>
        {error && (
          <Alert severity="error">{lookup('yaml_error_fetching_label')}</Alert>
        )}
        <Alert severity="info">{lookup('yaml_info_label')}</Alert>
        <Box pt={1}>
          <TextField
            id="sheet-url"
            label={lookup('yaml_url_label')}
            value={sheetUrl}
            onChange={handleURLChange}
            fullWidth
            placeholder={lookup('yaml_url_placeholder')}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Button
                  disabled={sheetUrl.length === 0 || loading}
                  variant="contained"
                  color="primary"
                  onClick={init}
                >
                  {loading
                    ? lookup('yaml_button_fetching')
                    : lookup('yaml_button_fetch')}
                </Button>
              )
            }}
          />
        </Box>
        {sheet && <Filters doc={doc} rows={rows} sheet={sheet} />}
      </Box>
    </Container>
  );
};

export default YAML;
