import ENDPOINT from 'utils/api';

/**
 * Authenticate user
 * @async
 * @param {string} email user's email
 * @param {string} token auth0 token
 * @return {Promise} user data.
 */
async function initUser({ email }, token) {
  try {
    const authorization = await fetch(`${ENDPOINT}authorization/${email}`, {
      headers: {
        'Access-Token': token
      }
    });
    return authorization;
  } catch (e) {
    throw new Error(e);
  }
}

export { initUser };
