import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AppRoutes from 'constants/AppRoutes';
import ErrorBoundary from 'components/common/error';
import Loader from 'components/common/loader';
import AppContext from 'AppContext';
import { initUser } from 'services';
import { useAuth0 } from 'auth0/react-auth0-spa';
import Dialog from 'components/common/dialog';
import Button from '@material-ui/core/Button';
import Company from 'components/company';
import Project from 'components/project';
import ProjectDataRule from 'components/projectDataRule';
import DeliverySetting from 'components/deliverySetting';
import Monitoring from 'components/monitoring';
import { AlertList, AlertView } from 'components/saas';
import YAML from 'components/yaml';
import Layout from 'components/common/layout';
import { displayErrorMessage } from 'utils/error';
import { setIdentity } from 'utils/analytics';
import I18n from 'i18n';

const { lookup } = new I18n();

/**
 * Root Component for protected routes
 * @param {Object} props
 */
const Root = props => {
  // read user from auth0
  const { user, getTokenSilently, logout } = useAuth0();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [state, setState] = useState({
    token: null,
    isAuthorized: false,
    companies: null,
    role: null,
    userId: null,
    loading: true
  });

  /**
   * Authenticate user
   * @param {Object} user auth0 user object
   * @param {function} getToken function to silently retrieve auth0 token
   */
  async function init(user, getToken) {
    try {
      const token = await getToken();
      const authorization = await initUser(user, token);
      if (authorization.ok) {
        const {
          companies,
          roles: [role = null],
          id: userId
        } = await authorization.json();
        if (role) {
          setState({
            token,
            isAuthorized: true,
            companies,
            role,
            userId,
            loading: false
          });
        } else {
          setDialogOpen(true);
        }
      } else {
        //no access
        setDialogOpen(true);
      }
    } catch (e) {
      setState(() => {
        throw new Error(e);
      });
    }
  }

  /**
   * Logout user
   */
  const handleDialogClose = () => {
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    setIdentity(user);
    init(user, getTokenSilently);
  }, [user, getTokenSilently]);

  return (
    <>
      <Loader loading={state.loading} />
      {user && state.isAuthorized && (
        <AppContext.Provider
          value={{
            ...state,
            user,
            displayErrorMessage
          }}
        >
          <Layout>
            <Switch>
              <Route
                path={AppRoutes.COMPANY}
                render={props => (
                  <ErrorBoundary>
                    <Company {...props} />
                  </ErrorBoundary>
                )}
              />
              <Route
                path={AppRoutes.MONITORING}
                render={props => (
                  <ErrorBoundary>
                    <Monitoring {...props} />
                  </ErrorBoundary>
                )}
              />
              <Route
                path={AppRoutes.YAML}
                render={props => (
                  <ErrorBoundary>
                    <YAML {...props} />
                  </ErrorBoundary>
                )}
              />
              <Route
                exact
                path={AppRoutes.ALERT_LIST}
                render={props => (
                  <ErrorBoundary>
                    <AlertList {...props} />
                  </ErrorBoundary>
                )}
              />
              <Route
                path={`${AppRoutes.ALERT_LIST}/:alertId`}
                render={props => (
                  <ErrorBoundary>
                    <AlertView {...props} />
                  </ErrorBoundary>
                )}
              />
              <Route
                path={`${AppRoutes.PROJECT_DATA_RULE}/:companyId/:companyName/:projectId/:projectName`}
                render={props => (
                  <ErrorBoundary>
                    <ProjectDataRule {...props} />
                  </ErrorBoundary>
                )}
              />
              <Route
                path={`${AppRoutes.DELIVERY_SETTING}/:companyId/:companyName/:projectId/:projectName/:projectDataRuleId`}
                render={props => (
                  <ErrorBoundary>
                    <DeliverySetting {...props} />
                  </ErrorBoundary>
                )}
              />
              <Route
                path={`${AppRoutes.PROJECT}/:companyId/:companyName`}
                render={props => (
                  <ErrorBoundary>
                    <Project key={props.match.params.companyId} {...props} />
                  </ErrorBoundary>
                )}
              />
              <Redirect to={AppRoutes.MONITORING} />
            </Switch>
          </Layout>
        </AppContext.Provider>
      )}
      <Dialog
        title={lookup('access_denied_dialog_title')}
        body={
          <span>
            <strong>{user.email} </strong>
            {lookup('access_denied_dialog_body')}
          </span>
        }
        handleClose={handleDialogClose}
        isOpen={dialogOpen}
        footer={<Button onClick={handleDialogClose}>OK</Button>}
      />
    </>
  );
};

export default Root;
