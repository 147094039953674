const ROUTES = {
  APP: '/app',
  COMPANY: '/app/companies',
  PROJECT: '/app/projects',
  YAML: '/app/yaml',
  MONITORING: '/app/monitoring',
  PROJECT_DATA_RULE: '/app/projectdatarule',
  DELIVERY_SETTING: '/app/deliverysetting',
  ALERT_LIST: '/app/alerts',
  ALERT_FORM_CUSTOMER: id => {
    return `/app/alerts/${id}/customer`;
  },
  ALERT_FORM_COHORT: id => {
    return `/app/alerts/${id}/cohort`;
  },
  ALERT_FORM_ALERT_TRIGGER: id => {
    return `/app/alerts/${id}/alerttrigger`;
  },
  ALERT_FORM_ENRICHMENT: id => {
    return `/app/alerts/${id}/enrichment`;
  },
  ALERT_FORM_ALERT_OUTPUT: id => {
    return `/app/alerts/${id}/alertoutput`;
  },
  ALERT_FORM_REVIEW: id => {
    return `/app/alerts/${id}/review`;
  }
};

export default ROUTES;
