import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import Container from '@material-ui/core/Container';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import HeaderLogo from './header-logo.svg';
import MuiIconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AppRoutes from 'constants/AppRoutes';
import { HEADER_INDEX, HEADER_CONFIG } from 'constants/AppConstants';
import { withRouter, useLocation } from 'react-router-dom';
import { useAuth0 } from 'auth0/react-auth0-spa';
import AppContext from 'AppContext';
import I18n from 'i18n';
const { lookup } = new I18n();

const Wrapper = styled.div(
  p => `
  display: flex;
  align-items: center;
  color: ${p.theme.colors.light};
`
);

const Logo = styled.img(
  p => `
  padding-right: ${p.theme.space.md};
`
);

const Tabs = styled(MuiTabs)`
  flex-grow: 1;
`;

const TabIndicator = {
  style: {
    display: 'none'
  }
};

const Tab = styled(MuiTab)(
  p => css`
    ${p.theme.typography.overline};
    opacity: 1;
    height: ${p.theme.fixed.headerHeight}px;
    ${({ mainnav }) =>
      mainnav &&
      css`
        &.Mui-selected {
          background-color: ${p.theme.colors.white};
          color: ${p.theme.colors.textGrey};
        }
      `}
    .MuiTab-wrapper {
      flex-direction: row;
    }
  `
);

const IconButton = styled(MuiIconButton)(
  p => `
  padding-right: 0;
  height: ${p.theme.fixed.headerHeight}px;
`
);

const DisplayName = styled.span(
  p => css`
    ${p.theme.typography.overline};
    color: inherit;
    padding-right: ${p.theme.space.sm};
  `
);

const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  keepMounted: true,
  getContentAnchorEl: null,
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  }
};

const initTabProps = index => {
  return {
    id: `header-tab-${index}`,
    'aria-controls': `header-tabpanel-${index}`
  };
};

/**
 * Detects active route from location's pathname
 * @param {Object} location
 * @returns {integer}
 */
const getCurrentRoute = location => {
  if (location.pathname.includes(AppRoutes.MONITORING)) {
    return HEADER_INDEX.MONITORING;
  } else if (location.pathname.includes(AppRoutes.YAML)) {
    return HEADER_INDEX.YAML;
  } else if (location.pathname.includes(AppRoutes.ALERT_LIST)) {
    return HEADER_INDEX.ALERT_LIST;
  } else {
    return HEADER_INDEX.CONFIGURATION;
  }
};

const Header = props => {
  const {
    role,
    user: { displayName }
  } = useContext(AppContext);
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(getCurrentRoute(location));
  const [isAlertView, setIsAlertView] = useState(false);
  const { logout } = useAuth0();

  const handleProfileMenu = event => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorElProfile(null);
  };

  const changeRoute = target => {
    props.history.push(target);
  };

  const signOut = () => {
    logout({
      returnTo: window.location.origin
    });
  };

  useEffect(() => {
    // detect alert views here to render custom header
    const isAlertView = location.pathname.match(
      new RegExp(`^${AppRoutes.ALERT_LIST}/.`, 'i')
    );
    if (isAlertView) {
      setIsAlertView(true);
    } else {
      // update selected tab on route change
      setIsAlertView(false);
      setSelectedTab(getCurrentRoute(location));
    }
  }, [location]);

  return (
    <Container maxWidth="lg">
      {selectedTab < HEADER_CONFIG[role].length && (
        <Wrapper>
          <Logo src={HeaderLogo} alt="home" />
          {!isAlertView && (
            <Tabs
              value={selectedTab}
              variant="scrollable"
              TabIndicatorProps={TabIndicator}
            >
              {HEADER_CONFIG[role].map((tab, id) => {
                return (
                  <Tab
                    key={id}
                    label={lookup(tab.label)}
                    {...initTabProps(tab.index)}
                    onClick={() => changeRoute(tab.route)}
                    mainnav={1}
                    disabled={tab.disabled}
                  />
                );
              })}
            </Tabs>
          )}
          {isAlertView && (
            <Tabs
              value={0}
              variant="scrollable"
              TabIndicatorProps={TabIndicator}
            >
              <Tab
                label={
                  <>
                    <ArrowBackIosIcon />
                    {lookup('header_alert_views')}
                  </>
                }
                {...initTabProps(0)}
                onClick={() => props.history.push(AppRoutes.ALERT_LIST)}
              />
            </Tabs>
          )}
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar-profile"
            aria-haspopup="true"
            onClick={handleProfileMenu}
            color="inherit"
          >
            <DisplayName>{displayName}</DisplayName>
            <AccountCircleIcon />
            <ArrowDropDownIcon />
          </IconButton>

          <Menu
            id="menu-appbar-profile"
            anchorEl={anchorElProfile}
            {...MenuProps}
            open={Boolean(anchorElProfile)}
            onClose={handleProfileMenuClose}
          >
            <MenuItem onClick={signOut}>{lookup('header_logout')}</MenuItem>
          </Menu>
        </Wrapper>
      )}
    </Container>
  );
};

export default withRouter(Header);
