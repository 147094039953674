import React, { useState, useEffect } from 'react';
import { getCodeCounts } from '../helpers';
import { download } from 'utils/download';
import styled, { css } from 'styled-components';
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import I18n from 'i18n';
const { lookup } = new I18n();

const DownloadContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FlexAccordionSummary = styled(AccordionSummary)(
  p => css`
    .MuiAccordionSummary-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      label {
        span {
          ${p.theme.typography.body};
        }
      }
    }
  `
);

const Preview = props => {
  const [fields, setFields] = useState({ ...props.fields });

  useEffect(() => {
    setFields(props.fields);
  }, [props.fields]);

  const handleFormatChange = (event, name) => {
    event.stopPropagation();

    if (event?.target?.value) {
      setFields(prevState => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          format: event.target.value
        }
      }));
    }
  };

  const formatJSON = json => {
    return JSON.stringify(json, null, 2);
  };

  const downloadYAML = () => {
    Object.keys(fields).forEach(item => {
      const field = fields[item];
      if (field.checked && field.yaml) {
        let data = field[field.format];
        data = field.format === 'yaml' ? data : formatJSON(fields[item].json);
        download(data, `${props.title}_${item}`, field.format);
      }
    });
  };

  return (
    <>
      {Object.keys(fields).map((item, index) => {
        if (fields[item].checked) {
          return (
            <Accordion
              disabled={!fields[item].yaml}
              key={item}
              defaultExpanded={Boolean(fields[item].yaml)}
            >
              <FlexAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${item}-content`}
                id={`${item}-header`}
              >
                <b>{`${fields[item].label} ${fields[
                  item
                ].format.toUpperCase()} ${
                  fields[item].yaml
                    ? getCodeCounts(fields[item].json, fields[item].is2d)
                    : `- ${lookup('yaml_accordion_header_no_data_label')}`
                }`}</b>
                {fields[item].yaml && (
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="format"
                      onClick={e => handleFormatChange(e, item)}
                    >
                      <FormControlLabel
                        value="start"
                        control={
                          <Radio
                            checked={fields[item].format === 'yaml'}
                            color="primary"
                            value="yaml"
                            name="radio-button-yaml"
                            inputProps={{ 'aria-label': 'yaml' }}
                          />
                        }
                        label={lookup('yaml_radio_format_yaml')}
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="start"
                        control={
                          <Radio
                            size="small"
                            checked={fields[item].format === 'json'}
                            color="primary"
                            value="json"
                            name="radio-button-json"
                            inputProps={{ 'aria-label': 'json' }}
                          />
                        }
                        label={lookup('yaml_radio_format_json')}
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </FlexAccordionSummary>
              <AccordionDetails>
                {fields[item].yaml && (
                  <TextField
                    multiline
                    rows={10}
                    variant="outlined"
                    value={
                      fields[item].format === 'yaml'
                        ? fields[item].yaml
                        : formatJSON(fields[item].json)
                    }
                    fullWidth
                  />
                )}
              </AccordionDetails>
            </Accordion>
          );
        }
        return null;
      })}
      <Box pt={2}>
        <DownloadContainer>
          <Button variant="contained" color="primary" onClick={downloadYAML}>
            {lookup('yaml_button_download')}
          </Button>
        </DownloadContainer>
      </Box>
    </>
  );
};

export default Preview;
